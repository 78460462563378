import React, { useCallback, useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { editCheckPoint } from "./network_requests";
import { useHistory } from "react-router";

const Scan = ({ checkPoint, setActive }) => {
  const [status, setStatus] = useState("Pending");
  const history = useHistory();

  const scan = useCallback(async () => {
    if ("NDEFReader" in window) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        console.log("Scan started successfully.");
        ndef.onreadingerror = () => {
          setStatus("Error");
          //alert("Cannot read data from the NFC tag. Try another one?");
          console.log("Cannot read data from the NFC tag. Try another one?");
        };

        ndef.onreading = (event) => {
          console.log("NDEF message read.");
          onReading(event);
        };
      } catch (error) {
        setStatus("Error");
        //alert(`Error! Scan failed to start: ${error}.`);

        console.log(`Error! Scan failed to start: ${error}.`);
      }
    } else {
      //alert("NFC scan is not supported in this device");

      setStatus("Device Unsupported");
    }
  }, []);

  const onReading = async ({ message, serialNumber }) => {
    let found = false;
    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          const textDecoder = new TextDecoder(record.encoding);
          const tag = textDecoder.decode(record.data);
          if (tag == checkPoint?.cfeld19) {
            found = true;
          }

          break;
        case "url":
          // TODO: Read URL record with record data.
          break;
        default:
        // TODO: Handle other records with record data.
      }
    }
    if (found) {
      //alert("Scanned & Matched Successful");
      setStatus("Scanned & Matched Successful");
    } else {
      //alert("Scanned & Matched Failed");
      setStatus("Scanned & Matched Failed");
    }
  };

  useEffect(() => {
    scan();
  }, [scan]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
          onClick={() => {
            setActive(false);
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "22px", fontWeight: "bold" }} />
        </Typography>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 450,
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Scan
        </Typography>
      </div>
      <Typography
        style={{
          fontSize: "22px",
          fontWeight: 400,
          marginTop: "10px",
          padding: "10px",
        }}
      >
        Scan Status: {status}
      </Typography>
    </>
  );
};

export default Scan;
