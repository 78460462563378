import { Row, Typography } from "antd";
import Tabs from "./components/tabs";
import { useState } from "react";
import Staff from "./components/staff";
import CostCenter from "./components/costCenter";
import Vehicle from "./components/vehicles";

export default function BaseData() {
  const [tabState, setTabState] = useState(0);
  return (
    <Row style={{ width: "100%", padding: "10px 20px" }}>
      <Typography>Stammdaten Arbeitsaufträge</Typography>
      <Row style={{ width: "100%", padding: "0px 70px" }}>
        <Tabs tabState={tabState} setTabState={setTabState} />
      </Row>
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "70vh",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        {tabState === 0 ? (
          <Staff />
        ) : tabState === 1 ? (
          <CostCenter />
        ) : (
          <Vehicle />
        )}
      </div>
    </Row>
  );
}
