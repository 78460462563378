import { url } from "../../../constants";
import axios from "axios";

export const callsearch = async (data) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}search/search`,
      {
        data
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const vote = async (data) => {
  try {
    
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}queryStatistic/vote`,
      {
        ...data
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addCall = async (data) => {
  try {
    
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}queryStatistic/addCall`,
      {
        ...data
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
