import {
  Row,
  Typography,
  Col,
  Button,
  message,
  Popover,
  Empty,
  Pagination,
} from "antd";
import { useEffect, useState } from "react";
import { double_to_string } from "../../../../../../constants";
import IntlMessages from "util/IntlMessages";
import { getArchiveEvents, getEvents, archiveEvent } from "../networkrequests";
import eventIconGreen from "../../../assets/Event_icon_green.png";
import eventIconYellow from "../../../assets/Event_icon_yellow.png";
import eventIconRed from "../../../assets/Event_icon_red.png";
import reqIconGreen from "../../../assets/req_icon_green.png";
import reqIconYellow from "../../../assets/req_icon_yellow.png";
import reqIconRed from "../../../assets/req_icon_red.png";
import calenderIcon from "../../../assets/calender.png";
import bubbleIcon from "../../../assets/bubble.png";
import { useHistory } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;

const daysDiff = (date) => {
  var now = moment(new Date()); //todays date
  var end = moment(date, "DD.MM.YYYY"); // another date
  var duration =
    moment.duration(now.diff(end)) ?? moment.duration(end.diff(now));
  var days = duration.asDays();
  return days;
};

const success = () => {
  message.success("Veranstaltung wurde ins Archiv verschoben.");
};

const error = () => {
  message.error("Ein Fehler ist passiert, bitte nochmal versuchen!");
};

export default function Table(props) {
  const {
    events,
    setEvents,
    editEvent,
    setEditEvent,
    updateEvents,
    setUpdateEvents,
  } = props;
  const history = useHistory();
  const [archiveEvents, setArchiveEvents] = useState([]);
  const [openPop, setOpenPop] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      const events_ = await getEvents();
      if (events_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setEvents({ ...events_ });
    })();
  }, [updateEvents]);

  useEffect(() => {
    (async () => {
      const events_ = await getArchiveEvents((page - 1) * 10);
      if (events_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setArchiveEvents({ ...events_ });
    })();
  }, [updateEvents, page]);

  return (
    <>
      <Row
        style={{
          backgroundColor: "#FFF",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 400,
          padding: "10px 30px",
          margin: "20px",
          borderRadius: "8px",
          border: "2px solid #ececec",
          minHeight: "70vh",
          flexDirection: "column",
        }}
      >
        <Row
          style={{
            justifyContent: "end",
            backgroundColor: "#fff",
            fontWeight: 400,
            width: "100%",
            padding: "10px",
            margin: "0px",
            borderBottom: "1px solid #eaeaea",
          }}
        >
          <Col xs={21}>
            <Text style={{ fontSize: "18px" }}>
              <IntlMessages id="events.events" />
            </Text>
          </Col>
          <Col xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popover
              trigger="click"
              placement="bottom"
              content={
                <>
                  <Button
                    type="text"
                    onClick={() => {
                      setEditEvent(null);
                      window.localStorage.removeItem("editing");

                      history.push("/main/eventManagement/outdoor-event");
                    }}
                  >
                    {<IntlMessages id="events.OutdoorEvent" />}
                  </Button>
                  <br />
                  <Button
                    type="text"
                    onClick={() => {
                      setEditEvent(null);
                      window.localStorage.removeItem("editing");
                      history.push("/main/eventManagement/indoor-event");
                    }}
                  >
                    {<IntlMessages id="events.IndoorEvent" />}
                  </Button>
                </>
              }
            >
              <Button size="small" type="primary">
                <IntlMessages id="events.addEvent" />
              </Button>
            </Popover>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "#fafafa",
            borderBottom: "1px solid #eaeaea",
            fontWeight: 500,
            width: "100%",
            padding: "10px 30px",
            margin: "20px 0 0 0",
          }}
        >
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.plannedEvent" />
            </Text>
          </Col>
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.Time" />
            </Text>
          </Col>
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.responsiblePerson" />
            </Text>
          </Col>
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.Actions" />
            </Text>
          </Col>
        </Row>

        {events?.count === 0 ? (
          <Row
            style={{
              width: "100%",
              height: "25vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty description="Keine Veranstaltungen vorhanden" />
          </Row>
        ) : null}

        {events?.list?.map((event) => {
          const daysDifference = daysDiff(double_to_string(event?.nfeld3));
          return (
            <Row
              style={{
                width: "100%",
                borderBottom: "1px solid #eaeaea",
                padding: "10px 30px",
                margin: 0,
                backgroundColor:
                  window.localStorage.getItem("lastevent") === event?.cfeld1
                    ? "lightgreen"
                    : "",
              }}
            >
              <Col
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditEvent({ ...event });
                  window.localStorage.setItem("lastevent", event?.cfeld1);
                  event?.cfeld30 === "outdoor"
                    ? history.push("/main/eventManagement/outdoor-event")
                    : history.push("/main/eventManagement/indoor-event");
                }}
              >
                <img
                  src={
                    event?.nfeld9
                      ? bubbleIcon
                      : daysDifference > 0 && daysDifference < 1
                      ? eventIconRed
                      : daysDifference > -3 && daysDifference < 0
                      ? eventIconYellow
                      : eventIconGreen
                  }
                  style={{ height: "20px", marginRight: "8px" }}
                />
                <img
                  src={
                    !event?.nfeld7
                      ? reqIconRed
                      : event?.nfeld10
                      ? reqIconGreen
                      : reqIconYellow
                  }
                  style={{ height: "20px", marginRight: "8px" }}
                />
                <Text style={{ color: "#038fde" }}>{event?.cfeld1}</Text>
              </Col>
              <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={calenderIcon}
                  style={{ height: "20px", marginRight: "8px" }}
                />
                <Text style={{ color: "#038fde" }}>
                  {double_to_string(event?.nfeld3)} -{" "}
                  {double_to_string(event?.nfeld4)}
                </Text>
              </Col>
              <Col xs={6}>
                <Text style={{ color: "#038fde" }}>
                  {event?.cfeld7} {event?.cfeld6}
                </Text>
              </Col>
              <Col xs={6}>
                <Popover
                  open={openPop === event?.stkey}
                  content={
                    <Button
                      type="primary"
                      onClick={async () => {
                        const response = await archiveEvent(event?.stkey);
                        if (response?.success) {
                          success();
                          setUpdateEvents(!updateEvents);
                        } else {
                          error();
                        }
                        setOpenPop(null);
                      }}
                    >
                      Archive
                    </Button>
                  }
                  trigger="click"
                >
                  <Text
                    style={{ color: "#038fde", cursor: "pointer" }}
                    onClick={() => {
                      setOpenPop(event?.stkey);
                    }}
                  >
                    <IntlMessages id="more_actions" />
                  </Text>
                </Popover>
              </Col>
            </Row>
          );
        })}
        <Row
          style={{
            justifyContent: "end",
            backgroundColor: "#fafafa",
            borderBottom: "1px solid #eaeaea",
            fontWeight: 400,
            width: "100%",
            padding: "10px 30px",
            margin: "20px 0 0 0",
          }}
        >
          <Popover
            trigger="click"
            placement="bottom"
            content={
              <>
                <Button
                  type="text"
                  onClick={() => {
                    setEditEvent(null);
                    window.localStorage.removeItem("editing");
                    history.push("/main/eventManagement/outdoor-event");
                  }}
                >
                  {<IntlMessages id="events.OutdoorEvent" />}
                </Button>
                <br />
                <Button
                  type="text"
                  onClick={() => {
                    setEditEvent(null);
                    window.localStorage.removeItem("editing");
                    history.push("/main/eventManagement/indoor-event");
                  }}
                >
                  {<IntlMessages id="events.IndoorEvent" />}
                </Button>
              </>
            }
          >
            <Button type="primary" size="small">
              <IntlMessages id="events.addEvent" />
            </Button>
          </Popover>
        </Row>

        <Row
          style={{
            backgroundColor: "#fafafa",
            borderBottom: "1px solid #eaeaea",
            fontWeight: 500,
            width: "100%",
            padding: "10px 30px",
            margin: "20px 0 0 0",
          }}
        >
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.Archive" />
            </Text>
          </Col>
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.Time" />
            </Text>
          </Col>
          <Col xs={6}>
            <Text>
              <IntlMessages id="events.responsiblePerson" />
            </Text>
          </Col>
        </Row>

        {archiveEvent?.count === 0 ? (
          <Row
            style={{
              width: "100%",
              height: "25vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty description="No Event in archive" />
          </Row>
        ) : null}

        {archiveEvents?.list?.map((event) => {
          return (
            <>
              <Row
                style={{
                  width: "100%",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: 0,
                }}
              >
                <Col
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditEvent({ ...event });
                    event?.cfeld30 === "outdoor"
                      ? history.push("/main/eventManagement/outdoor-event")
                      : history.push("/main/eventManagement/indoor-event");
                  }}
                >
                  <Text style={{ color: "#038fde" }}>{event?.cfeld1}</Text>
                </Col>
                <Col xs={6}>
                  <Text style={{ color: "#038fde" }}>
                    {double_to_string(event?.nfeld3)} -{" "}
                    {double_to_string(event?.nfeld4)}
                  </Text>
                </Col>
                <Col xs={6}>
                  <Text style={{ color: "#038fde" }}>
                    {event?.cfeld7} {event?.cfeld6}
                  </Text>
                </Col>
                {/*<Col xs={6}>
                <Text style={{ color: "#038fde" }}><IntlMessages id="more_actions" /></Text>
            </Col>*/}
              </Row>
            </>
          );
        })}
        <Row
          style={{
            backgroundColor: "#fff",
            padding: "10px 30px 0 30px",
            margin: "0",
          }}
          justify="end"
        >
          <Pagination
            defaultCurrent={1}
            total={archiveEvents?.count}
            current={page}
            defaultPageSize={10}
            style={{
              margin: 0,
            }}
            onChange={(newpage) => {
              setPage(newpage);
            }}
          />
        </Row>
      </Row>
    </>
  );
}
