import React from "react";
import { Route, Switch } from "react-router-dom";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import SocialApps from "./socialApps/index";
import Main from "./main/index";
import Documents from "./documents/index";
import { useMediaQuery } from "react-responsive";

const App = ({ match }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div
      className="gx-main-content-wrapper"
      style={isTabletOrMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}
    >
      <Switch>
        <Route path={`${match.url}main`} component={Main} />
      </Switch>
    </div>
  );
};

export default App;
