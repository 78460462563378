import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Menu,
  Typography,
  Select,
} from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { addCheck, addTree } from "./network_requests";
import TreeTabs from "./TreeTabs";
import { string_to_double } from "../../../../constants";
import { DownOutlined } from "@ant-design/icons";

export default function AddCheck(props) {
  const { open, setOpen, fields, setAction, stkey } = props;
  const history = useHistory();
  const ref = useRef();
  const [root, setRoot] = useState("-");
  const [stem, setStem] = useState("-");
  const [crown, setCrown] = useState("-");
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nextControl, setNextControl] = useState(
    moment()?.add(1, "Y")?.format("DD.MM.YYYY")
  );
  const { Text } = Typography;
  const { Option } = Select;

  useEffect(() => {
    setRoot("-");
    setStem("-");
    setCrown("-");
  }, []);

  return (
    <Modal
      title={"Add Check"}
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(!open);
        setAction(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
        setAction(null);
        setnfeld1(moment(moment(), "DD.MM.YYYY"));
        setNextControl(moment()?.add(1, "Y")?.format("DD.MM.YYYY"));
      }}
      style={{
        maxWidth: "920px",
      }}
    >
      <Form
        name="add Tree"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);
          const mulvalues = [
            root?.split(","),
            stem?.split(","),
            crown?.split(","),
          ];
          const numOfValues = [
            root?.split(",")?.length,
            stem?.split(",")?.length,
            crown?.split(",")?.length,
          ];
          var size = Math.max(...numOfValues);
          var payload = [];

          for (var i = 0; i < size; i++) {
            payload = [
              ...payload,
              {
                detTable__: "cldetail_g03",
                detkey: "",
                active: 1,
                stkey: stkey,
                stclass: 2,
                detclass: 1,
                gisid: "",
                ...values,
                nfeld1: string_to_double(nfeld1),
                cfeld5: numOfValues[0] > i ? mulvalues[0][i] : "-",
                cfeld4: numOfValues[1] > i ? mulvalues[1][i] : "-",
                cfeld3: numOfValues[2] > i ? mulvalues[2][i] : "-",
              },
            ];
          }

          const response = await addCheck([...payload]);
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Control Date" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                  setNextControl(
                    moment(d, "DD.MM.YYYY")?.add(1, "Y")?.format("DD.MM.YYYY")
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Next Control" />}
              style={{ marginBottom: 0 }}
            >
              <Input value={nextControl} />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Value" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Measurement data" />}
              name="cfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Dig Management" />}
              name="cfeld9"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Extraordinary events" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Finding Root" />}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={(val) => {
                  setRoot(val?.join(", "));
                }}
              >
                {fields?.root?.map((field) => {
                  return (
                    <Option key={field?.lovvalue}>{field?.lovvalue}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Finding Stem" />}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={(val) => {
                  setStem(val?.join(", "));
                }}
              >
                {fields?.stem?.map((field) => {
                  return (
                    <Option key={field?.lovvalue}>{field?.lovvalue}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Finding Crown" />}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={(val) => {
                  setCrown(val?.join(", "));
                }}
              >
                {fields?.crown?.map((field) => {
                  return (
                    <Option key={field?.lovvalue}>{field?.lovvalue}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
