import { Row, Select } from "antd";
import { useEffect, useState } from "react";

export default function TypeSelecter({ type, setType, getAllTypes }) {
  const [options, setOptions] = useState([
    {
      label: "none",
      value: "none",
    },
  ]);
  const [load, setLoad] = useState(false);
  useEffect(async () => {
    const types_ = await getAllTypes();

    setOptions([
      ...types_?.map((type_) => {
        return {
          label: type_,
          value: type_,
        };
      }),
    ]);
    setLoad(true);
  }, []);

  return (
    <Row>
      {load ? (
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Please select"
          defaultValue={[type]}
          onChange={(val) => {
            setType(val);
          }}
          options={options}
        />
      ) : null}
    </Row>
  );
}
