import { useState } from "react";
import Catagories from "./main";
import CatagoriesStep1 from "./step1";
import CatagoriesStep2 from "./step2";

export default function CategoriesMain() {
  const [showStates, setShowStates] = useState({
    page: 1,
  });
  return showStates?.page === 1 ? (
    <Catagories showStates={showStates} setShowStates={setShowStates} />
  ) : showStates?.page === 2 ? (
    <CatagoriesStep1 showStates={showStates} setShowStates={setShowStates} />
  ) : showStates?.page === 3 ? (
    <CatagoriesStep2 showStates={showStates} setShowStates={setShowStates} />
  ) : null;
}
