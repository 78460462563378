import { Row, Col, Typography, Button, Modal, Empty, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { deleteRepair, getAllRepairs } from "../network_requests";
import { double_to_string } from "../../../../constants";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

const Repairs = (props) => {
  const { state, setNewRepair, setEditRepair, stclass } = props;
  const [repairs, setRepairs] = useState([]);

  useEffect(async () => {
    const repairs_ = await getAllRepairs(state?.stkey, stclass);
    setRepairs(repairs_?.list);
  }, [props.update]);

  return (
    <>
      <Row>
        <Col xs={23}>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
            }}
          >
            <Col
              xs={5}
              style={{
                padding: 0,
              }}
            >
              <Text><IntlMessages id="cockpit.repair.repairDate" /></Text>
            </Col>

            <Col
              xs={11}
              style={{
                padding: 0,
              }}
            >
              <Text><IntlMessages id="cockpit.repair.repairContent" /></Text>
            </Col>

            <Col
              xs={5}
              style={{
                padding: 0,
              }}
            >
              <Text><IntlMessages id="cockpit.repair.repairedBy" /></Text>
            </Col>

            <Col
              xs={3}
              style={{
                padding: 0,
              }}
            >
              <Text><IntlMessages id="cockpit.repair.workinghrskm" /></Text>
            </Col>
          </Row>
        </Col>
      </Row>

      {repairs?.length === 0 ? (
        <Empty description="Keine Reparaturen eingetragen." />
      ) : null}

      {repairs?.map((repair) => {
        return (
          <Row align="middle">
            <Col xs={23}>
              <Row
                style={{
                  padding: "10px 30px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditRepair({ ...repair });
                }}
              >
                <Col
                  xs={5}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {repair?.nfeld1 ? double_to_string(repair?.nfeld1) : ""}{" "}
                  </Text>
                </Col>

                <Col
                  xs={11}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {repair?.cfeld2}
                  </Text>
                </Col>

                <Col
                  xs={5}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {repair?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {repair?.cfeld3}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col
              xs={1}
              style={{
                padding: 0,
              }}
            >
              <Popconfirm
                title="Delete the repair"
                description="Are you sure to delete this repair?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={async () => {
                  await deleteRepair({ ...repair }, props?.stclass);
                  props.setUpdate(!props?.update);
                }}
              >
                <DeleteOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            </Col>
          </Row>
        );
      })}
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          onClick={() => {
            setNewRepair({ ...state });
          }}
        >
          add Repair
        </Button>
      </Row>
    </>
  );
};

export default Repairs;
