import { Row, Typography, Col, Input } from "antd";
import AddAppointment from "./addAppointment";
import Tabs from "./tabs";
import { useState } from "react";
import { doubleDT_to_string } from "../../../../constants";

export default function Appointment({
  insertTemplate,
  editTemplate,
  setEditTemplate,
  setType,
  update,
  setUpdate,
  type,
}) {
  const [tabState, setTabState] = useState(0);
  const [title, setTitle] = useState(editTemplate?.title ?? "");
  insertTemplate = true;
  return (
    <>
      <Typography
        style={{
          fontSize: "20px",
          fontWeight: 400,
          textTransform: "uppercase",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Terminvereinbarung
        {editTemplate?.lastChangeDateTime ? (
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            Last Change: {editTemplate?.lastChangeUsername},{" "}
            {doubleDT_to_string(editTemplate?.lastChangeDateTime)}
          </Typography>
        ) : null}
      </Typography>

      <Row style={{ width: "100%" }}>
        <Col xs={12}>
          <Input
            value={title}
            placeholder="TITLE"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Tabs tabState={tabState} setTabState={setTabState} />

      <AddAppointment
        tabState={tabState}
        setTabState={setTabState}
        title={title}
        appointment={editTemplate ? editTemplate : {}}
        setAppointment={setEditTemplate}
        update={update}
        setUpdate={setUpdate}
        type={type}
        setType={setType}
      />
    </>
  );
}
