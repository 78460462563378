import { url } from "../../../../../constants";
import axios from "axios";

export const getEvents = async (page) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getActiveEvents`,
      {
        group: "class_g25",
        stClass: "1",
        type: "none",
        limitFrom: 0,
        limitTo: "50",
        orderBy: " nfeld3 ",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getArchiveEvents = async (page) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g25",
        stClass: "2",
        type: "none",
        limitFrom: page,
        limitTo: "10",
        orderBy: "nfeld3 DESC",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const archiveEvent = async (stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}moveEventToArchieve`,
      {
        stkey: stkey,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
