import moment from "moment";
import { string_to_double, url } from "../../../../constants";
import axios from "axios";

const latDegree = () => {
  return (0.1 / 6378) * (180 / Math.PI);
};
const lngDegree = (lat) => {
  return ((0.1 / 6378) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180);
};

export const searchByName = async (start, end, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g04",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "cfeld1", val: search, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchByStreet = async (start, end, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g04",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "cfeld2", val: search, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchByCenter = async (start, end, lat, lng) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g04",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        nfields: [
          {
            fieldNo: "nfeld2",
            fromVal: lat - latDegree(),
            toVal: lat + latDegree(),
            opVal: "between",
          },
          {
            fieldNo: "nfeld1",
            fromVal: lng - lngDegree(lng),
            toVal: lng + lngDegree(lng),
            opVal: "between",
          },
        ],
        cfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getIssues = async (start, pageSize) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getIssues`,
      {
        group: "class_g10",
        stClass: "1",
        type: "none",
        limitFrom: start,
        limitTo: pageSize,
        orderBy: "nfeld4 DESC",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addIssue = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}addIssue`,
      {
        active: 1,
        group: 10,
        stkey: "",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editIssue = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}editIssue`,
      {
        active: 1,
        group: 10,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getListofValues = async (id) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFiles = async (id, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}requestFileList`,
      {
        attribute: attribute,
        objID: id,
        group: "10",
        stClass: "1",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadFile = async (file, key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileWithMIMO`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: attribute,
        detclass: 0,
        group: 10,
        stclass: 1,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,

        detclass: 0,
        stclass: 1,
        group: 10,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForIssues = async (order) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForIssues`,
      {
        stkey: "all",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getWordForIssue = async (stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getWordForIssue`,
      {
        stkey:stkey,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};