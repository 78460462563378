import { Row } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BaseData from "./components/create/baseData";
import Tabs from "./components/tabs";

export default function CreateEvent(props) {
  const { setEditEvent, updateEvents, setUpdateEvents } = props;
  const [tabState, setTabState] = useState(0);
  let { page } = useParams();

  return (
    <Row
      style={{
        backgroundColor: "#FFF",
        borderBottom: "1px solid #eaeaea",
        fontWeight: 500,
        padding: "10px 30px",
        margin: "20px",
        borderRadius: "8px",
        border: "2px solid #ececec",
        minHeight: "70vh",
        flexDirection: "column",
      }}
    >
      Create Event ({page && page === "outdoor-event" ? "Outdoor" : "Indoor"})
      <Tabs tabState={tabState} setTabState={setTabState} creating={true} />
      <BaseData
        setEditEvent={setEditEvent}
        updateEvents={updateEvents}
        setUpdateEvents={setUpdateEvents}
        cfeld30={page && page === "outdoor-event" ? "outdoor" : "indoor"}
      />
    </Row>
  );
}
