import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Empty,
  Select,
  Input,
  message,
} from "antd";
import {
  editTrackingDetails,
  getTrackingData,
  sendTrackingInfo,
  getCostCenter,
} from "./network_requests";
import { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import { doubleDT_to_string, string_to_doubleDT } from "../../../constants";
import MoreActions from "../../extraComponents/MoreActions";
import moment from "moment";
import IntlMessages from "util/IntlMessages";

const { Text } = Typography;

export default function TrackingMobile(props) {
  const [Tracking, setTracking] = useState([]);

  const [updateData, setUpdateData] = useState(false);
  const [comment, setComment] = useState("");
  const [customer, setCustomer] = useState("");
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [stkey, setStekey] = useState(null);
  const { vehicle } = useParams();
  const [customers, setCustomers] = useState([]);

  useEffect(async () => {
    setLoad(true);
    const response = await sendTrackingInfo(vehicle);

    if (response.status === 401) {
      localStorage.removeItem("tracking_id");
      history.go(0);
    } else {
      if (response?.success) {
        setStekey(response?.success);
        const customers_ = await getCostCenter();
        setCustomers(customers_);
      }
    }
    setLoad(false);
  }, []);

  useEffect(async () => {
    if (stkey) {
      const tracking = await getTrackingData(stkey);
      setTracking(tracking);
      setCustomer(tracking?.cfeld3);
      setComment(tracking?.cfeld10);
    }
  }, [updateData, stkey]);

  return (
    <>
      <>
        <>
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 450,
              }}
            >
              Tracking
            </Typography>
          </div>

          <div
            style={{
              backgroundColor: "#f3f7ff",
              left: 0,
              padding: "20px",
              width: "100%",
            }}
          >
            <Row style={{ padding: "10px 20px 0px 20px" }}>
              <Text style={{ color: "gray", textTransform: "uppercase" }}>
                Tracking {Tracking?.nfeld2 ? <IntlMessages id="tracking.stopped"/> : <IntlMessages id="tracking.started"/>}
              </Text>
            </Row>
          </div>
        </>

        {load ? (
          <div
            style={{
              marginTop: "10vh",
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Spin />
          </div>
        ) : null}
        {!load ? (
          <div
            style={{
              left: 0,
              padding: "20px",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            {[
              {
                name: <IntlMessages id="account_username"/>,
                value: Tracking?.cfeld1,
              },
              {
                name: <IntlMessages id="vehicle.title"/>,
                value: Tracking?.cfeld2,
              },
              {
                name: <IntlMessages id="tracking.startTime"/>,
                value: Tracking?.nfeld1
                  ? doubleDT_to_string(Tracking?.nfeld1)
                  : "-",
              },
              {
                name: <IntlMessages id="tracking.endTime"/>,
                value: Tracking?.nfeld2
                  ? doubleDT_to_string(Tracking?.nfeld2)
                  : "-",
              },
            ]?.map((item) => {
              return (
                <Row
                  style={{
                    width: "100%",
                    margin: "10px 0",
                  }}
                >
                  <Col
                    xs={24}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text style={{ color: "#808080", paddingLeft: "5px" }}>
                      {item?.name}
                    </Text>
                  </Col>

                  <Col
                    xs={24}
                    style={{
                      padding: "10px",
                      backgroundColor: "#e5e5e5",
                      borderRadius: "10px",
                      marginTop: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    <Text>{item?.value}</Text>
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                width: "100%",
                margin: "10px 0",
              }}
            >
              <Col
                xs={24}
                style={{
                  padding: 0,
                }}
              >
                <Text style={{ color: "#808080", paddingLeft: "5px" }}>
                <IntlMessages id="tracking.customer"/>
                </Text>
              </Col>

              <Col
                xs={24}
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                <Select
                  value={customer}
                  onChange={(e) => {
                    setCustomer(e);
                  }}
                  options={[
                    ...customers?.map((item) => {
                      return {
                        value: item?.cfeld1,
                        label: item?.cfeld1,
                      };
                    }),
                  ]}
                  size="middle"
                  style={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                width: "100%",
                margin: "10px 0",
              }}
            >
              <Col
                xs={24}
                style={{
                  padding: 0,
                }}
              >
                <Text style={{ color: "#808080", paddingLeft: "5px" }}>
                <IntlMessages id="comment"/>
                </Text>
              </Col>

              <Col
                xs={24}
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                <Input
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Button
              type="primary"
              onClick={async () => {
                const response = await editTrackingDetails({
                  ...Tracking,
                  cfeld3: customer,
                  cfeld10: comment,
                });
                if (response?.success) {
                  message.success("Tracking Details aktualisiert");
                }
                setUpdateData(!updateData);
              }}
            >
              <IntlMessages id="save"/>
            </Button>
            <Button
              style={{ backgroundColor: "red", color: "white" }}
              onClick={async () => {
                const response = await editTrackingDetails({
                  ...Tracking,
                  nfeld2: string_to_doubleDT(
                    moment().format("DD.MM.YYYY HH:mm")
                  ),
                });
                if (response?.success) {
                  message.success("Tracking gestoppt!");
                }
                setUpdateData(!updateData);
              }}
              disabled={Tracking?.nfeld2}
            >
              
              <IntlMessages id="tracking.stopTracking"/>
            </Button>

            <Button>
              <MoreActions
                state={Tracking}
                noDelete={true}
                update={updateData}
                setUpdate={setUpdateData}
              />
            </Button>
          </div>
        ) : null}
      </>
    </>
  );
}
