import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { useHistory } from "react-router";
import IntlMessages from "util/IntlMessages";

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => history.push("/main/account")}>{<IntlMessages id="account_myaccount" />}</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        showArrow={false}
      >
        <Avatar
          src={"https://via.placeholder.com/150"}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {window.localStorage.getItem("uname")}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
