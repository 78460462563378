import { Modal, Input, Form } from "antd";
import { useRef } from "react";
import { addLov } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

export default function AddLov(props) {
  const { open, setOpen } = props;

  const ref = useRef();
  const history = useHistory();

  return (
    <Modal
      title={<>Add List of Value</>}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      width="800px"
      onOk={() => {
        setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="add lov"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);

          const response = await addLov({
            ...values,
            lovkey: "",
            lovgrtext: props?.parent?.lovgrtext,
            lovgrkey: props?.parent?.lovgrkey,
            lovname: props?.parent?.lovname,
            lovshortcut: " ",
          });
          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item label="Name" name="lovvalue" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item label="Sequence" name="lovorder" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Comment"
          name="lovcomment"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
