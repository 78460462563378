import { Input, Row, Tabs, Typography } from "antd";
import { useState } from "react";
import Ranking from "./ranking";

export default function OtherRatings(props) {
  const { TabPane } = Tabs;
  const { ratings } = props;
  const { Text } = Typography;
  const [active, setActive] = useState(ratings[0]?.cfeld1);
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey={active}
          tabPosition="left"
          style={{ height: 250, width: "100%" }}
          size="small"
          onChange={(key) => {
            setActive(key);
          }}
        >
          {ratings?.map((rating) => {
            return (
              <TabPane tab={rating?.cfeld1} key={rating?.cfeld1}>
                <>
                  <Ranking
                    value={rating?.nfeld2}
                    explaination={rating?.cfeld2}
                    disable={true}
                  />
                  <Row
                    style={{
                      width: "100%",
                      padding: "12px",
                    }}
                  >
                    <Text
                      style={{
                        color: "#727272",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      Explanation
                    </Text>
                    <Input.TextArea rows={5} value={rating?.cfeld2} disabled />
                  </Row>
                </>
              </TabPane>
            );
          })}
        </Tabs>
      </Row>
    </>
  );
}
