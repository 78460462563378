import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Dropdown,
  Menu,
  Typography,
  DatePicker,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { editAppliance } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { footerContent } from "../../index";
import moment from "moment";
import {
  string_to_double,
  double_to_string,
} from "../../../../../../constants";

export default function EditAppliance(props) {
  const { appliance, setAppliance, fields, emptyFooter } = props;
  const open = Boolean(appliance);
  const ref = useRef();
  const history = useHistory();

  const { Text } = Typography;
  const [necessary, setNecessary] = useState(null);
  const [condition, setCondition] = useState(null);
  const [interval, setInterval] = useState(null);
  const [type, setType] = useState(null);
  const [group, setGroup] = useState(null);
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld3, setnfeld3] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld5, setnfeld5] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setNecessary(appliance?.cfeld10 ? appliance?.cfeld10 : null);
    setCondition(appliance?.cfeld2 ? appliance?.cfeld2 : null);
    setInterval(appliance?.cfeld17 ? appliance?.cfeld17 : null);
    setType(appliance?.cfeld4 ? appliance?.cfeld4 : null);
    setGroup(appliance?.cfeld3 ? appliance?.cfeld3 : null);
    setnfeld1(
      appliance?.nfeld1
        ? moment(double_to_string(appliance?.nfeld1), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setnfeld3(
      appliance?.nfeld3
        ? moment(double_to_string(appliance?.nfeld3), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setnfeld5(
      appliance?.nfeld5
        ? moment(double_to_string(appliance?.nfeld5), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [appliance]);

  const type_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setType(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.appliance_type?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const group_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setGroup(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.appliance_group?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const condition_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCondition(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.condition?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const necessary_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setNecessary(e.key);
        ref.current.setFieldsValue({ cfeld10: e.key });
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.check_necessary_appl?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const interval_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setInterval(e.key);
        ref.current.setFieldsValue({ cfeld17: e.key });
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.check_interval?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  function handOkClick() {
    setAppliance(null);
    ref?.current?.submit();
  }
  function handleCancelClick() {
    setAppliance(null);
  }

  const fc = footerContent(emptyFooter, handOkClick, handleCancelClick);

  return (
    <Modal
      title={
        <>
          <IntlMessages id="edit" /> <IntlMessages id="appliance.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      onOk={() => {
        setAppliance(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setAppliance(null);
      }}
      footer={fc}
    >
      <Form
        name="Add appliance"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...appliance,
          nfeld1: nfeld1,
          nfeld3,
          nfeld3,
          nfeld5: nfeld5,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editAppliance(
            {
              ...appliance,
              ...values,
              cfeld2: condition,
              cfeld10: necessary,
              cfeld17: interval,
              cfeld4: type,
              cfeld3: group,
              nfeld1: string_to_double(nfeld1),
              nfeld3: string_to_double(nfeld3),
              nfeld5: string_to_double(nfeld5),
            },
            1
          );
          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="appliance_ID" />}
              name="cfeld1"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="input_should_not_be_empty" />,
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={
                <>
                  <IntlMessages id="appliance_no" />
                </>
              }
              name="cfeld11"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="appliance_group" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={group_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{group}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="appliance_type" />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={type_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{type}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="condition" />}
              name="cfeld2"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={condition_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{condition}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="in_ownership" />}
              name="cfeld9"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="serial_no" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cost_center" />}
              name="cfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="ce_mark" />}
              name="cfeld8"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="launch_date" />}
              name="nfeld1"
              style={{ marginBottom: 0 }}
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "The field should not be empty!",
                  whitespace: true,
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                //defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/*<Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="check_necessary" />}
              name="cfeld10"
              rules={[
                {
                  message: <IntlMessages id="input_should_not_be_empty" />,
                },
              ]}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
            >
              <Dropdown overlay={necessary_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{necessary}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="next_check" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
              initialValue={moment()}
            >
              <DatePicker
                style={{ width: "100%" }}
                //defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld3(d);
                }}
              />
            </Form.Item>
              </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="check_intervall" />}
              name="cfeld17"
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
            >
              <Dropdown overlay={interval_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{interval}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        */}
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="producer" />}
              name="cfeld13"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="supplier" />}
              name="cfeld14"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="last_seen" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld5}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld5(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="QR_code" />}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
