import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useRef, useState } from "react";
import { addFloor } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import {
  string_to_double,
  double_to_string,
} from "../../../../../../constants";

export default function AddFloor(props) {
  const { open, setOpen, setPage, parent } = props;
  const [nfeld4, setnfeld4] = useState(moment(moment(), "DD.MM.YYYY"));
  const ref = useRef();
  const history = useHistory();

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="floor.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="Add Floor"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addFloor(
            { ...values, nfeld4: string_to_double(nfeld4) },
            parent
          );
          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
          setOpen(!open);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="name_identifier" />}
              name="cfeld1"
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="floor_no" />}
              name="cfeld2"
              rules={[
                {
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="total_area" />}
              name="nfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="net_area" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="effective_area" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="facade_area" />}
              name="nfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="window_area" />}
              name="nfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="jalousie_area" />}
              name="nfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="next_planned_check" />}
          style={{ marginBottom: 0 }}
        >
          <DatePicker
            style={{ width: "100%" }}
            defaultValue={nfeld4}
            format="DD.MM.YYYY"
            onChange={(e, d) => {
              setnfeld4(d);
            }}
          />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld6"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
