import { Row, Typography, Col, Pagination, Spin, Tabs, Empty,Popconfirm,Tooltip } from "antd";
import { getBuildings, getUrgentChecksforBuildings, getExcelForUrgentChecksforBuildings } from "./network_requests";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { double_to_string, getWarning } from "../../../constants";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import boardwPenIcon from "../../../assets/Icons_waste/boardwPen.png";
import AddInspection from "./NewInspection";
import { FileExcelOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { TabPane } = Tabs;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function BuildingChecks({ type }) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [building, setBuilding] = useState(null);
  const [order, setOrder] = useState("ASC");
  const [newInspection, setNewInspection] = useState(null);

  useEffect(async () => {
    setLoad(true);
    const buildings_ = await getBuildings();
    if (buildings_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setBuildings(buildings_?.list);
    setBuilding(
      buildings_?.list?.length > 0 ? buildings_?.list[0]?.stkey : null
    );
    setLoad(false);
  }, []);

  useEffect(async () => {
    if (building) {
      setLoad(true);
      const checks = await getUrgentChecksforBuildings(building, order, type);
      if (checks?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setData(checks);
      setLoad(false);
    }
  }, [building, order, type]);

  return (
    <>
     <AddInspection
        open={newInspection}
        setOpen={setNewInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <Tabs
                defaultActiveKey={building}
                tabPosition="left"
                style={{ height: "75vh" }}
                size="small"
                onChange={(key) => {
                  setBuilding(key);
                }}
              >
                {buildings?.map((building, i) => {
                  return (
                    <TabPane tab={building?.cfeld1} key={building?.stkey}>
                      <Row
                        style={{
                          backgroundColor: "#FAFAFA",
                          borderBottom: "1px solid #eaeaea",
                          fontWeight: 500,
                          padding: "10px 30px",
                          margin: "0 15px",
                        }}
                      >
                        {/* <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="Machine Name/Type" />
                          </Text>
                        </Col> */}
                        <Col
                          span={8}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="Prüfung" />
                          </Text>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="cockpit.LastCheck" />
                          </Text>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Row style={{ display: "flex", alignItems: "center" }}>
                            <Col
                            xs={8}
                            style={{
                              padding: 0,
                            }}
                            >
                              <Text>
                                <IntlMessages id="cockpit.NextCheck" />

                                {order === "ASC" ? (
                                  <ArrowDownOutlined
                                    onClick={() => {
                                      setOrder("DESC");
                                    }}
                                    style={{ paddingLeft: "5px" }}
                                  />
                                ) : (
                                  <ArrowUpOutlined
                                    onClick={() => {
                                      setOrder("ASC");
                                    }}
                                    style={{ paddingLeft: "5px" }}
                                  />
                                )}
                              </Text>
                              </Col>
                              <Col
                              xs={12}
                              style={{ display: "flex", alignItems: "center" }}
                              >
                                <Popconfirm
                                title="Excel Datei erzeugen?"
                                onConfirm={async () => {
                                  const response = await getExcelForUrgentChecksforBuildings(building?.stkey,building?.cfeld1, order, type);
                                  if (response?.file) {
                                    downloadToLocal(response?.file, response?.filename);
                                  }
                                }}
                                okText="Ja"
                                cancelText="Nein"
                                >
                                <Tooltip title="Excel Datei aus der Liste erzeugen">
                                  <FileExcelOutlined style={{ cursor: "pointer" }} />
                                </Tooltip>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {data?.length === 0 ? <Empty description="Keine Prüfungen vorhanden!"/> : null}

                      {data?.slice((page - 1) * 10, page * 10)?.map((item) => {
                        return (
                          <Row
                            style={{
                              backgroundColor: "#fff",
                              borderBottom: "1px solid #eaeaea",
                              padding: "10px 30px",
                              margin: "0 15px",
                            }}
                          >
                            {/* <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.objGroup}
                              </p>
                            </Col> */}
                            <Col
                              span={8}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "12px" }}>
                                {item?.objID}
                              </p>
                              <p style={{ margin: 0, fontSize: "12px" }}>
                                {item?.objGroup}
                              </p>
                              <p style={{ margin: 0, fontSize: "12px" }}>
                                {item?.objType}
                              </p>
                              <p style={{ margin: 0, fontSize: "12px" }}>
                                {item?.objKennzeichen}
                              </p>
                            </Col>
                            <Col
                              span={8}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.lastCheck
                                  ? double_to_string(item?.lastCheck)
                                  : ""}{" "}
                              </p>
                              <p style={{ margin: 0, fontSize: "12px" }}>
                                {item?.checkPerson}
                              </p>
                              <p style={{ margin: 0, fontSize: "12px" }}>
                                {item?.checkIntervall}
                              </p>
                            </Col>
                            <Col
                              span={8}
                              style={{
                                padding: 0,
                              }}
                            >
                            <Row style={{ display: "flex", alignItems: "center" }}>
                            <Col
                              span={8}
                              style={{
                                padding: 0,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor:
                                      getWarning(item?.warn2) > 0
                                        ? "red"
                                        : getWarning(item?.warn1) < 0
                                        ? "green"
                                        : "yellow",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div style={{ paddingLeft: "5px" }}>
                                  <p style={{ margin: 0, fontSize: "14px" }}>
                                    {item?.nextCheck
                                      ? double_to_string(item?.nextCheck)
                                      : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: "14px" }}>
                                    {item?.checkPerson}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={boardwPenIcon}
                              style={{ height: "20px", marginRight: "5px" }}
                            ></img>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "12px",
                                color: "#038fde",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setNewInspection({
                                  ...item,
                                });
                              }}
                            >
                            <IntlMessages id="cockpit.NewInspection" />
                            </p>
                          </Col>
                          </Row>
                          </Col>
                          </Row>

                        );
                      })}
                    </TabPane>
                  );
                })}
                {/* {[buildings?.map(i => (
            <TabPane tab={`Tab-${i}`} key={i} disabled={i === 28}>
              Content of tab {i}
            </TabPane>
          ))} */}
              </Tabs>
            </Col>
          </Row>

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={data?.length}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
