import { url, string_to_double } from "../../../constants";
import axios from "axios";
import moment from "moment";

export const getPictures = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}requestImageList`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFile = async (key, filename, group, stclass) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,
        detclass: 0,
        stclass: stclass,
        group: group,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}removeFile`,
      {
        stkey: key,
        cfeld1: filename,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadFile = async (file, key, attribute, group, stclass) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileWithMIMO`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: attribute,
        detclass: 0,
        group: group,
        stclass: stclass,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFiles = async (key, attribute, group, stclass) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}requestFileList`,
      {
        attribute: attribute,
        objID: key,
        group: group,
        stClass: stclass,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getPicture = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}downloadImage`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadImage = async ({ file, stclass, objID, group }) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const date = moment().format("YYYYMMDD");
    const time = moment().format("HHmm");
    const response = await axios.post(
      `${url}uploadImage`,
      {
        file: file?.slice(22),
        stclass: stclass,
        filename: `${date}${time}_${objID}.png`,
        attribute: "none",
        group: group,
        date: parseInt(date),
        time: parseInt(time),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: objID,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteImage = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}delImage`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllInspections = async (stKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g01",
        stClass: "9",
        stKey: stKey,
        detail: "cldetail_g01",
        detClass: "4",
        type: "none",
        limitFrom: "0",
        limitTo: 10,
        orderBy: "nfeld1",
        cfields: [],
        nfields: [
          { fieldNo: "nfeld3", fromVal: 0, opVal: ">" },
          { fieldNo: "active", fromVal: 1, opVal: "=" },
        ],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllRepairs = async (stKey, stclass) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g01",
        stClass: stclass,
        stKey: stKey,
        detail: "cldetail_g01",
        detClass: "5",
        type: "none",
        limitFrom: "0",
        limitTo: 10,
        orderBy: "nfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteInspection = async (values, stclass) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        active: 0,
        stclass: stclass ? stclass : 9,
        detclass: 4,
        gisid: null,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteRepair = async (values, stclass) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        active: 0,
        stclass: stclass ? stclass : 15,
        detclass: 5,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addRepair = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        detkey: "",
        active: 1,
        detclass: "5",
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editRepair = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        active: 1,
        detclass: "5",
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
