import {
  Row,
  Typography,
  Col,
  DatePicker,
  Pagination,
  Spin,
  Select,
} from "antd";
import {
  deleteTracking,
  getAllStaff,
  getCostCenter,
  getFields,
  getTrackings,
  getVehicles,
} from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import in_progress from "../../../assets/Icons_waste/task_inprogress.png";
import trackingOpen from "../../../assets/Icons_waste/task_open.png";
import planned from "../../../assets/Icons_waste/task_planned.png";
import finished from "../../../assets/Icons_waste/task_finished.png";
import AddTracking from "./addTracking";
import EditTracking from "./editTracking";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { doubleDT_to_string, double_to_string } from "../../../constants";
import MoreActions from "../../extraComponents/MoreActions";
import moment, { isMoment } from "moment";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function Tracking(props) {
  const [Tracking, setTracking] = useState([]);
  const [addTracking, setAddTracking] = useState(false);
  const [editTracking, setEditTracking] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [cFilter, setcFilter] = useState(null);
  const [updateData, setUpdateData] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState([moment().startOf("month"), moment()]);

  useEffect(async () => {
    setLoad(true);

    const Tracking_ = await getTrackings((page - 1) * 10, filter);

    const users_ = await getAllStaff();
    const customers_ = await getCostCenter();
    const vehicle_ = await getVehicles();

    setUsers(users_?.list);
    setCustomers(customers_);
    setVehicles(vehicle_?.list);

    if (Tracking_?.status === 401) {
      localStorage.removeItem("tracking_id");
      history.go(0);
    }

    const assignments_ = await getFields("G01C04D00F13");
    const persons_ = await getFields("G20C01D00F05");
    setAssignments(assignments_);
    setPersons(persons_);
    setTracking(Tracking_ ? Tracking_?.list : []);
    setCount(Tracking_?.count ? Tracking_?.count : 0);
    setLoad(false);
  }, [page, updateData, filter]);

  useEffect(() => {
    console.log(cFilter);
    const userFilter = cFilter?.username
      ? { fieldNo: "cfeld1", val: cFilter?.username, opVal: "=" }
      : null;
    const vehicleFilter = cFilter?.vehicle
      ? { fieldNo: "cfeld2", val: cFilter.vehicle, opVal: "=" }
      : null;
    const customerFilter = cFilter?.customer
      ? { fieldNo: "cfeld3", val: cFilter.customer, opVal: "=" }
      : null;

    const cfelds = [
      ...[userFilter, vehicleFilter, customerFilter]?.filter(
        (item) => item?.fieldNo
      ),
    ];
    console.log(cfelds);

    const nfelds =
      dates?.length === 2
        ? isMoment(dates[0])
          ? [
              {
                fieldNo: "nfeld1",
                fromVal: ` ${dates[0]?.format("YYYYMMDD")}0000`,
                opVal: "between",
                toVal: `${dates[1]?.format("YYYYMMDD")}2400`,
              },
            ]
          : [
              {
                fieldNo: "nfeld1",
                fromVal: ` ${dates[0]}0000`,
                opVal: "between",
                toVal: `${dates[1]}2400`,
              },
            ]
        : [];

    setFilter({
      cfields: cfelds,
      nfields: nfelds,
    });
  }, [dates, cFilter]);

  return (
    <>
      <AddTracking
        open={addTracking}
        setOpen={setAddTracking}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        persons={persons}
        assignments={assignments}
      />
      <EditTracking
        tracking={editTracking}
        setTracking={setEditTracking}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        persons={persons}
        assignments={assignments}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <Row
            style={{
              borderBottom: "1px solid #eaeaea",
              fontWeight: 400,
              padding: "20px",
              margin: "0",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Col xs={5}>
              <Text style={{ fontSize: "18px" }}><IntlMessages id="tracking.overview"/></Text>
            </Col>
            <Col xs={4}>
              <Select
                size="middle"
                value={cFilter?.username}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={<IntlMessages id="account_username"/>}
                options={[
                  ...users?.map((item) => {
                    return {
                      value: item?.cfeld1,
                      label: item?.cfeld1,
                    };
                  }),
                ]}
                onChange={(value) => {
                  setcFilter({
                    ...cFilter,
                    username: value,
                  });
                }}
              />
            </Col>
            <Col xs={4}>
              <Select
                placeholder={<IntlMessages id="vehicle.title"/>}
                allowClear
                options={[
                  ...vehicles?.map((item) => {
                    return {
                      value: item?.cfeld1,
                      label: item?.cfeld1,
                    };
                  }),
                ]}
                size="middle"
                style={{
                  width: "100%",
                }}
                value={cFilter?.vehicle}
                onChange={(value) => {
                  setcFilter({
                    ...cFilter,
                    vehicle: value,
                  });
                }}
              />
            </Col>
            <Col xs={4}>
              <Select
                placeholder={<IntlMessages id="tracking.customer"/>}
                allowClear
                options={[
                  ...customers?.map((item) => {
                    return {
                      value: item?.cfeld1,
                      label: item?.cfeld1,
                    };
                  }),
                ]}
                size="middle"
                style={{
                  width: "100%",
                }}
                value={cFilter?.customer}
                onChange={(value) => {
                  setcFilter({
                    ...cFilter,
                    customer: value,
                  });
                }}
              />
            </Col>
            <Col
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {console.log(dates)}
              <RangePicker
                format={"DD.MM.YYYY"}
                allowClear
                defaultValue={
                  isMoment(dates[0])
                    ? dates
                    : [
                        moment(dates[0], "YYYYMMDD"),
                        moment(dates[1], "YYYYMMDD"),
                      ]
                }
                onChange={(values, formatString) => {
                  const modifiedArray = formatString.map((formatString) => {
                    return formatString.replace(/\./g, "");
                  });
                  setDates(modifiedArray);
                }}
              />
              {/* {moment().format("DD.MM.YYYY")} {" > "}{" "}
              {moment().add(1, "M").format("DD.MM.YYYY")} */}
            </Col>
          </Row>
          <div style={{ padding: "40px 30px", fontWeight: 400 }}>
            <Row
              style={{
                width: "100%",
                padding: "10px 0px 10px 15px",
                backgroundColor: "#FAFAFA",
                marginLeft: "0",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 400,
              }}
            >
              <Col
                xs={2}
                style={{
                  padding: 0,
                }}
              >
                <Text>Status</Text>
              </Col>
              <Col
                xs={4}
                style={{
                  padding: 0,
                }}
              >
                <Text><IntlMessages id="account_username"/></Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text><IntlMessages id="vehicle.title"/></Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text><IntlMessages id="tracking.started"/></Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text><IntlMessages id="tracking.stopped"/></Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <Text><IntlMessages id="tracking.customer"/></Text>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <IntlMessages id="comment"/>
              </Col>
              <Col
                xs={3}
                style={{
                  padding: 0,
                }}
              >
                <IntlMessages id="more_actions"/>
              </Col>
            </Row>

            {Tracking?.map((tracking) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 0px 10px 15px",
                    margin: "0",
                    fontWeight: 400,
                  }}
                >
                  <Col
                    xs={2}
                    style={{
                      padding: 12,
                    }}
                  >
                    <img
                      src={
                        tracking?.nfeld2
                          ? finished
                          : tracking?.nfeld1
                          ? planned
                          : trackingOpen
                      }
                      style={{ width: "18px" }}
                    />
                  </Col>
                  <Col
                    xs={4}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      //setEditTracking(tracking);
                    }}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {tracking?.cfeld1}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text style={{ color: "#038fde" }}>{tracking?.cfeld2}</Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                      fontSize: "12px",
                    }}
                  >
                    <Text>
                      {tracking?.nfeld1
                        ? doubleDT_to_string(tracking?.nfeld1)
                        : " - "}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                      fontSize: "12px",
                    }}
                  >
                    <Text>
                      {tracking?.nfeld2
                        ? doubleDT_to_string(tracking?.nfeld2)
                        : " - "}
                    </Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text>{tracking?.cfeld3}</Text>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Text>{tracking?.cfeld10}</Text>
                  </Col>
                  <Col xs={3}>
                    <MoreActions
                      state={tracking}
                      noDelete={true}
                      update={updateData}
                      setUpdate={setUpdateData}
                    />
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                padding: "10px 30px 0 30px",
                backgroundColor: "#fafafa",
                borderBottom: "1px solid #eaeaea",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                defaultPageSize={10}
                style={{
                  margin: 0,
                }}
                onChange={(newpage) => {
                  setPage(newpage);
                }}
              />
            </Row>
          </div>
        </div>
      ) : null}
    </>
  );
}
