import {
  Row,
  Typography,
  Upload,
  Button,
  Col,
  Card,
  message,
  Pagination,
  List,
  Empty,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import {
  CloseOutlined,
  UploadOutlined,
  FileOutlined,
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import {
  downloadFile,
  getFiles,
  removeFile,
  uploadFile,
} from "./network_Request";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function downloadToLocal(pdf, filename) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UploadDocs(props) {
  const { templateKey } = props;

  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState(1);

  const attribute = "general";

  const uploadProps = {
    accept: ".pdf,.png,.jpg,.doc,.docx",
    showUploadList: false,

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },

    multiple: true,

    onChange: async (info) => {
      const { status } = info.file;

      if (status === "done") {
        let reader = new FileReader();

        reader.onload = async (e) => {
          console.log(e.target.result);
          const response = await uploadFile(
            {
              filename: info.file.name,
              file: e.target.result,
            },
            templateKey,
            attribute
          );
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          if (response?.success) {
            success();
            setUpdate(!update);
          } else {
            error();
          }
        };

        reader.readAsDataURL(info.file.originFileObj);
      }
    },
  };

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await getFiles(templateKey, attribute);
      if (response?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }

      setFiles([...response]);
    })();
  }, [update, attribute]);

  return (
    <Row
      style={{
        marginLeft: "20px",
        padding: "20px",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Typography style={{ fontSize: "22px", fontWeight: 500 }}>
          DOKUMENTE
        </Typography>
      </Row>
      <Row style={{ width: "100%", marginTop: "16px" }}>
        <Upload {...uploadProps} customRequest={dummyRequest}>
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
      </Row>
      <Row
        style={{
          width: "100%",
          margin: "16px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Upload.Dragger
          customRequest={dummyRequest}
          {...uploadProps}
          style={{ width: "70vw" }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>

          <p className="ant-upload-text">
            Die Datei über dieses Feld ziehen zum Hochladen.
          </p>
          <p className="ant-upload-hint">
            Support für single or bulk upload max 5 MB.
          </p>
        </Upload.Dragger>
      </Row>
      <Row style={{ width: "100%", marginTop: "20px" }}>
        <List style={{ width: "100%", padding: 0 }}>
          {files?.length <= 0 ? <Empty /> : null}
          <Row>
            {files?.slice((page - 1) * 10, page * 10)?.map((doc) => {
              return (
                <Col xs={12}>
                  <List.Item
                    style={{
                      width: "100%",
                      border: "1px solid #808080",
                      margin: "5px 0",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        padding: 0,
                        margin: 0,
                        alignItems: "center",
                      }}
                    >
                      <Col
                        xs={2}
                        style={{
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <FileOutlined style={{ fontSize: "18px" }} />
                      </Col>
                      <Col
                        xs={20}
                        style={{
                          padding: 0,
                          margin: 0,
                          color: "#038fde",
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          const response = await downloadFile(
                            doc?.gisid,
                            doc?.cfeld1
                          );
                          if (response?.file) {
                            downloadToLocal(response?.file, response?.filename);
                          }
                        }}
                      >
                        {doc?.cfeld1?.length < 30
                          ? doc?.cfeld1
                          : `${doc?.cfeld1?.substr(0, 30)}...`}
                      </Col>
                      <Col
                        xs={2}
                        style={{
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Popconfirm
                          title="Are you sure you want to delete this file?"
                          onConfirm={async () => {
                            const response = await removeFile(
                              doc?.stkey,
                              doc?.cfeld1
                            );
                            if (response?.success) {
                              success();
                              setUpdate(!update);
                            } else {
                              error();
                            }
                          }}
                        >
                          <DeleteOutlined
                            style={{ fontSize: "18px", color: "#f32013" }}
                          />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </List.Item>
                </Col>
              );
            })}
          </Row>
        </List>
      </Row>
      {files?.length > 0 ? (
        <Row
          style={{
            width: "100%",
            marginTop: "16px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={page}
            total={files?.length}
            pageSize={10}
            onChange={(page_) => {
              setPage(page_);
            }}
          />
        </Row>
      ) : null}
    </Row>
  );
}
