import React, { useCallback, useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { editCheckPoint } from "./network_requests";
import { useHistory } from "react-router";

const Write = ({ checkPoint, setActive }) => {
  const [status, setStatus] = useState("Pending");
  const history = useHistory();

  const onWrite = async (tag) => {
    try {
      //alert(`value needed to be saved: ${tag}`);
      console.log("value needed to be saved: ", tag);
      if ("NDEFReader" in window) {
        console.log("Creating new window");
        const ndef = new window.NDEFReader();
        console.log("Starting the scan");
        await ndef.scan();
        console.log("Starting the write");
        const encoder = new TextEncoder();
        await ndef.write({
          records: [
            {
              recordType: "text",
              data: encoder.encode(tag),
            },
          ],
        });
        console.log("Write finished. Updating nfeld5 value.");

        await editCheckPoint({
          ...checkPoint,
          nfeld5: 1,
        });
        setStatus("Writing Finished");
        //alert("Value Saved");
      } else {
        setStatus("Device Unsupported");
        //alert("NFC is not supported on this device.");
      }
    } catch (error) {
      //alert(`Error: ${error.message}`);
      console.error(error);
    }
  };

  const write = useCallback(async () => {
    await onWrite(checkPoint?.cfeld19);
  }, []);

  useEffect(() => {
    write();
  }, [write]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
          onClick={() => {
            setActive(false);
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "22px", fontWeight: "bold" }} />
        </Typography>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 450,
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Write
        </Typography>
      </div>
      <Typography
        style={{
          fontSize: "22px",
          fontWeight: 400,
          marginTop: "10px",
          padding: "10px",
        }}
      >
        Write Status: {status}
      </Typography>
    </>
  );
};

export default Write;
