import { Button, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { uploadImage } from "./network_requests";

export default function SignDelivery(props) {
  const { open, setOpen, stkey } = props;

  const ref = useRef();
  const history = useHistory();

  const clearSignature = () => {
    ref.current.clear();
  };

  const saveSignature = async () => {
    const signatureDataURL = ref.current.toDataURL("image/png");

    const upload = await uploadImage(signatureDataURL, stkey);

    if (upload?.success === "saved") {
      message.success("Image Uploaded Successfully");
      ref.current.clear();
      setOpen(!open);
    } else {
      message.error("An error occurred while performing action");
    }
  };

  return (
    <>
      <>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#038fde",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "22px", fontWeight: "bold" }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            unterschreiben
          </Typography>
        </div>
      </>
      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "center",
          marginTop: "25px",
          padding: "20px",
        }}
      >
        <SignatureCanvas
          penColor="#000"
          backgroundColor="#ebeaeb"
          ref={ref}
          canvasProps={{ width: 325, height: 200, className: "sigCanvas" }}
        />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "flex-end",
          padding: "20px",
        }}
      >
        <Button type="default" onClick={clearSignature}>
          löschen
        </Button>
        <Button type="primary" onClick={saveSignature}>
          speichern
        </Button>
      </div>
    </>
  );
}
