import { Row, Col, Typography, Collapse, Switch } from "antd";
import ServicePanel from "./servicePanel";

const { Panel } = Collapse;

export default function Services({ services, setShowAll }) {
  return (
    <>
      <Row style={{ marginBottom: "10px" }}>
        <Col xs={18}>
          <Typography.Text
            style={{ fontWeight: 600, textTransform: "uppercase" }}
          >
            Service Bereiche
          </Typography.Text>
        </Col>
        <Col xs={6}>
          <Typography.Text
            style={{ fontWeight: 600, textTransform: "uppercase" }}
          >
            Alle anzeigen{" "}
            <Switch
              onChange={(checked) => {
                setShowAll(checked);
              }}
            />
          </Typography.Text>
        </Col>
      </Row>
      <Collapse
        style={{
          width: "100%",
          borderRadius: "5px",
        }}
        defaultActiveKey={[1, 2, 3, 4, 5,6,7,8,9,10]}
      >
        {services?.map((service, index) => {
          return (
            <Panel
              header={
                <span
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      require(`assets/Icons_waste/${service?.serviceIcon}`)
                        ?.default
                    }
                    height={"22px"}
                    style={{ marginRight: "5px" }}
                  />
                  {service?.serviceTitle} {service?.bccMailAdress == " " ? null : "(bcc: ".concat(service?.bccMailAdress).concat(")")}
                  
                </span>
              }
              key={index + 1}
              style={{
                borderRadius: 0,
                borderBottomLeftRadius:
                  index === services?.length - 1 ? "8px" : 0,
                borderBottomRightRadius:
                  index === services?.length - 1 ? "8px" : 0,
              }}
            >
              <ServicePanel service={service} />
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
}
