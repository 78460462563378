import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useRef, useState, useEffect } from "react";
import { editVehicle } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

import { string_to_double, double_to_string } from "../../../../constants";

export default function EditVehicles(props) {
  const { vehicle, setVehicle, setPage, emptyFooter } = props;
  const open = Boolean(vehicle);
  const ref = useRef();
  const history = useHistory();
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setnfeld1(
      vehicle?.nfeld1
        ? moment(double_to_string(vehicle?.nfeld1), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [vehicle]);

  function handOkClick() {
    setVehicle(null);
    ref?.current?.submit();
  }
  function handleCancelClick() {
    setVehicle(null);
  }

  return (
    <Modal
      title={
        <>
          <IntlMessages id="vehicle.title" /> <IntlMessages id="edit" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      onOk={() => {
        setVehicle(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setVehicle(null);
      }}
    >
      <Form
        name="add vehicles"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...vehicle,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editVehicle({
            ...vehicle,
            ...values,
            nfeld1: string_to_double(nfeld1),
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Name / Type" />}
              name="cfeld1"
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: "Pflichtfeld!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.registrationNo." />}
              name="cfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.condition" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.costcenter" />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.inventoryNo." />}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.firstRegistration" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.QR-Code" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.AM-Check" />}
              name="cfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.Producer" />}
              name="cfeld8"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.ProducerTelno" />}
              name="cfeld9"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={18} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.Supplier" />}
              name="cfeld10"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="vehicle.SupplierTelno" />}
              name="cfeld11"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="vehicle.comment" />}
          name="cfeld12"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
