import { Typography, Menu, Dropdown, Modal, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { useEffect, useState } from "react";
import {
  getPictures,
  getPicture,
  uploadImage,
  deleteImage,
  uploadFile,
} from "./network_requests";

import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import PhotoAction from "./components/PhotoAction";
import Inspections from "./components/Inspections";
import AddInspection from "../../main/pruefCockpit/NewInspection";
import EditInspection from "../../main/pruefCockpit/EditInspection";
import { DownOutlined } from "@ant-design/icons";
import Repairs from "./components/Repairs";
import AddRepair from "./components/AddRepair";
import EditRepair from "./components/EditRepair";

export default function MoreActions(props) {
  const { state, stClass, group } = props;

  const { Text } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInspection, setIsInspection] = useState(false);
  const [isRepair, setIsRepair] = useState(false);
  const [attribute, setAttribute] = useState("none");
  const [photo, setPhoto] = useState(null);
  const [image, setImage] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [filesUpload, setFilesUpload] = useState(false);
  const [open, setOpen] = useState(null);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);
  const [newInspection, setNewInspection] = useState(null);
  const [editInspection, setEditInspection] = useState(null);
  const [newRepair, setNewRepair] = useState(null);
  const [editRepair, setEditRepair] = useState(null);
  const [load, setLoad] = useState(false);

  const uploadProps = {
    accept: ".png",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          setFile(e.target.result);
        };
        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  const menu = (
    <Menu>
      {props?.noPic ? null : (
        <>
          <Menu.Item
            key="0"
            onClick={() => {
              setIsRepair(false);
              setIsInspection(false);
              setIsModalVisible(!isModalVisible);
              setAttribute("none");
            }}
          >
            <IntlMessages id="submenu.pictures" />
          </Menu.Item>

          {props?.fileButton ? (
            <Menu.Item
              key="1"
              onClick={() => {
                setIsRepair(false);
                setIsInspection(false);
                setIsModalVisible(!isModalVisible);
                setAttribute("file");
              }}
            >
              Files
            </Menu.Item>
          ) : null}
        </>
      )}
      {props?.noDelete ? null : (
        <Menu.Item
          key="2"
          onClick={async () => {
            if (props?.setExitObject) {
              props?.setExitObject("appliance");
            }
            if (props?.setOpen) {
              props?.setOpen(state);
            }
            if (props?.setExitModal) {
              props?.setExitModal(!props.exitModal);
            }
            if (props?.onDelete) {
              const response = await props?.onDelete({ ...state }, 1);
              if (response?.success === "saved" && props?.setUpdate) {
                props?.setUpdate(!props?.update);
              }
            }
            if (!props?.onDelete && props?.setUpdate) {
              props?.setUpdate(!props?.update);
            }
          }}
        >
          <IntlMessages id="submenu.exit_object" />
        </Menu.Item>
      )}
      {props.inspection ? (
        <Menu.Item
          key="3"
          onClick={() => {
            setIsRepair(false);
            setIsModalVisible(!isModalVisible);
            setIsInspection(!isInspection);
          }}
        >
          <IntlMessages id="cockpit.Inspections" />
        </Menu.Item>
      ) : null}
      {props.repair ? (
        <Menu.Item
          key="4"
          onClick={() => {
            setIsInspection(false);
            setIsModalVisible(!isModalVisible);
            setIsRepair(!isRepair);
          }}
        >
          <IntlMessages id="cockpit.Repairs" />
        </Menu.Item>
      ) : null}
    </Menu>
  );

  useEffect(async () => {
    if (file) {
      const upload = await uploadFile(
        {
          file: file,
          filename: `Captured ${Date.now()}.png`,
        },
        state?.stkey,
        "none",
        group ?? 1,
        state?.stclass
        //   {
        //   file: file,
        //   objID: state?.stkey,
        //   stclass: state?.stclass,
        //   group: 1,
        // }
      );
      setFile(null);
      if (upload?.success === "saved") {
        message.success("Image Uploaded Successfully");
        setImage(null);
        // setIsModalVisible(!isModalVisible);
        setUpdate(!update);
      } else {
        message.error("An error occurred while performing action");
      }

      //setIsModalVisible(!isModalVisible);
    }
  }, [file]);

  useEffect(async () => {
    const pictures_ = await getPictures({
      attribute: "none",
      objID: state?.stkey,
      group: group ?? "1",
      stClass: state?.stclass,
    });
    console.log(pictures_?.length);
    setPictures(pictures_);
  }, [state, update]);

  useEffect(async () => {
    if (photo) {
      setIsModalVisible(!isModalVisible);
      const picture_ = await getPicture({
        imageName: photo?.cfeld1,
        objID: photo?.gisid,
        attribute: "none",
        stclass: photo?.nfeld2,
        group: photo?.nfeld1,
      });
      setImage(picture_);
    }
  }, [photo]);

  return (
    <>
      <AddInspection
        open={newInspection}
        setOpen={setNewInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        roomAppliance={true}
        stclass={stClass}
      />
      <AddRepair
        open={newRepair}
        setOpen={setNewRepair}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        stclass={stClass}
      />
      <EditInspection
        open={editInspection}
        setOpen={setEditInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        reset={null}
        roomAppliance={true}
        stclass={stClass}
      />
      <EditRepair
        open={editRepair}
        setOpen={setEditRepair}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        stclass={stClass}
      />

      <div style={{ position: "fixed", left: "25%", top: "20%", zIndex: 24 }}>
        {cameraOpen ? (
          <Camera
            isImageMirror={false}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            // idealResolution={{ height: 303, width: 290 }}
            onTakePhoto={(dataUri) => {
              // dispatch(setCamera({ ...camera, isOpen: false }));
              setFile(dataUri);
              setCameraOpen(!cameraOpen);
              setIsModalVisible(!isModalVisible);
            }}
          />
        ) : null}
      </div>

      <Modal
        title={`Photo ${state?.cfeld1}`}
        visible={Boolean(photo)}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
      >
        <img
          src={`data:image/jpeg;base64,${image?.file}`}
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
      <Modal
        title={
          isRepair
            ? `Repair ${state?.cfeld1}`
            : isInspection
            ? `Inspektionen ${state?.cfeld1}`
            : isRepair
            ? `Reparatur ${state?.cfeld1}`
            : attribute === "file"
            ? `Files ${state?.cfeld1}`
            : `Photo ${state?.cfeld1}`
        }
        width={isInspection || isRepair ? 800 : 450}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        {isInspection ? (
          <Inspections
            setNewInspection={setNewInspection}
            state={state}
            setEditInspection={setEditInspection}
            update={update}
            setUpdate={setUpdate}
            stclass={stClass}
          />
        ) : isRepair ? (
          <Repairs
            setNewRepair={setNewRepair}
            state={state}
            setEditRepair={setEditRepair}
            update={update}
            setUpdate={setUpdate}
            stclass={stClass}
          />
        ) : (
          <PhotoAction
            pictures={pictures}
            setPhoto={setPhoto}
            setOpen={setOpen}
            open={open}
            deleteImage={deleteImage}
            uploadProps={uploadProps}
            setCameraOpen={setCameraOpen}
            cameraOpen={cameraOpen}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            stkey={state?.stkey}
            stclass={state?.stclass}
            attribute={attribute}
            update={update}
            group={group}
          />
        )}
      </Modal>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Text
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
        >
          <IntlMessages id="more_actions" />
        </Text>
      </Dropdown>
    </>
  );
}
