import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Dropdown,
  Typography,
  Menu,
  InputNumber,
  DatePicker,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { editTracking } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { doubleDT_to_string, string_to_doubleDT } from "../../../constants";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

export default function EditTracking(props) {
  const { tracking, setTracking, assignments, persons } = props;

  const open = Boolean(tracking);
  const [assignment, setAssignment] = useState("");
  const [person, setPerson] = useState("");
  const [nfeld5, setnfeld5] = useState(null);
  const [nfeld6, setnfeld6] = useState(null);
  const [nfeld7, setnfeld7] = useState(null);
  const { Text } = Typography;
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    setAssignment(tracking?.cfeld4 ? tracking?.cfeld4 : null);
    setPerson(tracking?.cfeld5 ? tracking?.cfeld5 : null);
    setnfeld5(
      tracking?.nfeld5
        ? moment(doubleDT_to_string(tracking?.nfeld5), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld6(
      tracking?.nfeld6
        ? moment(doubleDT_to_string(tracking?.nfeld6), "DD.MM.YYYY HH:mm")
        : null
    );
    setnfeld7(
      tracking?.nfeld7
        ? moment(doubleDT_to_string(tracking?.nfeld7), "DD.MM.YYYY HH:mm")
        : null
    );
  }, [tracking]);

  const assignment_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setAssignment(e.key);
      }}
    >
      {assignments?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const person_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setPerson(e.key);
      }}
    >
      {persons?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  // useEffect(() => {
  //   if (tracking?.groupkey) {
  //     const group_ = groups?.find((item) => item?.groupkey === tracking.groupkey);
  //     setGroup(group_);
  //   }
  // }, [tracking]);

  return (
    <Modal
      title={
        <>
          <IntlMessages id="trackings.edittracking" />
        </>
      }
      width="660px"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setTracking(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setTracking(null);
      }}
    >
      <Form
        name="add Tracking"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...tracking,
          nfeld5: nfeld5,
          nfeld6: nfeld6,
          nfeld7: nfeld7,
          nfeld3: tracking
            ? doubleDT_to_string(tracking?.nfeld3, "DD.MM.YYYY HH:mm")
            : null,
        }}
        onFinish={async (values) => {
          if (values?.cfeld1) {
            setTracking(null);
            props.setLoad(true);
            const response = await editTracking({
              ...tracking,
              ...values,
              cfeld4: assignment,
              cfeld5: person,
              nfeld3: string_to_doubleDT(values?.nfeld3),
              nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
              nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
              nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
            });

            if (response.status === 401) {
              localStorage.removeItem("tracking_id");
              history.go(0);
            }

            props.setUpdate(!props.update);
          }
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="trackings.title" />}
          name="cfeld1"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: "Please enter the title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="trackings.description" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <TextArea rows={6} />
        </Form.Item>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="trackings.Creator" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="trackings.creationdatetime" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="trackings.assignment" />}
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={assignment_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{assignment}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="trackings.executionPerson" />}
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={person_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{person}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="trackings.durationh" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="trackings.InProgress" />}
              name="nfeld5"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld5}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld5(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="trackings.Planned" />}
              name="nfeld6"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld6}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld6(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="trackings.FinishedDateTime" />}
              name="nfeld7"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld7}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld7(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="trackings.comment" />}
          name="cfeld6"
          style={{ marginBottom: "15px" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
