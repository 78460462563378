import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Dropdown,
  Menu,
} from "antd";
import { useEffect, useRef, useState } from "react";
//import { addEstates } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { editTree } from "./network_requests";
import TreeTabs from "./TreeTabs";
import { string_to_double, double_to_string } from "../../../../constants";
import { DownOutlined } from "@ant-design/icons";
import Checks from "./Checks";
import Actions from "./Actions";

export default function EditTree(props) {
  const { open, setOpen, setPage, fields } = props;
  const [tabValue, setTabValue] = useState(1);
  const history = useHistory();
  const ref = useRef();
  const [nfeld6, setnfeld6] = useState(moment(moment(), "DD.MM.YYYY"));
  const [action, setAction] = useState(null);
  const [type, setType] = useState(null);
  const [func, setFunc] = useState(null);
  const [place, setPlace] = useState(null);
  const [morphology, setMorphology] = useState(null);
  const { Text } = Typography;

  const type_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setType(e.key);
      }}
    >
      {fields?.type?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const func_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setFunc(e.key);
      }}
    >
      {fields?.func?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const morphology_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setMorphology(e.key);
      }}
    >
      {fields?.morphology?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const place_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setPlace(e.key);
      }}
    >
      {fields?.place?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  useEffect(() => {
    setnfeld6(
      open?.nfeld6
        ? moment(double_to_string(open?.nfeld6), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [open]);

  return (
    <Modal
      title={<TreeTabs tabValue={tabValue} setTabValue={setTabValue} />}
      width="920"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={
        tabValue === 1
          ? "Save"
          : tabValue === 2
          ? "Neue Kontrolle"
          : "Neue Maßnahme"
      }
      onOk={() => {
        if (tabValue === 1) {
          ref?.current?.submit();
        }
        if (tabValue === 2) {
          setAction("addCheck");
        }
        if (tabValue === 3) {
          setAction("addAction");
        }
      }}
      onCancel={() => {
        setOpen(null);
      }}
      style={{
        minWidth: "920px",
        maxWidth: "920px",
      }}
    >
      {tabValue === 1 ? (
        <Form
          name="Edit Tree"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ...open,
          }}
          onFinish={async (values) => {
            props.setLoad(true);
            const response = await editTree({
              ...open,
              ...values,
              nfeld6: string_to_double(nfeld6),
            });
            if (response?.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            props.setUpdate(!props.update);
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.TreeID" />}
                name="cfeld1"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.controller" />}
                name="cfeld13"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="Date" />}
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={nfeld6}
                  format="DD.MM.YYYY"
                  onChange={(e, d) => {
                    setnfeld6(d);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.street" />}
                name="cfeld6"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.streetno" />}
                name="cfeld19"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.owner" />}
                name="cfeld16"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.coverage" />}
                name="nfeld4"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.diameter" />}
                name="nfeld6"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.height" />}
                name="nfeld5"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.age" />}
                name="nfeld8"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.yearofplanting" />}
                name="nfeld7"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.status" />}
                name="cfeld14"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.stand" />}
                name="cfeld15"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.area" />}
                name="cfeld29"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.type" />}
                name="cfeld22"
                style={{ marginBottom: 0 }}
              >
                <Dropdown overlay={type_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={21}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{type}</Text>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.morphology" />}
                name="cfeld10"
                style={{ marginBottom: 0 }}
              >
                <Dropdown overlay={morphology_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={21}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{morphology}</Text>
                    </Col>
                    <Col
                      span={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.crowndiameter" />}
                name="cfeld23"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.function" />}
                name="cfeld11"
                style={{ marginBottom: 0 }}
              >
                <Dropdown overlay={func_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={21}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{func}</Text>
                    </Col>
                    <Col
                      span={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.place" />}
                name="cfeld5"
                style={{ marginBottom: 0 }}
              >
                <Dropdown overlay={place_menu} trigger="click">
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      height: "36px",
                      borderRadius: "6px",
                      margin: "0px",
                    }}
                  >
                    <Col
                      span={21}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Text>{place}</Text>
                    </Col>
                    <Col
                      span={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DownOutlined style={{ color: "#d9d9d9" }} />
                    </Col>
                  </Row>
                </Dropdown>
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="outdoor.burliness" />}
                name="cfeld24"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : tabValue === 2 ? (
        <Checks
          stkey={open?.stkey}
          action={action}
          setAction={setAction}
          setLoad={props?.setLoad}
          update={props?.update}
          setUpdate={props?.setUpdate}
        />
      ) : (
        <Actions
          stkey={open?.stkey}
          action={action}
          setAction={setAction}
          setLoad={props?.setLoad}
          update={props?.update}
          setUpdate={props?.setUpdate}
        />
      )}
    </Modal>
  );
}
