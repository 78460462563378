import { Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Employees from "./employeeOverview";
import Catagories from "./generalContent";

export default function EmployeeApp() {
  const { page } = useParams();
  const [mainPage, setMainPage] = useState(page);

  useEffect(() => {
    setMainPage(page);
  }, [page]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          minHeight: "70vh",
          padding: "20px 10px",
          marginBottom: "20px",
        }}
      >
        {mainPage == 1 ? <Employees /> : mainPage == 2 ? <Catagories /> : null}
      </div>
    </>
  );
}
