import { Row, Typography, Col, Pagination, Spin } from "antd";
import { getLov } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
//import AddLov from "./addLov";
import EditList from "./editList";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function List(props) {
  const [listValues, setListValues] = useState([]);
  const [addLov, setAddLov] = useState(false);
  const [editLov, setEditLov] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    setLoad(true);
    const lov_ = await getLov();

    if (lov_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setListValues(lov_ ? lov_ : []);
    setCount(lov_?.length ? lov_?.length : 0);
    setLoad(false);
  }, [updateData]);

  return (
    <>
      <EditList
        lov={editLov}
        setLov={setEditLov}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        List of Values
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>List of Values</Text>
            </Col>
            {/*<Col xs={12} sm={12} xl={6}>
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>*/}
          </Row>

          {listValues.slice((page - 1) * 10, page * 10)?.map((listValue) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditLov(listValue);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {listValue?.lovname}
                  </Text>
                </Col>

                {/*<Col>
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    <IntlMessages id="more_actions" />
                  </Text>
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                  </Col>*/}
              </Row>
            );
          })}

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
