import {
  Row,
  Typography,
  Upload,
  Button,
  Col,
  Card,
  message,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";

import {
  downloadFile,
  getFiles,
  removeFile,
  uploadFile,
  uploadFileTemp,
} from "../network_requests";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  console.log(linkSource);
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UploadDocs(props) {
  const { templateKey } = props;

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [attribute, setAttribute] = useState("servicetpl");
  const [temp, setTemp] = useState(false);

  const uploadProps = {
    accept: ".pdf,.png,.jpg,.doc,.docx",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          console.log(e.target.result);
          setFile({
            filename: file.name,
            file: e.target.result,
          });
        };

        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (file) {
        const response = temp
          ? await uploadFileTemp(file, templateKey, attribute)
          : await uploadFile(file, templateKey, attribute);
        if (response?.status === 401) {
          localStorage.removeItem("user_id");
          history.go(0);
        }
        if (response?.success) {
          success();
          setUpdate(!update);
        } else {
          error();
        }
      }
    })();
  }, [file?.file]);

  useEffect(() => {
    (async () => {
      const response = await getFiles(templateKey, "servicetpl");
      if (response?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }

      setFiles([...response]);
    })();
  }, [update]);

  return (
    <Row
      style={{
        marginTop: "70px",
        marginLeft: "20px",
        padding: "20px",
      }}
    >
      <Row>
        <Typography>Anhänge</Typography>
      </Row>
      <Row style={{ width: "100%", marginTop: "20px" }}>
        {files?.map((doc) => {
          return (
            <Col xs={12} style={{ padding: "10px" }}>
              <Card style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} justify="end">
                  <CloseOutlined
                    style={{ fontSize: "8px", cursor: "pointer" }}
                    onClick={async () => {
                      const response = await removeFile(
                        doc?.stkey,
                        doc?.cfeld1
                      );
                      if (response?.success) {
                        success();
                        setUpdate(!update);
                      } else {
                        error();
                      }
                    }}
                  />
                </Row>
                <Typography.Text
                  style={{
                    cursor: "pointer",
                    color: "#038fde",
                    textTransform: "lowercase",
                  }}
                  onClick={async () => {
                    const response = await downloadFile(
                      doc?.gisid,
                      doc?.cfeld1
                    );
                    if (response?.file) {
                      downloadToLocal(response?.file, response?.filename);
                      //  downloadPDF(response?.file, response?.filename);
                    }
                  }}
                >
                  {doc?.cfeld1} {doc?.cfeld5 ? `(${doc?.cfeld5})` : ""}
                </Typography.Text>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row style={{ width: "100%" }}>
        <Checkbox
          onChange={(e) => {
            setTemp(e.target.checked);
          }}
        >
          Temp
        </Checkbox>
        <Upload {...uploadProps}>
          <Button
            onClick={() => {
              setAttribute("servicetpl");
            }}
            icon={<UploadOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </Row>
    </Row>
  );
}
