import { Input, Form, Typography, Button, Row, Col, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { checkTagID, getLatestPhoto } from "./network_requests";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Documents from "./documents";
import noImage from "../../../assets/Icons_waste/noimage.png";

export default function EditCheckPoint(props) {
  const { checkPoint, setCheckPoint, setNfcscan } = props;

  const [photoActive, setPhotoActive] = useState(false);
  const [latestPhoto, setLatestPhoto] = useState(null);

  const ref = useRef();
  const history = useHistory();

  // const [imageUrl, setImageUrl] = useState(
  //   latestPhoto?.file.replace("application/pdf", "image/jpeg")
  // );

  const handleImageError = () => {
    // Replace with the path to your fallback image
    //setImageUrl(nfcicon);
  };

  useEffect(() => {
    const fetchPhoto = async () => {
      if (!photoActive) {
        const photo = await getLatestPhoto(checkPoint?.objstkey);
        setLatestPhoto(photo);
      }
    };

    if (checkPoint?.objstkey) {
      fetchPhoto();
    }
  }, [checkPoint?.roundStKey, photoActive]);

  return (
    <>
      {photoActive ? (
        <Documents
          templateKey={checkPoint?.objstkey}
          setActive={setPhotoActive}
        />
      ) : (
        <div
          style={{
            backgroundColor: "#f3f7ff",
            height: "85vh",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#038fde",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setNfcscan(null);
                  setCheckPoint(null);
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                {checkPoint?.objcfeld1}
              </Typography>
            </div>
          </>
          <Form
            name="edit CheckPoint"
            ref={ref}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              padding: "10px 30px",
            }}
            initialValues={{
              bemerkung: checkPoint?.erledigtBemerkung,
            }}
            onFinish={async (values) => {
              if (checkPoint?.nfcTagObject || values?.bemerkung) {
                props.setLoad(true);
                const insertData = checkPoint?.nfcTagObject
                  ? { ...checkPoint }
                  : {
                      roundStKey: checkPoint?.roundStKey,
                      objstkey: checkPoint?.objstkey,
                      group: checkPoint?.group,
                      objstclass: checkPoint?.objstclass,
                    };
                const response = await checkTagID({
                  ...insertData,
                  ...values,
                });

                if (response.status === 401) {
                  message.error("Please login again!");
                  localStorage.removeItem("user_id");
                  history.go(0);
                }
                if (response.status === 200) {
                  message.success("Erfolgreich gespeichert!");
                }
                setNfcscan(null);
                setCheckPoint(null);
                props.setUpdate(!props.update);
              } else {
                message?.error(
                  "NFC Tag wurde nicht gescanned, bitte einen Kommentar eingeben!"
                );
              }
            }}
            //   onFinishFailed={onFinishFailed}
            //   autoComplete="off"
          >
            <Form.Item style={{ marginBottom: "20px" }}>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                Überprüfe {checkPoint?.objcfeld1}!
              </Typography>
            </Form.Item>
            <Form.Item
              label={"Bemerkung"}
              name="bemerkung"
              style={{ marginBottom: 0 }}
            >
              <TextArea rows={3} />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "10px", marginTop: "10px" }}
              label={"Foto"}
            >
              <Row
                style={{
                  width: "100%",
                  height: "180px",
                  backgroundColor: "#fff",
                  border: "1px solid #f5f5f5",
                  margin: "0px",
                  padding: "15px",
                }}
              >
                <Row style={{ width: "100%", margin: 0, padding: "5px" }}>
                  <Row
                    style={{
                      backgroundImage: latestPhoto?.filename
                        ? `url(${latestPhoto.file.replace(
                            "application/pdf",
                            "image/jpeg"
                          )})`
                        : `url(${noImage})`,

                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "100%",
                      margin: 0,
                    }}
                  ></Row>
                </Row>
              </Row>
            </Form.Item>

            <Row
              style={{
                marginTop: "16px",

                margin: 0,
                padding: 0,
              }}
            >
              <Col
                xs={10}
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                      display: checkPoint?.nfcTagObject ? "none" : "",
                    }}
                    onClick={() => {
                      setPhotoActive(!photoActive);
                    }}
                  >
                    Fotos
                  </Button>
                </Form.Item>
              </Col>
              <Col
                xs={4}
                style={{
                  padding: 0,
                  margin: 0,
                }}
              />
              <Col
                xs={10}
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                    }}
                  >
                    speichern
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}{" "}
    </>
  );
}
