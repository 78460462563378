import { url } from "../../../constants";
import axios from "axios";
import moment from "moment";

export const getUrgentChecks = async (order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getVehicleCockpitContent`,
      {
        orderby: `D.nfeld4 ${order}, D.nfeld5 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        type: type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForUrgentChecks = async (order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForVehicleCockpitContent`,
      {
        orderby: `D.nfeld4 ${order}, D.nfeld5 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        type: "none",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getBuildings = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "4",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUrgentChecksforBuildings = async (stkey, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitVehiclesForBuilding`,
      {
        orderby: `D.nfeld4 ${order}, D.nfeld5 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        buildingStKey: stkey,
        type: type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForUrgentChecksforBuildings = async (stkey,name, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForPruefCockpitVehiclesForBuilding`,
      {
        orderby: `D.nfeld4 ${order}, D.nfeld5 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        buildingStKey: stkey,
        buildingName:name,
        type: "none",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUrgentChecksforRecommendations = async (stkey, order) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitAppliancesExternChecksForBuildingWithOrder`,
      {
        orderby: `D.nfeld4, D.nfeld5`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        buildingStKey: stkey,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompanies = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getDistinctDetailValues`,
      {
        group: "01",
        stclass: 15,
        detclass: 4,
        key: "cfeld2",
        type: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUrgentChecksforCompanies = async (company, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitVehiclenForCompany`,
      {
        orderby: `D.nfeld4 ${order}, D.nfeld5 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        company: company,
        type: type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForUrgentChecksforCompanies = async (company, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForPruefCockpitVehiclenForCompany`,
      {
        orderby: `D.nfeld4 ${order}, D.nfeld5 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        company: company,
        type: "none",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getLastInspectionForAppliance = async (stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getLastInspectionForAppliance`,
      {
        group: "class_g01",
        stClass: "15",
        stKey: stkey,
        detail: "cldetail_g01",
        detClass: "4",
        type: "none",
        limitFrom: "0",
        limitTo: "1",
        orderBy: "nfeld1",
        cfields: [],
        nfields: [{ fieldNo: "nfeld3", fromVal: 0, opVal: ">" }],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDetailForAppliance = async (stKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonByStKey`,
      {
        group: "class_g01",
        stClass: "15",
        stKey: stKey,
        detail: "",
        detClass: "",
        type: "none",
        limitFrom: "0",
        limitTo: "1",
        orderBy: "nfeld1",
        cfields: [{ fieldNo: "stKey", val: stKey, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addInspection = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}insertNewExtCheckForAppliance`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editInspection = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        active: 1,
        stclass: 15,
        detclass: 4,
        gisid: null,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editAppliance = async (values, active) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        group: "1",
        active: active,
        stclass: 15,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteImage = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}delImage`,
      {
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllTypes = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.get(`${url}getVehicleCockpitTypeList`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
