import {
  Row,
  Typography,
  Form,
  Button,
  Input,
  Col,
  Checkbox,
  List,
  message,
  Avatar,
  Pagination,
  Popconfirm,
  Progress,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { addTask, getChatMessages, removeMessage } from "../network_requests";
import { useHistory } from "react-router-dom";
import { doubleDT_to_string, string_to_doubleDT } from "../../../../constants";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import { url } from "../../../../constants";
import moment from "moment";
import "./logbook.css";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

var stompClient = null;

export default function LogBook() {
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [update, setUpdate] = useState(false);
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [privateChats, setPrivateChats] = useState(new Map());
  const [publicChats, setPublicChats] = useState([]);
  const [tab, setTab] = useState("CHATROOM");
  const [userData, setUserData] = useState({
    username: "",
    receivername: "",
    connected: false,
    message: "",
  });

  useEffect(async () => {
    if (connected) {
      setLoad(true);
      const messages_ = await getChatMessages((page - 1) * pageSize, pageSize);
      if (messages_?.status === 401) {
        localStorage.removeItem("company_id");
        history.go(0);
      }
      setMessages(messages_?.list);
      setCount(messages_?.count);
      setLoad(false);
    } else {
      connect();
    }
  }, [connected, page, pageSize, update]);

  const connect = () => {
    const license = window.localStorage.getItem("licence");
    const token = window.localStorage.getItem("user_id");
    let Sock = new SockJS(`${url}ws?token=${token}`);

    stompClient = over(Sock);
    stompClient.connect({}, onConnected, onError);
  };

  const taskadded = () => {
    message.success("Task has been added!");
  };

  const onConnected = () => {
    setUserData({ ...userData, connected: true });
    setConnected(true);
    stompClient.subscribe("/chatroom/public", onPublicMessageReceived);

    userJoin();
  };

  const userJoin = () => {
    var chatMessage = {
      senderName: window.localStorage.getItem("uname"),
      status: "JOIN",
    };
    stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
  };

  const onPublicMessageReceived = (payload) => {
    var payloadData = JSON.parse(payload.body);

    switch (payloadData.status) {
      case "JOIN":
        if (!privateChats.get(payloadData.senderName)) {
          privateChats.set(payloadData.senderName, []);
          setPrivateChats(new Map(privateChats));
        }
        break;
      case "MESSAGE":
        setUpdate(!update);
        // setMessages((messages) => [
        //   {
        //     cfeld1: payloadData?.senderName,
        //     cfeld2: payloadData?.receiverName + " " + payloadData?.message,
        //     nfeld1: payloadData?.dateTime,
        //   },
        //   ...messages,
        // ]);
        break;
      default:
        break;
    }
  };

  const onError = (err) => {
    console.log(err);
  };

  const handleMessage = (event) => {
    const { value } = event.target;
    setUserData({ ...userData, message: value });
  };
  const sendValue = (message) => {
    if (stompClient) {
      stompClient.send("/app/message", {}, JSON.stringify(message));
      setUserData({ ...userData, message: "" });
      form.resetFields();
    }
  };

  const handleUsername = (event) => {
    const { value } = event.target;
    setUserData({ ...userData, username: value });
  };

  return (
    <Row style={{ padding: "0px" }}>
      <Row style={{ width: "100%", padding: "0px 15px", alignItems: "center" }}>
        <Col xs={21} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "12px",
              width: "12px",
              borderRadius: "50%",
              backgroundColor: connected ? "green" : "red",
              marginRight: "10px",
            }}
          />
          <Typography.Text
            style={{ fontWeight: 600, textTransform: "uppercase" }}
          >
            Service Nachrichten
          </Typography.Text>
        </Col>
        <Col xs={3}>
          <ReloadOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUpdate(!update);
            }}
          />
        </Col>
      </Row>
      <Row style={{ width: "100%", padding: "0px" }}>
        <Form
          name="basic"
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: 600,
            padding: 0,
            margin: 0,
            paddingLeft: "15px",
          }}
          initialValues={{}}
          onFinish={(values) => {
            const time = moment()?.format("DD.MM.YYYY HH:mm");

            sendValue({
              senderName: window.localStorage.getItem("uname"),
              receiverName: values?.contactPerson,
              message: values?.Question,
              stKey: "47d65e60013422",
              dateTime: string_to_doubleDT(time),
              status: "MESSAGE",
              forward1: values?.forward1 ? 1 : 0,
              forward2: values?.forward2 ? 1 : 0,
              forward3: values?.forward3 ? 1 : 0,
              forward4: values?.forward4 ? 1 : 0,
            });
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Name Kontaktperson & Kontaktdaten"
            name="contactPerson"
            style={{ padding: "0px 0 0 15px", margin: 0 }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Information / Frage"
            name="Question"
            style={{ padding: "0px 0 0 15px", margin: 0 }}
          >
            <Input />
          </Form.Item>
          <Row
            style={{
              width: "100%",
              margin: "10px 0",
              padding: 0,
            }}
          >
            <Col xs={8} style={{ margin: 0, padding: 0 }}>
              <Typography.Text style={{ fontSize: "12px" }}>
                Als Mail an:
              </Typography.Text>
            </Col>

            <Col
              xs={8}
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <Form.Item
                labelCol={0}
                wrapperCol={24}
                name="forward1"
                valuePropName="checked"
                style={{ padding: 0, margin: 0 }}
              >
                <Checkbox />
              </Form.Item>
              <Typography.Text
                style={{
                  fontSize: "12px",
                  marginLeft: "5px",
                  margin: 0,
                  padding: 0,
                }}
              >
                Wirtschaftshof
              </Typography.Text>
            </Col>
            <Col
              xs={8}
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <Form.Item
                labelCol={0}
                wrapperCol={24}
                name="forward2"
                valuePropName="checked"
                style={{ padding: 0, margin: 0 }}
              >
                <Checkbox />
              </Form.Item>
              <Typography.Text style={{ fontSize: "12px", marginLeft: "5px" }}>
                Freizeitzentrum
              </Typography.Text>
            </Col>
          </Row>

          <Row style={{ width: "100%", margin: "10px 0" }}>
            <Col xs={8} style={{ margin: 0, padding: 0 }}></Col>
            <Col
              xs={8}
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <Form.Item
                labelCol={0}
                wrapperCol={24}
                name="forward3"
                valuePropName="checked"
                style={{ padding: 0, margin: 0 }}
              >
                <Checkbox />
              </Form.Item>
              <Typography.Text style={{ fontSize: "12px", marginLeft: "5px" }}>
                Bauamt
              </Typography.Text>
            </Col>
            <Col
              xs={8}
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <Form.Item
                labelCol={0}
                wrapperCol={24}
                name="forward4"
                valuePropName="checked"
                style={{ padding: 0, margin: 0 }}
              >
                <Checkbox />
              </Form.Item>
              <Typography.Text style={{ fontSize: "12px", marginLeft: "5px" }}>
                Kultur
              </Typography.Text>
            </Col>
          </Row>

          <Form.Item style={{ padding: "0px 0 0 15px", margin: "10px 0 0 0" }}>
            <Button type="primary" htmlType="submit">
              senden
            </Button>
          </Form.Item>
        </Form>
      </Row>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Row style={{ width: "100%", padding: "0px 15px" }}>
          <List>
            {messages?.slice(0, pageSize)?.map((message) => {
              return (
                <List.Item>
                  <Row style={{ width: "100%" }}>
                    <Col xs={3}>
                      <Avatar />
                    </Col>
                    <Col xs={15} style={{ paddingLeft: "25px" }}>
                      <Typography style={{ fontWeight: "bold" }}>
                        {doubleDT_to_string(message?.nfeld1)}, {message?.cfeld1}
                      </Typography>
                      <Typography>
                        {message?.cfeld3} {message?.cfeld2}
                      </Typography>
                    </Col>
                    <Col xs={1}>
                      <CheckCircleOutlined
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          const response_ = await addTask({
                            detKey: message?.detkey,
                            stKey: message?.stkey,
                          });
                          if (response_?.success === "success") {
                            taskadded();
                          }
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      <Popconfirm
                        title="Soll diese Nachricht wirklich gelöscht werden?"
                        onConfirm={async () => {
                          const response_ = await removeMessage({
                            detKey: message?.detkey,
                            stKey: message?.stkey,
                          });
                          if (response_?.success === "success") {
                            setUpdate(!update);
                          }
                        }}
                        okText="Ja"
                        cancelText="Nein"
                      >
                        <DeleteOutlined style={{ cursor: "pointer" }} />
                      </Popconfirm>
                    </Col>
                  </Row>
                </List.Item>
              );
            })}
          </List>
        </Row>
      )}
      <Row
        style={{
          backgroundColor: "#fff",
          padding: "10px 30px 0 30px",
          margin: "0",
        }}
        justify="end"
      >
        <Pagination
          defaultCurrent={1}
          total={count}
          current={page}
          pageSize={pageSize}
          pageSizeOptions={[5, 10, 15]}
          style={{
            margin: 0,
          }}
          onChange={(newpage, pageSize_) => {
            setPage(newpage);
            setPageSize(pageSize_);
          }}
        />
      </Row>
    </Row>
  );
}
