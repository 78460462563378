import React from "react";
import { List, Row, Typography } from "antd";
import { Link } from "react-router-dom";

function ServicePanel(props) {
  const { service } = props;

  return (
    <Row>
      <List style={{ width: "100%", padding: 0, margin: 0 }}>
        {service?.subareas?.map((area) => {
          return (
            <List.Item
              style={{
                width: "100%",
                padding: "5px 10px",
              }}
            >
              <Link
                to={`/main/service_areas/main/${area.stkey}`}
                style={{ color: "black" }}
              >
                {area?.subareatitle}
              </Link>
            </List.Item>
          );
        })}
        {service?.quickactions ? (
          <List.Item
            style={{
              width: "100%",
              padding: "5px 10px",
              display: "flex",
            }}
          >
            <Typography>
              Quick Actions:
              {service?.quickactions?.map((action) => {
                return (
                  <Link
                    to={`/main/service_areas/${action?.qaLink}`}
                    style={{ padding: "0px 5px", color: "black" }}
                  >
                    {action.qaTitle}
                  </Link>
                );
              })}
            </Typography>
          </List.Item>
        ) : null}
      </List>
    </Row>
  );
}

export default ServicePanel;
