import { Col, Row, Pagination, Typography, Spin, Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import { getActions, getFields } from "./network_requests";
import { DownOutlined } from "@ant-design/icons";
import { double_to_string } from "../../../../constants";
import AddAction from "./AddAction";
import EditAction from "./EditAction";
import MarkActionAsDone from "./MarkActionAsDone";
import IntlMessages from "util/IntlMessages";

export default function Actions(props) {
  const { stkey, action, setAction } = props;
  const { Text } = Typography;
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(null);
  const [markDone, setMarkDone] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [actions, setActions] = useState([]);
  const [fields, setFields] = useState({});
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setAddOpen(action === "addAction" ? true : false);
  }, [action]);

  useEffect(async () => {
    setLoad(true);
    const actions_ = await getActions(0, 10, stkey);
    const vitality = await getFields("G03C02D02F04");
    const actionF = await getFields("G03C02D02F03");
    const dueTime = await getFields("G03C02D02F10");
    const worthiness = await getFields("G03C02D02F05");
    const examination = await getFields("G03C02D02F07");
    const safety = await getFields("G03C02D02F02");

    setFields({
      vitality: vitality,
      actionF: actionF,
      dueTime: dueTime,
      worthiness: worthiness,
      examination: examination,
      safety: safety,
    });

    setActions(actions_?.list ? actions_?.list : []);
    setCount(actions_?.count ? actions_?.count : 0);
    setLoad(false);
  }, [update]);

  return (
    <>
      <AddAction
        stkey={stkey}
        open={addOpen}
        setOpen={setAddOpen}
        fields={fields}
        setAction={setAction}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      <EditAction
        stkey={stkey}
        open={editOpen}
        setOpen={setEditOpen}
        fields={fields}
        setAction={setAction}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      <MarkActionAsDone
        state={markDone}
        setState={setMarkDone}
        stkey={stkey}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col xs={12} sm={12} xl={18} style={{ padding: 0 }}>
              <Text>{<IntlMessages id="outdoor.previousactions" />}</Text>
            </Col>
            <Col
              xs={12}
              sm={12}
              xl={6}
              style={{ padding: 0, justifyContent: "flex-end" }}
            >
              <Text>{<IntlMessages id="more_actions" />}</Text>
            </Col>
          </Row>

          {actions?.map((action) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {<IntlMessages id="outdoor.action" />} : {action?.cfeld3}
                    </Text>
                    <br />
                    <br />
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      <IntlMessages id="outdoor.vitality" /> : {action?.cfeld4}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      <IntlMessages id="outdoor.preservation" /> : {action?.cfeld5}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      <IntlMessages id="outdoor.roadsafety" /> : {action?.cfeld2}
                    </Text>
                    <br />
                  </div>
                </Col>

                <Col>
                  <Text
                    style={{
                      color: "#038fde",
                      paddingRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditOpen(action);
                    }}
                  >
                    <IntlMessages id="edit" /> |
                  </Text>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="0"
                          onClick={() => {
                            setMarkDone(action);
                          }}
                        >
                          <IntlMessages id="erledigt" />
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                        cursor: "pointer",
                      }}
                    >
                      <IntlMessages id="more_actions" />
                    </Text>
                  </Dropdown>
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 15]}
              style={{
                margin: 0,
              }}
              onChange={(newpage, pageSize_) => {
                setPage(newpage);
                setPageSize(pageSize_);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
