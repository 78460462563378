import antdDe from "antd/lib/locale-provider/de_DE";
import deMessages from "../locales/de_De.json";

const DeLang = {
  messages: {
    ...deMessages,
  },
  antd: antdDe,
  locale: "de-De",
};
export default DeLang;
