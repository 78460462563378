import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useRef, useState } from "react";
import { addEstates } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import {
  string_to_double,
  double_to_string,
} from "../../../../../../constants";

export default function AddEstates(props) {
  const { open, setOpen, setPage } = props;
  const history = useHistory();
  const ref = useRef();
  const [nfeld4, setnfeld4] = useState(moment(moment(), "DD.MM.YYYY"));

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="estate.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="add estates"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addEstates({
            ...values,
            nfeld4: string_to_double(nfeld4),
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="name_identifier" />}
          name="cfeld1"
          rules={[
            {
              required: true,
              message: "The field should not be empty!",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={15} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="street" />}
              name="cfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="zip_code" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="catastral_commune" />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="property_number" />}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="property_size" />}
              name="nfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="green_area" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="next_planned_check" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld4}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld4(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="traffic_areas" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld9"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
