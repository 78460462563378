import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Dashboard from "./dashboard";
import Layouts from "./Layouts";
import asyncComponent from "../../util/asyncComponent";
import UserOverview from "./overview-indoor/userManagement";
import Overview from "./overview-indoor/overview";
import List from "./overview-indoor/listValues";
import Ashtrays from "./overview-outdoor/ashtrays";
import DogBags from "./overview-outdoor/dog-bags";
import Stations from "./overview-outdoor/stations";
import TrashTrays from "./overview-outdoor/trashtrays";
import Greenfields from "./overview-outdoor/greenfields";
import TrafficSigns from "./overview-outdoor/trafficsigns";
import Cockpit from "./cockpit";
import PruefCockpit from "./pruefCockpit";
import Trees from "./overview-outdoor/trees";
import Search from "./search";
import SearchResult from "./searchResult";
import TasksMobile from "./tasksMobile";
import DeliveryMobile from "./deliveryMobile";
import OrdersAppMobile from "./ordersAppMobile";
import SearchStatistic from "./searchStatistic";
import Hydrants from "./overview-outdoor/hydrant";
import AutomaticWaterings from "./overview-outdoor/automatic_watering";
import Fountains from "./overview-outdoor/fountain";
import Tasks from "./tasks";
import Tracking from "./tracking";
import OtherWaterConnectors from "./overview-outdoor/other_water_connectors";
import PumpingStations from "./overview-outdoor/pumping_stations";
import EventManagement from "./eventManagement";
import VehicleOverview from "./vehicle-overview";
import VehicleExitOverview from "./vehicle-exit-overview";
import VehicleCockpit from "./vehicleCockpit";
import ServiceAreas from "./service_areas";
import Properties from "./overview-outdoor/property";
import PropertiesArchive from "./overview-outdoor/propertyarchive";
import EmployeeApp from "./employeeApp";
import EmployeeMobileApp from "./EmployeeMobileApp";
import MyAccount from "./account";
import WorkOrders from "./ordersApp/pages/workOrders";
import BaseDataWorkOrders from "./ordersApp/pages/baseData";
import BaseData from "./ordersApp/pages/baseData";
import IdeasApp from "./ideasApp";
import BaseDataOrder from "./ordersApp/pages/workOrders/components/baseDataOrder";
import SubAreas from "./overview-outdoor/subAreas";
import TrackingMobile from "./trackingMobile";
import Issues from "./overview-outdoor/issues";
import CategoryMobile from "./categoryMobile";
import CheckAppMobile from "./checkAppMobile";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/user-management`} component={UserOverview} />
    <Route path={`${match.url}/overview`} component={Overview} />
    <Route path={`${match.url}/list-overviews`} component={List} />
    <Route path={`${match.url}/ashtrays`} component={Ashtrays} />
    <Route path={`${match.url}/dog-bag`} component={DogBags} />
    <Route path={`${match.url}/stations`} component={Stations} />
    <Route path={`${match.url}/trashtrays`} component={TrashTrays} />
    <Route path={`${match.url}/greenfields`} component={Greenfields} />
    <Route path={`${match.url}/trafficsigns`} component={TrafficSigns} />
    <Route path={`${match.url}/trees`} component={Trees} />
    <Route path={`${match.url}/tasks`} component={Tasks} />
    <Route path={`${match.url}/tracking`} component={Tracking} />
    <Route path={`${match.url}/tasksMobile`} component={TasksMobile} />
    <Route path={`${match.url}/categoryMobile`} component={CategoryMobile} />
    <Route path={`${match.url}/checkAppMobile`} component={CheckAppMobile} />
    <Route path={`${match.url}/deliveryMobile`} component={DeliveryMobile} />
    <Route path={`${match.url}/ordersAppMobile`} component={OrdersAppMobile} />
    <Route path={`${match.url}/ideas/:page`} component={IdeasApp} />
    <Route
      path={`${match.url}/trackingMobile/:vehicle`}
      component={TrackingMobile}
    />
    <Route path={`${match.url}/hydrant`} component={Hydrants} />
    <Route path={`${match.url}/issues`} component={Issues} />
    <Route
      path={`${match.url}/automatic_watering`}
      component={AutomaticWaterings}
    />
    <Route path={`${match.url}/fountain`} component={Fountains} />
    <Route
      path={`${match.url}/other_water_connectors`}
      component={OtherWaterConnectors}
    />
    <Route path={`${match.url}/pumping_stations`} component={PumpingStations} />
    <Route path={`${match.url}/cockpit`} component={Cockpit} />
    <Route path={`${match.url}/pruefCockpit`} component={PruefCockpit} />
    <Route path={`${match.url}/vehicleCockpit`} component={VehicleCockpit} />
    <Route
      path={`${match.url}/service_areas/:page/:subkey`}
      component={ServiceAreas}
    />
    <Route path={`${match.url}/service_areas`} component={ServiceAreas} />
    <Route
      path={`${match.url}/eventManagement/:page`}
      component={EventManagement}
    />
    <Route path={`${match.url}/workOrders`} component={WorkOrders} />

    <Route
      path={`${match.url}/workOrders-baseData`}
      component={BaseDataWorkOrders}
    />
    <Route path={`${match.url}/baseData-Order`} component={BaseDataOrder} />
    <Route path={`${match.url}/search`} component={Search} />
    <Route
      path={`${match.url}/vehicles_overview/:page`}
      component={VehicleOverview}
    />
    <Route
      path={`${match.url}/vehicles_exit_overview/:page`}
      component={VehicleExitOverview}
    />
    <Route path={`${match.url}/employee_app/:page`} component={EmployeeApp} />
    <Route
      path={`${match.url}/employee_MobileApp`}
      component={EmployeeMobileApp}
    />
    <Route path={`${match.url}/searchResult`} component={SearchResult} />
    <Route path={`${match.url}/properties`} component={Properties} />
    <Route path={`${match.url}/subareas`} component={SubAreas} />
    <Route
      path={`${match.url}/propertiesarchive`}
      component={PropertiesArchive}
    />
    <Route path={`${match.url}/account`} component={MyAccount} />
    {/* <Route path={`${match.url}/searchStatistic`} component={SearchStatistic}/> */}
    {/* <Route path={`${match.url}/dashboard`} component={Dashboard}/> */}
    {/*  <Route path={`${match.url}/widgets`} component={Widgets}/>
    <Route path={`${match.url}/metrics`} component={Metrics}/>
    <Route path={`${match.url}/layouts`} component={Layouts}/>
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/> */}
  </Switch>
);

export default Main;
