import {
  Col,
  Row,
  Pagination,
  Typography,
  Spin,
  Modal,
  Dropdown,
  Menu,
  Button,
} from "antd";
import { useEffect, useState } from "react";
import {
  addCoordinate,
  deleteCoordinate,
  getCoordinates,
  getFields,
} from "./network_requests";
import { useHistory } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { double_to_string } from "../../../../constants";
import AddCoordinate from "./AddCoordinate";
import EditCoordinate from "./EditCoordinate";
import IntlMessages from "util/IntlMessages";

export default function Coordinate(props) {
  const { open, setOpen } = props;
  const { Text } = Typography;
  const [addOpen, setAddOpen] = useState(null);
  const [editOpen, setEditOpen] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [coordinate, setcoordinate] = useState([]);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    if (open?.stkey) {
      setLoad(true);
      const coordinate_ = await getCoordinates(0, 10, open?.stkey);
      if (coordinate_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setcoordinate(coordinate_?.list ? coordinate_?.list : []);
      setCount(coordinate_?.count ? coordinate_?.count : 0);
      setLoad(false);
    }
  }, [update, open?.stkey]);

  return (
    <>
      {addOpen ? (
        <AddCoordinate
          open={addOpen}
          setOpen={setAddOpen}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
        />
      ) : null}
      {editOpen ? (
        <EditCoordinate
          open={editOpen}
          setOpen={setEditOpen}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
        />
      ) : null}

      <Modal
        title="coordinate"
        width="660"
        visible={open}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onOk={() => {
          setOpen(null);
        }}
        onCancel={() => {
          setOpen(null);
        }}
        style={{
          minWidth: "720px",
        }}
      >
        {load ? (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Spin />
          </div>
        ) : null}

        {!load ? (
          <>
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "20px 0 0 0",
              }}
            >
              <Col xs={8} style={{ padding: 0 }}>
                <Text>Name</Text>
              </Col>
              <Col xs={8} style={{ padding: 0 }}>
                <Text>Lat/Lon</Text>
              </Col>
              <Col xs={8} style={{ padding: 0 }}></Col>
            </Row>

            {coordinate?.map((coordinate) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col xs={8} style={{ padding: 0 }}>
                    <Text
                      style={{
                        color: "#038fde",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditOpen(coordinate);
                      }}
                    >
                      {coordinate?.cfeld1 && coordinate?.cfeld1 !== " "
                        ? coordinate?.cfeld1
                        : "Un-named"}
                    </Text>
                  </Col>
                  <Col xs={8} style={{ padding: 0 }}>
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {coordinate?.nfeld1};{coordinate?.nfeld2}
                    </Text>
                  </Col>
                  <Col
                    xs={8}
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="1"
                            onClick={async () => {
                              setEditOpen({ ...coordinate });
                            }}
                          >
                            Edit
                          </Menu.Item>
                          <Menu.Item
                            key="2"
                            onClick={async () => {
                              setLoad(true);
                              const response = await deleteCoordinate({
                                ...coordinate,
                              });
                              if (response?.status === 401) {
                                localStorage.removeItem("user_id");
                                history.go(0);
                              }
                              setLoad(false);
                              setUpdate(!props.update);
                            }}
                          >
                            Remove
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Text
                        style={{
                          color: "#038fde",
                        }}
                      >
                        More
                        <DownOutlined
                          style={{
                            color: "#038fde",
                            paddingLeft: "15px",
                          }}
                        />
                      </Text>
                    </Dropdown>
                  </Col>
                </Row>
              );
            })}

            <Row
              style={{
                backgroundColor: "#fff",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Button
                type="primary"
                onClick={() => {
                  setAddOpen(open?.stkey);
                }}
              >
                Add Value
              </Button>
            </Row>

            <Row
              style={{
                backgroundColor: "#fff",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                pageSize={pageSize}
                pageSizeOptions={[5, 10, 15]}
                style={{
                  margin: 0,
                }}
                onChange={(newpage, pageSize_) => {
                  setPage(newpage);
                  setPageSize(pageSize_);
                }}
              />
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  );
}
