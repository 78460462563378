import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  message,
  Typography,
  Button,
  InputNumber,
} from "antd";
import { useRef, useState } from "react";
import { addRepair } from "../network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { string_to_double } from "../../../../constants";

const { Text } = Typography;

const success = () => {
  message.success("Repair created successfully");
};

const error = () => {
  message.error("Error! Please try again");
};

export default function AddRepair(props) {
  const { open, setOpen, roomAppliance, stclass } = props;

  const history = useHistory();
  const ref = useRef();
  const [nfeld1, setnfeld1] = useState(null);
  const [nextRepairDate, setnextRepairDate] = useState(null);
  const [condition, setCondition] = useState(1.0);
  const [repairType, setRepairType] = useState(null);

  return (
    <Modal
      title={
        <>
          <IntlMessages id="cockpit.add_Repair" />
        </>
      }
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onCancel={() => {
        setOpen(null);
      }}
      footer={
        <Row>
          <Col xs={24} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              key="back"
              onClick={() => {
                setOpen(null);
              }}
            >
              <IntlMessages id="cancel" />
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setOpen(null);
                ref?.current?.submit();
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        name="add Repair"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);

          const response = await addRepair({
            ...values,
            nfeld1: string_to_double(nfeld1),
            stkey: open?.stkey,
            stclass: stclass,
          });

          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          if (response.success === "saved") {
            success();
          } else {
            error();
          }

          setnfeld1(null);

          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.repair.repairDate" />}
              name="nfeld1"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.repair.repairedBy" />}
              name="cfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="cockpit.repair.repairContent" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="cockpit.repair.workinghrskm" />}
          name="cfeld3"
          style={{ marginBottom: 0 }}
        >
          <Input style={{ width: "50%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
