import { Row } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BaseData from "./components/edit/baseData";
import Checklist from "./components/edit/Checklist";
import Comments from "./components/edit/Comments";
import Upload from "./components/edit/Upload";
import Notes from "./components/edit/Notes";
import Tabs from "./components/tabs";

export default function EditEvent(props) {
  const {
    events,
    setEvents,
    editEvent,
    setEditEvent,
    updateEvents,
    setUpdateEvents,
  } = props;
  const [tabState, setTabState] = useState(0);

  let { page } = useParams();

  return (
    <Row
      style={{
        backgroundColor: "#FFF",
        borderBottom: "1px solid #eaeaea",
        fontWeight: 500,
        padding: "10px 30px",
        margin: "20px",
        borderRadius: "8px",
        border: "2px solid #ececec",
        minHeight: "70vh",
        flexDirection: "column",
        textTransform: "capitalize",
      }}
    >
      {editEvent?.cfeld1} (
      {page && page === "outdoor-event" ? "Outdoor" : "Indoor"})
      <Tabs tabState={tabState} setTabState={setTabState} />
      {tabState === 0 ? (
        <BaseData
          events={events}
          setEvents={setEvents}
          edit={editEvent}
          setEdit={setEditEvent}
          updateEvents={updateEvents}
          setUpdateEvents={setUpdateEvents}
          cfeld30={page && page === "outdoor-event" ? "outdoor" : "indoor"}
        />
      ) : tabState === 1 ? (
        <Checklist event={editEvent} />
      ) : tabState === 2 ? (
        <Upload event={editEvent} />
      ) : tabState === 3 ? (
        <Comments event={editEvent} />
      ) : tabState === 4 ? (
        <Notes event={editEvent} />
      ) : null}
    </Row>
  );
}
