import {
  Row,
  Typography,
  Upload,
  Button,
  Col,
  Card,
  message,
  Pagination,
  List,
  Empty,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import {
  CloseOutlined,
  UploadOutlined,
  FileOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  downloadFile,
  getFiles,
  removeFile,
  uploadFile,
} from "./network_requests";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

function downloadToLocal(pdf, filename) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UploadDocs(props) {
  const { templateKey, attribute } = props;

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState(1);

  const uploadProps = {
    accept: ".pdf,.png,.jpg,.doc,.docx",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          console.log(e.target.result);
          setFile({
            filename: file.name,
            file: e.target.result,
          });
        };

        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (file) {
        const response = await uploadFile(file, templateKey, attribute);
        if (response?.status === 401) {
          localStorage.removeItem("user_id");
          history.go(0);
        }
        if (response?.success) {
          success();
          setUpdate(!update);
        } else {
          error();
        }
      }
    })();
  }, [file?.file]);

  useEffect(() => {
    (async () => {
      const response = await getFiles(templateKey, attribute);
      if (response?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }

      setFiles([...response]);
    })();
  }, [update, attribute]);

  return (
    <Row
      style={{
        marginLeft: "20px",
        padding: "20px",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Typography style={{ fontSize: "22px", fontWeight: 500 }}>
          Anhänge
        </Typography>
      </Row>
      <Row style={{ width: "100%", marginTop: "20px" }}>
        <List style={{ width: "100%", padding: 0 }}>
          {files?.length <= 0 ? <Empty /> : null}
          {files?.slice((page - 1) * 10, page * 10)?.map((doc) => {
            return (
              <List.Item
                style={{
                  width: "100%",
                  border: "1px solid #808080",
                  margin: "5px 0",
                  padding: "15px",
                  borderRadius: "8px",
                }}
              >
                <Row
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: 0,
                    margin: 0,
                    alignItems: "center",
                  }}
                >
                  <Col
                    xs={2}
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <FileOutlined style={{ fontSize: "18px" }} />
                  </Col>
                  <Col
                    xs={20}
                    style={{
                      padding: 0,
                      margin: 0,
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      const response = await downloadFile(
                        doc?.gisid,
                        doc?.cfeld1
                      );
                      if (response?.file) {
                        downloadToLocal(response?.file, response?.filename);
                      }
                    }}
                  >
                    {doc?.cfeld1?.length < 120
                      ? doc?.cfeld1
                      : `${doc?.cfeld1?.substr(0, 120)}...`}
                  </Col>
                  <Col
                    xs={2}
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Popconfirm
                      title="Are you sure you want to delete this file?"
                      onConfirm={async () => {
                        const response = await removeFile(
                          doc?.stkey,
                          doc?.cfeld1
                        );
                        if (response?.success) {
                          success();
                          setUpdate(!update);
                        } else {
                          error();
                        }
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: "18px", color: "#f32013" }}
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              </List.Item>
            );
          })}
        </List>
      </Row>
      {files?.length > 0 ? (
        <Row
          style={{
            width: "100%",
            marginTop: "16px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={page}
            total={files?.length}
            pageSize={10}
            onChange={(page_) => {
              setPage(page_);
            }}
          />
        </Row>
      ) : null}

      <Row style={{ width: "100%", marginTop: "16px" }}>
        <Upload {...uploadProps}>
          <Button type="primary" icon={<UploadOutlined />}>
            Upload File
          </Button>
        </Upload>
      </Row>
    </Row>
  );
}
