import { url } from "../../../../constants";
import axios from "axios";

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAppliances = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g28",
        stClass: 2,
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addAppliance = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntriesRetStKey`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: parent,
          stclass: 4,
          storder: 0,
          treepos: 1,
          disptree: 0,
        },
        obj_Data: {
          active: 1,
          group: "1",
          stclass: 9,
          ...values,
          gisid: "",
          stkey: "",
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const editAppliance = async (values, active) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        group: "1",
        active: active,
        stclass: 2,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteAppliance = async (values, deleteIt) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        group: "28",
        active: 0,
        stclass: 2,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const excelForExitAppliances = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}excelForExitAppliances`,
      {
        group: "1",
        stclass: 9,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};