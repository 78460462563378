import { url } from "../../../constants";
import axios from "axios";

export const makeindex = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}search/indexdata`,
      {
        
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};