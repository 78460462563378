import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  doubleDT_to_string,
  double_to_string,
  string_to_double,
  string_to_doubleDT,
} from "../../../../../../constants";
import { editArtist } from "../networkrequests";
import IntlMessages from "util/IntlMessages";

const success = () => {
  message.success("The artist has been updated.");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function EditArtist(props) {
  const { open, setOpen } = props;

  const [nfeld1, setnfeld1] = useState(
    open?.nfeld1 ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY") : null
  );

  const [nfeld2, setnfeld2] = useState(
    open?.nfeld2
      ? moment(doubleDT_to_string(open?.nfeld2), "DD.MM.YYYY HH:mm")
      : null
  );

  useEffect(() => {
    setnfeld1(
      open?.nfeld1 ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY") : null
    );

    setnfeld2(
      open?.nfeld2
        ? moment(doubleDT_to_string(open?.nfeld2), "DD.MM.YYYY HH:mm")
        : null
    );
  }, [open]);

  const ref = useRef();
  const { Text } = Typography;

  return (
    <Modal
      title={"Künstler hinzufügen"}
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        // setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
      }}
      style={{
        minWidth: "45vw",
      }}
    >
      <Form
        name="add artist"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
        }}
        onFinish={async (values) => {
          const response = await editArtist({
            ...open,
            ...values,
            nfeld1: nfeld1 ? string_to_double(nfeld1) : 0,
            nfeld2: nfeld2 ? string_to_doubleDT(nfeld2) : 0,
          });
          if (response?.success) {
            success();
          } else {
            error();
          }
          props.setUpdate(!props.update);
          setOpen(null);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item
              name="cfeld1"
              label="Name"
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={11}>
            <Form.Item label="Datum des Auftritts">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={2} />

          <Col xs={11}>
            <Form.Item label="Eintreffen">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld2}
                showTime={"HH:mm"}
                format="DD.MM.YYYY HH:mm"
                onChange={(e, d) => {
                  setnfeld2(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={11}>
            <Form.Item name="cfeld8" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={2} />
          <Col xs={11}>
            <Form.Item name="cfeld9" label="Phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld2" label="Agentur">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={11}>
            <Form.Item name="cfeld3" label="Strabe / Hausnummer">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={2} />
          <Col xs={11}>
            <Form.Item name="cfeld4" label="Postleitzahl">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld5" label="Kunstlergeschenk">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld6" label="Kunstlerverpflegung">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld7" label="Sonstiges">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
