import {
    Switch
  } from "antd";
  import { useState } from "react";

export default function CatSwitch(props) { 
    const { categoryName, catDisabled, catChecked, catIndex } = props;

    function handleClick(i) {
        props.onChange(catIndex, i);
    }

return (
    <Switch 
        id={'switch' + catIndex} 
        checkedChildren={categoryName} 
        unCheckedChildren={categoryName} 
        checked={catChecked}
        onChange={handleClick}
        disabled={catDisabled} />
)

}