import { url } from "../../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getStaff = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "2",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCostCenter = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "3",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getVehicle = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "1",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addStaff = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        active: 1,
        stclass: 1,
        stkey: "47D68CEA8CA340",
        detTable__: "cldetail_g33",
        detclass: 2,
        detkey: "",
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editStaff = async (values) => {
  try {
   
    const token = window.localStorage.getItem("user_id");
   const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        active: 1,
        stclass: 1,
        stkey:"47D68CEA8CA340",
        detTable__: "cldetail_g33" ,
        detclass: 2,
        ...restValues,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const deleteAll = async (values) => {
  try {
    
    const token = window.localStorage.getItem("user_id");
   const { detTable__,active, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        active: 0,
        stclass: 1,
        stkey:"47D68CEA8CA340",
        detTable__: "cldetail_g33" ,
        ...restValues,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const addCostCenter = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        active: 1,
        stclass: 1,
        stkey: "47D68CEA8CA340",
        detTable__: "cldetail_g33",
        detclass: 3,
        detkey: "",
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const editCostCenter = async (values) => {
  try {
   
    const token = window.localStorage.getItem("user_id");
   const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        active: 1,
        stclass: 1,
        stkey:"47D68CEA8CA340",
        detTable__: "cldetail_g33" ,
        detclass: 3,
        ...restValues,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const addVehicle = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        active: 1,
        stclass: 1,
        stkey: "47D68CEA8CA340",
        detTable__: "cldetail_g33",
        detclass: 1,
        detkey: "",
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const editVehicle = async (values) => {
  try {
   
    const token = window.localStorage.getItem("user_id");
   const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        active: 1,
        stclass: 1,
        stkey:"47D68CEA8CA340",
        detTable__: "cldetail_g33" ,
        detclass: 1,
        ...restValues,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};