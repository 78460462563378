import { Modal, Input, Form, Row, Col, Dropdown, Typography, Menu } from "antd";
import React, { useMemo, useState, useEffect } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";

import useWindowDimensions from 'util/useWindowDimensions';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { url } from "constants/index";

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

export default function ShowPfd(props) {  
  const { searchQuery, showPdf, setShowPdf, fileId } = props;

  const history = useHistory();
  const { Text } = Typography;
  const [searchString, setSearchString] = useState(searchQuery);

  const [pdfFileObject, setPdfFileObject] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  
  const { height, width } = useWindowDimensions();
  
  const [searchResults, setSearchResults] = useState([]);

  const [pages, setPages] = useState([]);

  useEffect(() => {

    const token = window.localStorage.getItem("user_id");
    var pdfFileObjectTmp = {
      url:  url   + 'search/getFile/' + fileId,
      httpHeaders: { Authorization: `Bearer ${token}` },
    };
    setPdfFileObject(pdfFileObjectTmp);

    pdfjs.getDocument(pdfFileObjectTmp).promise.then((docData) => {
      const pageCount = docData._pdfInfo.numPages;
      
      const pagePromises = Array.from(
        { length: pageCount },
        (_, pageNumber) => {
          return docData.getPage(pageNumber + 1).then((pageData) => {
            return pageData.getTextContent().then((textContent) => {
              return textContent.items.map(({ str }) => str).join(" ");
            });
          });
        }
      );

      return Promise.all(pagePromises).then((pages) => {
        setPages(pages);
      });
    });
  }, []);

  useEffect(() => {
    if (!searchString || !searchString.length) {
      setSearchResults([]);
      return;
    }
    /* 
      Currently this regex is case-insensitive. This could be extended to be configurable. 
      Or could be extended to be a fuzzy search. Fuzzy search would need a more 
      complex return from the hook to be able to highlight the found term(s) in the view.
      EX: resultsList = Array<{ pageNumber: number, matchedTerms: Array<string> }>
    */
    
    //const regex = new RegExp(`${searchString}*`, "i");
    var regExSearchString = searchString.trim().replace(/\s+/g, ' ').replace(/\s/g, '|');
    const regex = new RegExp(`${regExSearchString}*`, "i");

    const updatedResults = [];

    pages.forEach((text, index) => {
      if (regex.test(text)) {
        updatedResults.push(index + 1);
      }
    });

    setSearchResults(updatedResults);

    if (updatedResults.length > 0) {
      setPageNumber(updatedResults[0]);
    }
    
  }, [pages]);

  const textRenderer = useMemo(
    (textItem) => {      
      if (!textItem) return null;
      return highlightPattern(textItem.str, searchString);
    },
    [searchString]
  );
  
  function highlightPattern(text, pattern) {
    const splitText = text.split(pattern);
  
    if (splitText.length <= 1) {
      return text;
    }
  
    const matches = text.match(pattern);
  
    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
          : [...arr, element],
      []
    );
  }

  function previousMatch() {
  
    for (let i = searchResults.length - 1; i >= 0; i--) {
      let x = searchResults[i];
      if (x < pageNumber) {
        setPageNumber(x);
        break;
      }
    }
  }
  
  function nextMatch() {
    
    for (let i of searchResults) {
      if (i > pageNumber) {
        setPageNumber(i);
        break;
      }
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  
  function previousPage() {
    changePage(-1);
  }
  
  function nextPage() {
    changePage(1);
  }

  const css = ` mark { 
    opacity: 0.5; 
    background: #ff0; 
    box-shadow: 0px 2px 10px #ff0; 
    color: transparent; 
    white-space: pre; 
  } `;

  return (
    <>
    <style>{css}</style>
    <Modal
      title={
        <>
          PDF anzeigen
        </>
      }
      footer={null}
      centered
      visible={showPdf}
      width={width*0.50}
      destroyOnClose
      okText="Ok"
      onOk={() => {
        setShowPdf(false);
      }}
      onCancel={() => {
        setShowPdf(false);
      }}
    >     
    <center>
    <button
          type="primary"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          <LeftOutlined />
        </button>
        &nbsp;Seite&nbsp;
        <button
          type="primary"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          <RightOutlined />
        </button>&nbsp;
        <button
          type="primary"
          disabled={pageNumber <= 1 || pageNumber == searchResults[0]}
          onClick={previousMatch}
        >
          <LeftOutlined />
        </button>
        &nbsp;Treffer&nbsp;
        <button
          type="primary"
          disabled={pageNumber >= numPages || pageNumber == searchResults[searchResults.length - 1]}
          onClick={nextMatch}
        >
          <RightOutlined />
        </button>
        &nbsp;Seite {pageNumber || (numPages ? 1 : '--')} von {numPages || '--'}
        <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
            </div>
            <TransformComponent>
          <Document file={pdfFileObject} 
                    onLoadSuccess={onDocumentLoadSuccess} >
         
      {
     //searchString &&   
    searchResults &&
    <Page pageNumber={pageNumber} customTextRenderer={
      
      (textItem) => (
        textItem.str.toLowerCase()
          .split(searchString.toLowerCase())
          .reduce((strArray, currentValue, currentIndex) => (
            currentIndex === 0
              ? ([...strArray, currentValue])
              : ([...strArray, (
                // eslint-disable-next-line react/no-array-index-key
                <mark key={currentIndex}>
                  {searchString.toLowerCase()}
                </mark>
              ), currentValue])
          ), []) )

    } />
    }
            </Document>
            </TransformComponent> </React.Fragment> )  }
      </TransformWrapper> 
            </center>
    </Modal>
    </>
  );
}
