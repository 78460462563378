import { Typography, Menu, Dropdown, Modal, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { useEffect, useState } from "react";
import Inspections from "./components/Inspections";
import AddInspection from "../../main/pruefCockpit/NewInspection";
import EditInspection from "../../main/pruefCockpit/EditInspection";

export default function ModelInspections(props) {
  const { state, stClass, isModalVisible, setIsModalVisible } = props;
  const { Text } = Typography;

  const [update, setUpdate] = useState(false);
  const [newInspection, setNewInspection] = useState(null);
  const [editInspection, setEditInspection] = useState(null);
  const [load, setLoad] = useState(false);

  return (
    <>
      <AddInspection
        open={newInspection}
        setOpen={setNewInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        roomAppliance={true}
        stclass={stClass}
      />

      <EditInspection
        open={editInspection}
        setOpen={setEditInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        reset={null}
        roomAppliance={true}
        stclass={stClass}
      />

      <Modal
        title={`Inspektionen ${state?.cfeld1}`}
        width={800}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        <Inspections
          setNewInspection={setNewInspection}
          state={state}
          setEditInspection={setEditInspection}
          update={update}
          setUpdate={setUpdate}
          stclass={stClass}
        />
      </Modal>
    </>
  );
}
