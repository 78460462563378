import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import {
  deleteCategory,
  editCategory,
  getCatagories,
} from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddCategory from "./addCategory";
import EditCategory from "./editCategory";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../extraComponents/MoreActions";
import vehicle from "../../../../assets/Icons_waste/vehicle.png";
import info from "../../../../assets/Icons_waste/info_blue.png";

const { Text } = Typography;

export default function Catagories(props) {
  const { showStates, setShowStates } = props;
  const [Catagories, setCatagories] = useState([]);
  const [addCatagories, setAddCatagories] = useState(false);
  const [editCatagories, setEditCatagories] = useState(null);

  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [type, setType] = useState("none");

  useEffect(async () => {
    setLoad(true);
    const Catagories_ = await getCatagories();
    if (Catagories_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setCatagories(Catagories_ ? Catagories_ : []);
    setLoad(false);
  }, [page, updateData, type]);

  return (
    <>
      <AddCategory
        open={addCatagories}
        setOpen={setAddCatagories}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        stkey="47df310efa4720"
      />
      <EditCategory
        Category={editCatagories}
        setCategory={setEditCatagories}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        stkey="47df310efa4720"
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <IntlMessages id="Categories" />
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
            align="middle"
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="Categories" />
              </Text>
            </Col>
            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>

            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              {/* <TypeSelecter type={type} setType={setType} /> */}
            </Col>
          </Row>

          {Catagories?.length === 0 ? <Empty /> : null}

          {Catagories?.map((Category) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditCatagories(Category);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    <img
                      src={
                        Category.icon === "info.png"
                          ? info
                          : Category.icon === "vehicle.png"
                          ? vehicle
                          : null
                      }
                      height={"22px"}
                      style={{ marginRight: "10px" }}
                    />
                    {Category?.title}
                  </Text>
                </Col>

                <Col>
                  <MoreActions
                    state={Category}
                    noPic={true}
                    onDelete={async (data) => {
                      const response = await deleteCategory({ ...data });
                      setUpdateData(!updateData);
                      return response;
                    }}
                    update={updateData}
                    setUpdate={setUpdateData}
                  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>

                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <RightSquareOutlined
                    size="large"
                    style={{
                      color: "#038fde",
                      marginLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      setShowStates({
                        ...showStates,
                        page: 2,
                        name: Category?.title,
                        key: Category?.categoryKey,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddCatagories(!addCatagories);
              }}
            >
              <IntlMessages id="Add Category" />
            </Button>
          </Row>
        </>
      ) : null}
    </>
  );
}
