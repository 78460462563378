import Table from "./components/table";

export default function Home(props) {
  const {
    events,
    setEvents,
    editEvent,
    setEditEvent,
    updateEvents,
    setUpdateEvents,
  } = props;
  return (
    <Table
      events={events}
      setEvents={setEvents}
      editEvent={editEvent}
      setEditEvent={setEditEvent}
      updateEvents={updateEvents}
      setUpdateEvents={setUpdateEvents}
    />
  );
}
