import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Home from "./pages/home";
import Event from "./pages/event";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/de_DE";
import "moment/locale/de";

const EventManagement = () => {
  let { page } = useParams();
  const [events, setEvents] = useState([]);
  const [updateEvents, setUpdateEvents] = useState(false);
  const [editEvent, setEditEvent] = useState(null);

  return (
    <ConfigProvider locale={locale}>
      {page && (page === "indoor-event" || page === "outdoor-event") ? (
        <Event
          events={events}
          setEvents={setEvents}
          editEvent={editEvent}
          setEditEvent={setEditEvent}
          updateEvents={updateEvents}
          setUpdateEvents={setUpdateEvents}
        />
      ) : (
        <Home
          events={events}
          setEvents={setEvents}
          editEvent={editEvent}
          setEditEvent={setEditEvent}
          updateEvents={updateEvents}
          setUpdateEvents={setUpdateEvents}
        />
      )}
    </ConfigProvider>
  );
};

export default EventManagement;
