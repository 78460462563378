import { Row, Col, Typography, Button, Pagination, Spin } from "antd";
import { useEffect, useState } from "react";
import { editSubarea, getSubAreas } from "./network_requests";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import AddSubarea from "./AddSubarea";
import EditSubarea from "./EditSubarea";
import SubareaMoreActions from "./MoreActions";
import Coordinate from "./coordinates";
const { Text } = Typography;

const SubAreas = () => {
  const [count, setCount] = useState(null);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [coordinateOpen, setCoordinateOpen] = useState(false);
  const [edit, setEdit] = useState(null);
  const [update, setUpdate] = useState(false);
  const [addLoc, setAddLoc] = useState(true);
  const [latlng, setLatlng] = useState(null);

  const [subarea, setSubarea] = useState(null);
  const history = useHistory();
  const [subArea, setSubArea] = useState(null);

  useEffect(async () => {
    setLoad(true);

    const subareas_ = await getSubAreas((page - 1) * pageSize, pageSize);
    if (subareas_?.list?.length > 0) {
      setSubArea(subareas_?.list);
    }

    setLoad(false);
  }, [update, page, pageSize]);

  return (
    <div>
      {addLoc ? (
        <AddSubarea
          open={open}
          setOpen={setOpen}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
          latlng={latlng}
        />
      ) : null}
      {Boolean(edit) ? (
        <EditSubarea
          open={edit}
          setOpen={setEdit}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
        />
      ) : null}
      <Coordinate open={coordinateOpen} setOpen={setCoordinateOpen} />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <Row style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "16px",
              width: "100%",
            }}
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>Name</Text>
            </Col>
            <Col xs={12} sm={12} xl={6}>
              <Text>Action</Text>
            </Col>
          </Row>
          <Col xs={24}>
            {subArea?.map((subarea) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col
                    xs={12}
                    sm={12}
                    xl={18}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSubarea({ ...subarea });

                      // setCenter([subarea?.nfeld2, subarea?.nfeld1]);
                      //  setEditEstates(estate);
                    }}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {subarea?.cfeld2};{subarea?.cfeld1}
                    </Text>
                  </Col>

                  <Col>
                    <SubareaMoreActions
                      state={subarea}
                      setEdit={setEdit}
                      edit={editSubarea}
                      coordinateOpen={coordinateOpen}
                      setCoordinateOpen={setCoordinateOpen}
                      update={update}
                      setUpdate={setUpdate}
                    />
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />
                  </Col>
                </Row>
              );
            })}

            <Row
              style={{
                backgroundColor: "#fff",
                margin: "0",
                padding: "10px 0",
              }}
            >
              <Col
                xs={24}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  Add Subarea
                </Button>
              </Col>
              <Col
                xs={24}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Pagination
                  defaultCurrent={1}
                  total={count}
                  current={page}
                  pageSize={pageSize}
                  pageSizeOptions={[5, 10, 15]}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage, pageSize_) => {
                    setPage(newpage);
                    setPageSize(pageSize_);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default SubAreas;
