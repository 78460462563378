import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  message,
} from "antd";
import { useRef, useState } from "react";
import moment from "moment";
import {
  string_to_double,
  string_to_doubleDT,
} from "../../../../../../constants";
import { addArtist } from "../networkrequests";
import IntlMessages from "util/IntlMessages";

const success = () => {
  message.success("The artist has been added.");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function AddArtist(props) {
  const { open, setOpen, parent } = props;

  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld2, setnfeld2] = useState(moment(moment(), "DD.MM.YYYY HH:mm"));
  const ref = useRef();
  const { Text } = Typography;

  return (
    <Modal
      title={"Künstler hinzufügen"}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        // setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
      style={{
        minWidth: "45vw",
      }}
    >
      <Form
        name="add artist"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          const response = await addArtist(
            {
              ...values,
              nfeld1: nfeld1 ? string_to_double(nfeld1) : 0,
              nfeld2: nfeld2 ? string_to_doubleDT(nfeld2) : 0,
            },
            parent
          );
          if (response?.success) {
            success();
          } else {
            error();
          }
          props.setUpdate(!props.update);
          setnfeld1(moment(moment(), "DD.MM.YYYY"));
          setnfeld2(moment(moment(), "DD.MM.YYYY HH:mm"));
          setOpen(!open);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item
              name="cfeld1"
              label="Name"
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: "The field cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={11}>
            <Form.Item label="Datum des Auftritts">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={2} />

          <Col xs={11}>
            <Form.Item label="Eintreffen">
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld2}
                showTime={"HH:mm"}
                format="DD.MM.YYYY HH:mm"
                onChange={(e, d) => {
                  setnfeld2(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={11}>
            <Form.Item name="cfeld8" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={2} />
          <Col xs={11}>
            <Form.Item name="cfeld9" label="Telefon">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld2" label="Agentur">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={11}>
            <Form.Item name="cfeld3" label="Straße / Hausnummer">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={2} />
          <Col xs={11}>
            <Form.Item name="cfeld4" label="Postleitzahl">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld5" label="Künstlergeschenk">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld6" label="Künstlerverpflegung">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "100%", height: "75px" }}>
          <Col xs={24}>
            <Form.Item name="cfeld7" label="Sonstiges">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
