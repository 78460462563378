import { Row, Col, Typography, Collapse, Switch, Button, Modal } from "antd";
import ServicePanel from "./servicePanel";
import { useEffect, useState } from "react";
import { getSubAreaTemplates } from "./network_requests";
import { useHistory, useParams } from "react-router-dom";
import TemplateMain from "./TemplateMain";
import Appointment from "./appoitments";
import MailSMS from "./mailSMS";
import Info from "./info";

export default function ServiceTemplates({ service }) {
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);
  const [templates, setTemplates] = useState(null);
  const history = useHistory();
  let { page, subkey } = useParams();
  const [insertTemplate, setInsertTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState({});
  const [type, setType] = useState("insert");
  const subarea = service?.subareas?.find(
    (subarea) => subarea?.stkey === subkey
  );

  console.log(service);

  useEffect(async () => {
    setLoad(true);
    const templates_ = await getSubAreaTemplates(subkey);
    if (templates_?.status === 401) {
      localStorage.removeItem("company_id");
      history.go(0);
    }
    setTemplates(templates_);
  }, [service, update]);

  const categories = [
    "Terminplanungs Templates",
    "Mail/SMS Templates",
    "Informations Templates",
  ];
  return (
    <Row style={{ width: "100%", padding: "10px 15px" }}>
      <Row style={{ marginBottom: "10px", width: "100%" }}>
        <Col xs={24}>
          <Typography.Text
            style={{
              fontWeight: 600,
              textTransform: "uppercase",
              cursor: "pointer",
            }}
          >
            <span
              onClick={() => {
                history.push("/main/service_areas");
              }}
            >
              {"Servicebereiche -> "}
            </span>
            <span
              style={{ paddingLeft: "5px" }}
              onClick={() => {
                setEditTemplate(null);
                history.push(`/main/service_areas/main/${subkey}`);
              }}
            >
              {`${service?.serviceTitle} -> ${subarea?.subareatitle}`}
            </span>
            {editTemplate?.title ? (
              <span
                style={{ paddingLeft: "5px" }}
              >{` -> ${editTemplate?.title}`}</span>
            ) : null}
          </Typography.Text>
        </Col>
      </Row>
      {page === "main" ? (
        <TemplateMain
          categories={categories}
          setInsertTemplate={setInsertTemplate}
          templates={templates}
          setEditTemplate={setEditTemplate}
          update={update}
          setUpdate={setUpdate}
          setType={setType}
        />
      ) : page === "appointment" ? (
        <Appointment
          insertTemplate={insertTemplate}
          editTemplate={editTemplate}
          setEditTemplate={setEditTemplate}
          setType={setType}
          update={update}
          setUpdate={setUpdate}
          type={type}
        />
      ) : page === "mailSMS" ? (
        <MailSMS
          insertTemplate={insertTemplate}
          editTemplate={editTemplate}
          setEditTemplate={setEditTemplate}
          setType={setType}
          update={update}
          setUpdate={setUpdate}
          type={type}
        />
      ) : (
        <Info
          insertTemplate={insertTemplate}
          editTemplate={editTemplate}
          setEditTemplate={setEditTemplate}
          setType={setType}
          update={update}
          setUpdate={setUpdate}
          type={type}
        />
      )}
    </Row>
  );
}
