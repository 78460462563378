import { url } from "../../../../../../constants";
import axios from "axios";

export const getFacilities = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g01",
        stClass: 4,
        stKey: val,
        detail: "cldetail_g01",
        detClass: 1,
        type: "none",
        limitFrom: limitFrom,
        limitTo: 5,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addFacility = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        detTable__: "cldetail_g01",
        detkey: "",
        active: 1,
        stkey: parent,
        stclass: 4,
        detclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editFacilities = async (values, parent, active) => {
  console.log(values);
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        active: active,
        stclass: 4,
        detclass: 1,
        stkey: parent,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAppliances = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: 9,
        type: "none",
        limitFrom: limitFrom,
        limitTo: 5,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: val, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addAppliance = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntries`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: parent,
          stclass: 4,
          storder: 0,
          treepos: 1,
          disptree: 0,
        },
        obj_Data: {
          active: 1,
          group: "1",
          stclass: 9,
          ...values,
          gisid: "",
          stkey: "",
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const editAppliance = async (values, active) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        group: "1",
        active: active,
        stclass: 9,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getITAppliances = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g01",
        stClass: "4",
        stKey: val,
        detail: "cldetail_g01",
        detClass: "2",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 5,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addITAppliance = async (values, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertDetail`,
      {
        detTable__: "cldetail_g01",
        detkey: "",
        active: 1,
        stkey: val,
        stclass: 4,
        detclass: 2,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editITAppliance = async (values, parent, active) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        ...values,
        detTable__: "cldetail_g01",
        active: active,
        stclass: 4,
        detclass: 2,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getMedicalAppliances = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "8",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 5,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: val, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addMedAppliance = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntries`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: parent,
          stclass: 4,
          storder: 0,
          treepos: 1,
          disptree: 0,
        },
        obj_Data: {
          active: 1,
          group: "1",
          stclass: 8,
          ...values,
          gisid: "",
          stkey: "",
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const editMedAppliance = async (values, active) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        group: "1",
        active: active,
        stclass: 8,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
