import { Row, Typography, Col, Button, Form, Input } from "antd";
import { useEffect, useState, useRef } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Documents from "./documents";
import { useHistory } from "react-router";

export default function CatagoriesStep2(props) {
  const { showStates, setShowStates } = props;
  const ref = useRef();
  const history = useHistory();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowStates({
              page: 2,
              name: showStates?.name,
              key: showStates?.key,
            });
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "22px", fontWeight: "bold" }} />
        </Typography>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 450,
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          {showStates?.name1}
        </Typography>
        <Typography
          style={{
            marginTop: "15px",
          }}
        >
          {showStates?.content}
        </Typography>

        <Documents templateKey={showStates?.key1} />
      </div>
    </>
  );
}
