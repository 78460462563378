import { Input, Form, Typography, Button } from "antd";
import { useRef } from "react";
import { addCategory } from "./network_requests";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";

export default function AddCategory(props) {
  const { open, setOpen } = props;

  const ref = useRef();
  const history = useHistory();

  return (
    <div
      style={{
        backgroundColor: "#f3f7ff",
        height: "95vh",
      }}
    >
      <>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#038fde",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "22px", fontWeight: "bold" }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Add Category
          </Typography>
        </div>
      </>
      <Form
        name="add Category"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          padding: "10px 30px",
        }}
        initialValues={{}}
        onFinish={async (values) => {
          if (values?.cfeld2) {
            setOpen(!open);
            props.setLoad(true);
            const response = await addCategory({
              ...values,
            });

            if (response.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }

            props.setUpdate(!props.update);
          }
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item label={"Title"} name="cfeld2" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>

        <Form.Item
          style={{
            marginTop: "16px",
            position: "absolute",
            bottom: "50px",
            display: "flex",
            justifyContent: "center",
            width: "85%",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "150px",
            }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
