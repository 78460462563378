import { Row, Typography, Col, Spin, Empty } from "antd";
import { getContent } from "./network_requests";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function CatagoriesStep1(props) {
  const { showStates, setShowStates } = props;
  const [Contents, setContents] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);

    const Contents_ = await getContent(showStates?.key);
    if (Contents_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setContents(Contents_ ? Contents_ : []);
    setLoad(false);
  }, [updateData]);

  return (
    <>
      <>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#038fde",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowStates({
                page: 1,
              });
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "22px", fontWeight: "bold" }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            {showStates?.name}
          </Typography>
        </div>

        <div
          style={{
            backgroundColor: "#f3f7ff",
            left: 0,
            padding: "20px",
            width: "100%",
            color: "gray",
            textTransform: "uppercase",
          }}
        >
          Bitte eine Kategorie auswählen
        </div>
      </>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          {Contents?.length === 0 ? <Empty /> : null}

          {Contents?.map((Content) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  padding: "20px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowStates({
                      ...showStates,
                      page: 3,
                      name1: Content?.title,
                      key1: Content?.contentKey,
                      content: Content?.content,
                    });
                  }}
                >
                  <Text>{Content?.title}</Text>
                </Col>
                <Col
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <RightOutlined
                    size="large"
                    onClick={() => {
                      setShowStates({
                        ...showStates,
                        page: 3,
                        name1: Content?.title,
                        key1: Content?.contentKey,
                        content: Content?.content,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
        </>
      ) : null}
    </>
  );
}
