import { useMapEvents } from "react-leaflet";

export default function DragComponent(props) {
  const map = useMapEvents({
    dragend: (e) => {
      props.setSearch(e.target.getCenter());
    },
  });
  return null;
}
