import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import {
  deleteIdea,
  getArchivedIdeas,
  getRatedIdeas,
  getUnratedIdeas,
  getUserGroups,
} from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../extraComponents/MoreActions";
import TypeSelecter from "../../extraComponents/MoreActions/typeSelecter";
import avatar_icon from "../../../assets/Icons_waste/avatar_icon.png";
import cal_icon from "../../../assets/Icons_waste/cal_icon.png";
import orange_cell from "../../../assets/Icons_waste/orange_call_sign.png";
import red_cell from "../../../assets/Icons_waste/red_call_sign.png";
import red_star from "../../../assets/Icons_waste/red_star_empty.png";
import green_star from "../../../assets/Icons_waste/green_star_empty.png";
import messageIcon from "../../../assets/Icons_waste/message_sign.png";
import { doubleDT_to_string } from "../../../constants";

const { Text } = Typography;

export default function Ideas(props) {
  const { title, attribute, setIdea } = props;
  const [Ideas, setIdeas] = useState([]);
  const [addIdeas, setAddIdeas] = useState(false);
  const [editIdeas, setEditIdeas] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [type, setType] = useState("none");
  const [userGroups, setUserGroups] = useState([]);

  useEffect(async () => {
    setLoad(true);
    const Ideas_ = await (attribute === "rated"
      ? getRatedIdeas((page - 1) * 10)
      : attribute === "archive"
      ? getArchivedIdeas((page - 1) * 10)
      : getUnratedIdeas((page - 1) * 10));
    if (Ideas_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setIdeas(Ideas_ ? Ideas_.list : []);
    setCount(Ideas_ ? Ideas_.count : 0);
    setLoad(false);
  }, [page, updateData, type]);

  return (
    <>
      <Row style={{ height: "14px" }}></Row>

      <Text
        style={{
          color: "#727272",
          fontSize: "18px",
        }}
      >
        {title}
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
            align="middle"
          >
            <Col
              xs={3}
              style={{
                padding: 0,
              }}
            >
              <Text>Rated by</Text>
            </Col>
            <Col
              xs={9}
              style={{
                padding: 0,
              }}
            >
              <Text>Idea</Text>
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>Creation Date</Text>
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>Created By</Text>
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {Ideas?.length === 0 ? <Empty /> : null}

          {Ideas?.map((Idea) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {Idea?.nfeld2} / {Idea?.nfeld3}
                    <img
                      src={
                        attribute === "archive"
                          ? Idea?.nfeld10
                            ? green_star
                            : red_star
                          : Idea?.nfeld9
                          ? messageIcon
                          : Idea?.nfeld8
                          ? orange_cell
                          : Idea?.nfeld2 < Idea?.nfeld3
                          ? red_cell
                          : Idea?.nfeld2 === Idea?.nfeld3
                          ? green_star
                          : null
                      }
                      height={"20px"}
                      style={{ paddingLeft: "8px" }}
                    />
                  </Text>
                </Col>

                <Col
                  xs={9}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIdea({
                      ...Idea,
                    });
                    history?.push(`./edit`);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {Idea?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    <img
                      src={cal_icon}
                      height={"20px"}
                      style={{ paddingRight: "8px" }}
                    />
                    {doubleDT_to_string(Idea?.nfeld1)}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    <img
                      src={avatar_icon}
                      height={"20px"}
                      style={{ paddingRight: "8px" }}
                    />
                    {Idea?.cfeld3}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <MoreActions
                    state={Idea}
                    noPic={true}
                    onDelete={async (data) => {
                      const response = await deleteIdea({ ...data });
                      setUpdateData(!updateData);
                      return response;
                    }}
                    update={updateData}
                    setUpdate={setUpdateData}
                  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              backgroundColor: "#FAFAFA",
              padding: "10px 30px 0 30px",
              margin: "0",
              minHeight: "14px",
            }}
            justify="end"
          >
            {count > 10 ? (
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                defaultPageSize={10}
                style={{
                  margin: 0,
                }}
                onChange={(newpage) => {
                  setPage(newpage);
                }}
              />
            ) : null}
          </Row>
        </>
      ) : null}
    </>
  );
}
