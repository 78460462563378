import {
  Row,
  Col,
  Typography,
  Button,
  Pagination,
  Spin,
  Switch,
  Menu,
  Dropdown,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import {
  editAshtray,
  getAshtrays,
  searchByName,
  searchByStreet,
  searchByCenter,
} from "./network_requests";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  MapConsumer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ashtray_icon from "../../../../assets/Icons_waste/cigarette_44_neu.png";
import { useHistory } from "react-router";
import AddAshtray from "./AddAshtray";
import EditAshtray from "./EditAshtray";
import { locationIcon } from "../../../../constants";
import MapMoreActions from "../../../extraComponents/MoreActions/MapMoreActions";
import DragComponent from "../../../extraComponents/MoreActions/DragComponent";

const { Text } = Typography;
const { Search } = Input;

const Ashtrays = () => {
  const [ashtrays, setAshtrays] = useState([]);
  const [count, setCount] = useState(null);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ashtraysMarkers, setAshtraysMarkers] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);
  const [update, setUpdate] = useState(false);
  const [addLoc, setAddLoc] = useState(true);
  const [latlng, setLatlng] = useState(null);
  const [position, setPosition] = useState(null);
  const history = useHistory();
  const [map, setMap] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setSearchType(null);
          setSearchText("");
          setSearch("");
        }}
      >
        <IntlMessages id="search.Reset_Search" />
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setSearchType("name");
          setSearchText("");
        }}
      >
        <IntlMessages id="search.byName" />
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setSearchType("street");
          setSearchText("");
        }}
      >
        <IntlMessages id="search.byStreet" />
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setSearchType("center");
          setSearchText("");
        }}
      >
        <IntlMessages id="search.aroundCenter" />
      </Menu.Item>
    </Menu>
  );

  const icon = L.icon({
    iconUrl: ashtray_icon,
    iconSize: [30, 30], // size of the icon
    shadowSize: [50, 64], // size of the shadow
  });

  useEffect(() => {
    if (center) {
      if (map) {
        map.flyTo(center);
      }
    }
  }, [center]);

  useEffect(() => {
    if (map)
      map?.locate()?.on("locationfound", function (e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng);
      });
  }, [map]);

  useEffect(async () => {
    setLoad(searchType !== "center" ? true : load);
    setAshtraysMarkers([]);

    const ashtrays_ =
      searchType === "name"
        ? await searchByName((page - 1) * pageSize, pageSize, search)
        : searchType === "street"
        ? await searchByStreet((page - 1) * pageSize, pageSize, search)
        : searchType === "center"
        ? await searchByCenter(
            (page - 1) * pageSize,
            pageSize,
            search?.lat,
            search?.lng
          )
        : await getAshtrays((page - 1) * pageSize, pageSize);
    if (ashtrays_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setAshtrays(ashtrays_?.list);
    setCount(ashtrays_?.count);
    setLoad(false);
  }, [update, page, pageSize, search]);

  return (
    <div>
      {addLoc ? (
        <AddAshtray
          open={open}
          setOpen={setOpen}
          setLoad={setLoad}
          update={update}
          setUpdate={setUpdate}
          latlng={latlng}
        />
      ) : null}
      <EditAshtray
        open={edit}
        setOpen={setEdit}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <Row>
          <Col
            xs={12}
            sm={12}
            xl={8}
            style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
          >
            {searchType ? (
              <div>
                {searchType === "center" ? (
                  <Row
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #eaeaea",
                      fontWeight: 400,
                      padding: "10px 10px",
                      margin: "20px 0 0 0",
                      color: "#808080",
                    }}
                  >
                    <Col
                      xs={12}
                      sm={12}
                      xl={18}
                      style={{
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SearchOutlined
                        style={{ color: "#808080", paddingRight: "10px" }}
                      />
                      <Text>Search around {searchType}</Text>
                    </Col>
                  </Row>
                ) : (
                  <Search
                    placeholder={`Search for ${searchType}`}
                    onSearch={(value) => {
                      setSearch(value);
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                    style={{ margin: "5px 0 0 0", padding: 0 }}
                    enterButton
                  />
                )}
              </div>
            ) : null}
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "20px 0 0 0",
              }}
            >
              <Col xs={12} sm={12} xl={18} style={{ padding: 0 }}>
                <Text>
                  <IntlMessages id="menu.ashtray" />
                </Text>
              </Col>
            </Row>
            {ashtrays?.map((ashtray) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col
                    xs={12}
                    sm={12}
                    xl={18}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAshtraysMarkers([ashtray]);
                      setCenter([ashtray?.nfeld2, ashtray?.nfeld1]);

                      //  setEditEstates(estate);
                    }}
                  >
                    <img
                     src={
                      ashtray_icon
                     }
                     style={{ height: "20px", marginRight: "8px" }}
                   />
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {ashtray?.cfeld1}
                    </Text>
                  </Col>

                  <Col>
                    <MapMoreActions
                      state={ashtray}
                      setEdit={setEdit}
                      edit={editAshtray}
                    />
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px",
                margin: "0",
              }}
            >
              <Col span={12}>
                <Button
                  size="small"
                  type="text"
                  style={{
                    margin: 0,
                    color: "#038fde",
                  }}
                  onClick={() => {
                    setAshtraysMarkers([...ashtrays]);
                  }}
                >
                  <IntlMessages id="outdoor.showonmap" />
                  {/* <IntlMessages id="estate.add" /> */}
                </Button>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  size="small"
                  type="text"
                  style={{
                    margin: 0,
                    color: "#038fde",
                  }}
                  onClick={() => {
                    //setOpen(!open);
                  }}
                >
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                      size="small"
                      type="text"
                      style={{
                        margin: 0,
                        color: "#038fde",
                      }}
                      onClick={() => {
                        //setOpen(!open);
                      }}
                    >
                      <IntlMessages id="outdoor.searchoptions" />
                      {/* <IntlMessages id="estate.add" /> */}
                    </Button>
                  </Dropdown>
                  {/* <IntlMessages id="estate.add" /> */}
                </Button>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#fff",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                pageSize={pageSize}
                pageSizeOptions={[5, 10, 15]}
                style={{
                  margin: 0,
                }}
                onChange={(newpage, pageSize_) => {
                  setPage(newpage);
                  setPageSize(pageSize_);
                }}
              />
            </Row>
          </Col>
          <Col span={1} />
          <Col
            xs={11}
            sm={11}
            xl={15}
            style={{
              padding: "0 25px 25px 25px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <Row style={{ padding: "20px", alignItems: "center" }}>
              <span style={{ paddingRight: "10px" }}>
                <IntlMessages id="outdoor.addlocation" />
              </span>
              <Switch
                checked={addLoc}
                onChange={() => {
                  setAddLoc(!addLoc);
                }}
              />
            </Row>
            {center ? (
              <MapContainer
                center={center}
                zoom={16}
                whenCreated={setMap}
                scrollWheelZoom={false}
                style={{
                  height: "75vh",
                }}
                tap={false}
              >
                {searchType === "center" ? (
                  <DragComponent setSearch={setSearch} />
                ) : null}
                <TileLayer
                  // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  subdomains={["mt1", "mt2", "mt3"]}
                />
                <MapConsumer>
                  {(map) => {
                    map.on("click", function (e) {
                      const { lat, lng } = e.latlng;
                      setLatlng({
                        lat: lat,
                        lng: lng,
                      });
                      setOpen(!open);
                    });
                    return null;
                  }}
                </MapConsumer>

                {position ? (
                  <Marker position={position} icon={locationIcon}>
                    <Popup>
                      You are here. <br />
                    </Popup>
                  </Marker>
                ) : null}

                {ashtraysMarkers?.map((mark) => {
                  return (
                    <Marker position={[mark.nfeld2, mark.nfeld1]} icon={icon}>
                      <Popup>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEdit(mark);
                          }}
                        >
                          {mark.cfeld1}
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            ) : null}
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default Ashtrays;
