import { Typography, Menu, Dropdown, Popconfirm, message, Modal } from "antd";
import IntlMessages from "util/IntlMessages";
import { useState } from "react";

import { deleteProperty, editProperty } from "./network_requests";

import "react-html5-camera-photo/build/css/index.css";
import UploadDocs from "./UploadDocs";

export default function MorePropertyActions(props) {
  const { state, setEdit, setCoordinateOpen, restore } = props;
  const { Text } = Typography;
  const [photo, setPhoto] = useState(null);
  const [image, setImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attribute, setAttribute] = useState("general");

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setEdit(state);
        }}
      >
        Details
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setCoordinateOpen(state);
        }}
      >
        Add Coordinates
      </Menu.Item>
      <Popconfirm
        placement="bottomLeft"
        title={`Do you really want to ${
          props.tree ? "fall this tree" : "remove this object?"
        }`}
        onConfirm={async () => {
          const response_ = await deleteProperty(state);
          props?.setUpdate(!props?.update);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Menu.Item key="2">Remove Object</Menu.Item>
      </Popconfirm>
      <Menu.Item
        key="3"
        onClick={() => {
          setIsModalVisible(!isModalVisible);
          setAttribute("general");
        }}
      >
        Files
      </Menu.Item>

      <Menu.Item
        key="4"
        onClick={() => {
          setIsModalVisible(!isModalVisible);
          setAttribute("plot");
        }}
      >
        Exchange Plot
      </Menu.Item>
      {restore ? (
        <Popconfirm
          title="Do you really want to restore this property?"
          onConfirm={async () => {
            await editProperty({ ...state });
            props?.setUpdate(!props?.update);
          }}
        >
          <Menu.Item key="5">Restore</Menu.Item>
        </Popconfirm>
      ) : null}
    </Menu>
  );

  return (
    <>
      <Modal
        title={`Photo ${state?.cfeld1}`}
        visible={Boolean(photo)}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
      >
        <img
          src={`data:image/jpeg;base64,${image?.file}`}
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>

      <Modal
        title={`Files ${state?.cfeld2};${state?.cfeld1}`}
        width={450}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        <UploadDocs attribute={attribute} templateKey={state?.cfeld29} />
      </Modal>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Text
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
        >
          <IntlMessages id="more" />
        </Text>
      </Dropdown>
    </>
  );
}
