import { Col, Row, Tabs, Typography } from "antd";
import { useState } from "react";
import orange_cell from "../../../assets/Icons_waste/orange_call_sign.png";
import Comments from "./comments";

export default function OtherDissens(props) {
  const { TabPane } = Tabs;
  const { dissens } = props;
  const { Text } = Typography;
  const [active, setActive] = useState(`Dissens `);
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey={active}
          tabPosition="left"
          style={{ width: "100%" }}
          size="small"
          onChange={(key) => {
            setActive(key);
          }}
        >
          {dissens?.map((dissen, index) => {
            return (
              <TabPane
                tab={`Dissens ${index + 1}`}
                key={`Dissens ${index + 1}`}
              >
                <Row style={{ width: "100%", padding: "0px 25px" }}>
                  <Row
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                      padding: "20px",
                    }}
                  >
                    <Col xs={22} style={{ padding: 0 }}>
                      <Text
                        style={{
                          color: "#727272",
                          fontSize: "16px",
                        }}
                      >
                        {dissen?.cfeld1}
                      </Text>
                    </Col>
                    <Col xs={2}>
                      <img src={orange_cell} height={"20px"} />
                    </Col>
                  </Row>
                  <Comments stkey={dissen?.detkey} />
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      </Row>
    </>
  );
}
