import {
  Input,
  Form,
  Row,
  Col,
  Dropdown,
  Typography,
  Menu,
  Button,
  message,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { editUser, getUser, getUserGroups } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

export default function MyAccount(props) {
  const [user, setUser] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [load, setLoad] = useState(false);
  const open = Boolean(user);
  const ref = useRef();
  const history = useHistory();
  const [group, setGroup] = useState(null);
  const { Text } = Typography;

  useEffect(async () => {
    setLoad(true);
    const User_ = await getUser();
    if (User_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    const groups = await getUserGroups();
    setUserGroups(groups);
    setUser(User_);
    setLoad(false);
  }, []);

  useEffect(() => {
    if (user?.groupkey) {
      const group_ = userGroups?.find(
        (item) => item?.groupkey === user.groupkey
      );
      setGroup(group_);
    }
  }, [user]);

  const group_menu = (
    <Menu title="Choose field">
      {userGroups?.map((group) => {
        return (
          <Menu.Item
            key={group?.groupkey}
            onClick={(e) => {
              setGroup(group);
            }}
          >
            {group?.groupname}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const validatePassword = (_, value) => {
    if (value && value.length < 8) {
      return Promise.reject('Password must be at least 8 characters long');
    }
    return Promise.resolve();
  };

  return (
    <Row justify={"center"}>
      {!load ? (
        <Col xs={12}>
          <Form
            name="add User"
            ref={ref}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              ...user,
            }}
            onFinish={async (values) => {
              const response = await editUser({
                ...user,
                ...values,
              });

              if (response.status === 401) {
                localStorage.removeItem("user_id");
                history.go(0);
              }
              if (response?.success) {
                success();
              } else {
                error();
              }
            }}
          >
            <Form.Item
              label={<IntlMessages id="account_username" />}
              name="username"
              style={{ marginBottom: 0 }}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="account_password" />}
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Pflichtfeld',
                },
                {
                  validator: validatePassword,
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="account_firstname" />}
              name="firstname"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="account_lastname" />}
              name="lastname"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" style={{ marginBottom: 0 }}>
              <Input />
            </Form.Item>
            <Form.Item label={<IntlMessages id="account_group" />} style={{ marginBottom: 0 }}>
              <Dropdown overlay={group_menu} trigger="click" disabled>
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{group?.groupname}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginTop: "15px" }}
              >
                {<IntlMessages id="save" />}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      ) : null}
    </Row>
  );
}
