import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Typography,
  DatePicker,
  Dropdown,
  Menu,
} from "antd";
import { useRef, useState } from "react";
import { addMachine } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { string_to_double } from "../../../../../../constants";

export default function AddMachine(props) {
  const { open, setOpen, setPage, parent, fields } = props;
  const { Text } = Typography;
  const [nfeld4, setnfeld4] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld5, setnfeld5] = useState(moment(moment(), "DD.MM.YYYY"));
  const [cfeld10, setcfeld10] = useState(moment(moment(), "DD.MM.YYYY"));
  const [checkInterval, setCheckInterval] = useState(null);
  const [maintenanceInterval, setMainInterval] = useState(null);

  const ref = useRef();
  const history = useHistory;

  const interval_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCheckInterval(e.key);
      }}
    >
      {fields?.interval?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const main_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setMainInterval(e.key);
      }}
    >
      {fields?.interval?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="machine.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="Add machine"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addMachine(
            {
              ...values,
              nfeld4: string_to_double(nfeld4),
              nfeld5: string_to_double(nfeld5),
              cfeld10: string_to_double(cfeld10),
              cfeld4: checkInterval,
              cfeld13: maintenanceInterval,
            },
            parent
          );

          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }

          props.setUpdate(!props.update);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="name_identifier" />}
              name="cfeld2"
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="type" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="serial_number" />}
              name="cfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="QR_code" />}
              name="cfeld16"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cost_center" />}
              name="cfeld17"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="net_area" />}
              name="nfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="height" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cleaning_program" />}
              name="cfeld15"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="producer" />}
              name="cfeld8"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="group" />}
              name="cfeld14"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <div
              style={{
                height: "36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text> {<IntlMessages id="next_check" />}</Text>
            </div>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={nfeld4}
              format="DD.MM.YYYY"
              onChange={(e, d) => {
                setnfeld4(d);
              }}
            />
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="check_intervall" />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={interval_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={22}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{checkInterval}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <div
              style={{
                height: "36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text>{<IntlMessages id={"next_maintenance"} />}</Text>
            </div>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={nfeld5}
              format="DD.MM.YYYY"
              onChange={(e, d) => {
                setnfeld5(d);
              }}
            />
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="maintenance_interval" />}
              name="cfeld13"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={main_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={22}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{maintenanceInterval}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="power" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <div
              style={{
                height: "36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text>{<IntlMessages id="exit_date" />}</Text>
            </div>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={cfeld10}
              format="DD.MM.YYYY"
              onChange={(e, d) => {
                setcfeld10(d);
              }}
            />
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld9"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
