import {
  Button,
  Col,
  Row,
  Select,
  Typography,
  DatePicker,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import IntlMessages from "../../../../../util/IntlMessages";
import moment from "moment";
import WorkOrdersMain from "./components/workOrdersMain";
import { useHistory } from "react-router";
import { useState } from "react";
import { FileExcelOutlined, MailOutlined } from "@ant-design/icons";
import {
  getExcelForWorkOrders,
  createOrdersFromEmail,
} from "./components/network_Request";

const { Text } = Typography;
const { RangePicker } = DatePicker;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function WorkOrders() {
  const navigate = useHistory();
  const [filter, setFilter] = useState("none");
  const [dates, setDates] = useState([
    moment(new Date()).format("YYYYMMDD"),
    moment(new Date()).format("YYYYMMDD"),
  ]);
  const [updateData, setUpdateData] = useState(false);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={4} style={{}}>
          <Text style={{ fontSize: "18px" }}>
            <IntlMessages id="wordorder.workorders" />
          </Text>
        </Col>
        <Col xs={6}>
          <Select
            defaultValue={filter}
            style={{ width: 240 }}
            onChange={(value) => {
              setFilter(value);
            }}
            options={[
              { value: "none", label: "offen" },
              { value: "created", label: "erstellt" },
              { value: "planned", label: "geplant" },
              { value: "finished", label: "abgeschlossen" },
              { value: "all", label: "alle" },
            ]}
          />
        </Col>
        <Col
          xs={9}
          style={{
            color: "#2596be",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <RangePicker
            format={"DD.MM.YYYY"}
            defaultValue={[moment(), moment()]}
            onChange={(values, formatString) => {
              const modifiedArray = formatString.map((formatString) => {
                return formatString.replace(/\./g, "");
              });
              setDates(modifiedArray);
            }}
          />
        </Col>
        <Col xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            type="primary"
            style={{
              margin: 0,
            }}
            onClick={() => {
              navigate.push("/main/baseData-Order");
            }}
          >
            <IntlMessages id="wordorder.newworkorder" />
          </Button>
        </Col>
        <Col xs={1} style={{ display: "flex", alignItems: "center" }}>
          <Popconfirm
            title="Excel Datei erzeugen?"
            onConfirm={async () => {
              const response = await getExcelForWorkOrders("issue");
              if (response?.file) {
                downloadToLocal(response?.file, response?.filename);
              }
            }}
            okText="Ja"
            cancelText="Nein"
          >
            <Tooltip title="Excel Datei erzeugen!">
              <FileExcelOutlined style={{ cursor: "pointer", padding: 5 }} />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Arbeitsaufträge aus Mails erzeugen?"
            onConfirm={async () => {
              const response = await createOrdersFromEmail("issue");
              if (response?.success) {
                setUpdateData(!updateData);
                message.success(response?.success);
              }
            }}
            okText="Ja"
            cancelText="Nein"
          >
            <Tooltip title="Arbeitsaufträge aus Mails!">
              <MailOutlined style={{ cursor: "pointer", padding: 5 }} />
            </Tooltip>
          </Popconfirm>
        </Col>
      </Row>
      <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />

      <WorkOrdersMain
        filter={filter}
        dates={dates}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />
    </>
  );
}
