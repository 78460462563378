import { Row, Col } from "antd";
import CockpitCard from "./CockpitCard";
import CockpitMain from "./CockpitMain";
import CockpitRightCard from "./CockpitRightCard";
import IntlMessages from "../../../util/IntlMessages";
import moment from "moment";

export default function Cockpit() {
  return (
    <>
      {/* <div style={{ display: "flex" }}>
        <CockpitCard color="blue" />
        <CockpitCard color="orange" />
        <CockpitCard color="green" />
        <CockpitCard color="purple" />
      </div> */}
      <Row>
        <Col span={24} style={{}}>
          <p> <IntlMessages id="cockpit.appliancesExternal"/> / <IntlMessages id="cockpit.today"/> {moment(new Date()).format("DD.MM.YYYY")}</p>
          <div style={{ border: "1px solid #eaeaea", marginBottom: "15px" }} />
          <CockpitMain />
          
        </Col>
        {/* <Col span={8}>
          <CockpitRightCard />
          <CockpitRightCard />
        </Col> */}
      </Row>
    </>
  );
}
