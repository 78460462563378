import { Row, Typography, Col, Pagination, Spin, Tabs, Tooltip } from "antd";
import {
  getBuildings,
  getUrgentChecksforRecommendations,
} from "./network_requests";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { double_to_string, getWarning } from "../../../constants";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";

import ranking from "../../../assets/Icons_waste/explanation.png";

const { Text } = Typography;
const { TabPane } = Tabs;
export default function RecommendationChecks() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [recommendation, setRecommendation] = useState(null);
  const [order, setOrder] = useState("ASC");

  useEffect(async () => {
    setLoad(true);
    const recommendations_ = await getBuildings();
    if (recommendations_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setRecommendations(recommendations_?.list);
    setRecommendation(
      recommendations_?.list?.length > 0
        ? recommendations_?.list[0]?.stkey
        : null
    );
  }, []);

  useEffect(async () => {
    if (recommendation) {
      setLoad(true);
      const checks = await getUrgentChecksforRecommendations(
        recommendation,
        order
      );
      if (checks?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setData(checks);
      setLoad(false);
    }
  }, [recommendation, order]);

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <Tabs
                defaultActiveKey={recommendation}
                tabPosition="left"
                style={{ height: "75vh" }}
                size="small"
                onChange={(key) => {
                  setRecommendation(key);
                }}
              >
                {recommendations?.map((recommendation, i) => {
                  return (
                    <TabPane
                      tab={
                        <span>
                          <img
                            src={ranking}
                            style={{
                              height: "20px",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          ></img>
                          {recommendation?.cfeld1}
                        </span>
                      }
                      key={recommendation?.stkey}
                    >
                      <Row
                        style={{
                          backgroundColor: "#FAFAFA",
                          borderBottom: "1px solid #eaeaea",
                          fontWeight: 500,
                          padding: "10px 30px",
                          margin: "0 15px",
                        }}
                      >
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="Ranking" />
                          </Text>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="Prüfung" />
                          </Text>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="cockpit.LastCheck" />
                          </Text>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="cockpit.NextCheck" />
                            {order === "ASC" ? (
                              <ArrowDownOutlined
                                onClick={() => {
                                  setOrder("DESC");
                                }}
                                style={{ paddingLeft: "5px" }}
                              />
                            ) : (
                              <ArrowUpOutlined
                                onClick={() => {
                                  setOrder("ASC");
                                }}
                                style={{ paddingLeft: "5px" }}
                              />
                            )}
                          </Text>
                        </Col>
                      </Row>

                      {data?.slice((page - 1) * 10, page * 10)?.map((item) => {
                        return (
                          <Row
                            style={{
                              backgroundColor: "#fff",
                              borderBottom: "1px solid #eaeaea",
                              padding: "10px 30px",
                              margin: "0 15px",
                            }}
                          >
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.order}
                                <Tooltip
                                  title={
                                    <span>
                                      Erklärung:
                                      <br />
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Warum Ranking 1 unter Medizinprodukte?<br></br>
                                        Das Ranking <b>bis 13.12.2021</b> ,der <b>Priorität 2</b> und der Position <b>Erdgeschoss</b> im Gebäude.<br></br>
                                        Prüfer <b>Alfred</b> hat auch letztes mal geprüft.<br></br>
                                        Das Gerät hatte <b>keine Reparaturen</b> in der Vergangenheit.
                                      </p>
                                    </span>
                                  }
                                  trigger="click"
                                  overlayInnerStyle={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "1px solid #808080",
                                  }}
                                >
                                  <img
                                    src={ranking}
                                    style={{
                                      height: "30px",
                                      cursor: "pointer",
                                      marginLeft: "15px",
                                    }}
                                  ></img>
                                </Tooltip>
                              </p>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.checkType}
                              </p>
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.checkIntervall}
                              </p>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.lastCheck
                                  ? double_to_string(item?.lastCheck)
                                  : ""}{" "}
                              </p>
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.checkPerson}
                              </p>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor:
                                      getWarning(item?.warn2) > 0
                                        ? "red"
                                        : getWarning(item?.warn1) < 0
                                        ? "green"
                                        : "yellow",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div style={{ paddingLeft: "5px" }}>
                                  <p style={{ margin: 0, fontSize: "14px" }}>
                                    {item?.nextCheck
                                      ? double_to_string(item?.nextCheck)
                                      : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: "14px" }}>
                                    {item?.checkPerson}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                    </TabPane>
                  );
                })}
                {/* {[recommendations?.map(i => (
            <TabPane tab={`Tab-${i}`} key={i} disabled={i === 28}>
              Content of tab {i}
            </TabPane>
          ))} */}
              </Tabs>
            </Col>
          </Row>

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={data?.length}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
