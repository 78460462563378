import { Row, Typography, Col, Pagination, Spin, Empty,Popconfirm,Tooltip } from "antd";
import {
  getLastInspectionForAppliance,
  getUrgentChecks,
  getFields,
  getDetailForAppliance,
  getExcelForUrgentChecks
} from "./network_requests";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { double_to_string, getWarning } from "../../../constants";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import lastCheckIcon from "../../../assets/Icons_waste/boardwhook.png";
import boardwPenIcon from "../../../assets/Icons_waste/boardwPen.png";
import AddInspection from "./NewInspection";
import EditAppliance from "./EditAppliance";
import EditInspection from "./EditInspection";
import { FileExcelOutlined } from "@ant-design/icons";

const { Text } = Typography;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UrgentChecks({ type }) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [update, setUpdate] = useState(false);
  const [editInspection, setEditInspection] = useState(null);
  const [updateApplianceValues, setUpdateApplianceValues] = useState(null);
  const [updateObjValues, setUpdateObjValues] = useState(null);
  const [editAppliance, setEditAppliance] = useState(null);
  const [fields, setFields] = useState({});
  const [newInspection, setNewInspection] = useState(null);
  const history = useHistory();

  useEffect(async () => {
    const condition = await getFields("G01C08D00F02");
    const check_necessary = await getFields("G01C08D00F10");
    const check_necessary_appl = await getFields("G01C09D00F01");
    const check_interval = await getFields("G01C08D00F17");
    setFields({
      ...fields,
      condition: condition,
      check_necessary: check_necessary,
      check_necessary_appl: check_necessary_appl,
      check_interval: check_interval,
    });
  }, []);

  useEffect(async () => {
    setLoad(true);
    const checks = await getUrgentChecks(order, type);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setData(checks);
    setLoad(false);
  }, [order, update, type]);

  useEffect(async () => {
    if (updateObjValues) {
      const checks = await getDetailForAppliance(updateObjValues);
      if (checks?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setEditAppliance(checks);
    }
  }, [updateObjValues]);

  useEffect(async () => {
    if (updateApplianceValues) {
      const check = await getLastInspectionForAppliance(updateApplianceValues);
      if (check?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setEditInspection(check);
    }
  }, [updateApplianceValues]);

  return (
    <>
      <AddInspection
        open={newInspection}
        setOpen={setNewInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      <EditAppliance
        appliance={editAppliance}
        setAppliance={setEditAppliance}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        fields={fields}
        reset={setUpdateObjValues}
      />
      <EditInspection
        open={editInspection}
        setOpen={setEditInspection}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
        reset={setUpdateApplianceValues}
      />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0 15px",
            }}
          >
            <Col
              span={6}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="Position" />
              </Text>
            </Col>
            <Col
              span={6}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="cockpit.Object" />
              </Text>
            </Col>
            <Col
              span={5}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="cockpit.LastCheck" />
              </Text>
            </Col>
            <Col
              span={7}
              style={{
                padding: 0,
              }}
            >
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
                >
                  <Text>
                    <IntlMessages id="cockpit.NextCheck" />

                    {order === "ASC" ? (
                      <ArrowDownOutlined
                        onClick={() => {
                          setOrder("DESC");
                        }}
                        style={{ paddingLeft: "5px" }}
                      />
                    ) : (
                      <ArrowUpOutlined
                        onClick={() => {
                          setOrder("ASC");
                        }}
                        style={{ paddingLeft: "5px" }}
                      />
                    )}
                  </Text>
                  </Col>
                  <Col
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                  >
                    <Popconfirm
                    title="Excel Datei erzeugen?"
                    onConfirm={async () => {
                      const response = await getExcelForUrgentChecks(order, type);
                      if (response?.file) {
                        downloadToLocal(response?.file, response?.filename);
                      }
                    }}
                    okText="Ja"
                    cancelText="Nein"
                    >
                    <Tooltip title="Excel Datei aus der Liste erzeugen">
                      <FileExcelOutlined style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>

          {data?.length === 0 ? <Empty description="Keine überfälligen Prüfungen vorhanden!"/> : null}

          {data?.slice((page - 1) * 10, page * 10)?.map((item) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0 15px",
                }}
              >
                <Col
                  span={6}
                  style={{
                    padding: 0,
                  }}
                >
                  <p style={{ margin: 0, fontSize: "12px" }}>
                    {item?.objPosition}
                  </p>
                </Col>
                <Col
                  span={6}
                  style={{
                    padding: 0,
                    color: "#038fde",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUpdateObjValues(item?.objStkey);
                  }}
                >
                  <p style={{ margin: 0, fontSize: "12px" }}>{item?.objID}</p>
                  <p style={{ margin: 0, fontSize: "12px" }}>
                    {item?.objGroup}
                  </p>
                  <p style={{ margin: 0, fontSize: "12px" }}>{item?.objType}</p>
                  <p style={{ margin: 0, fontSize: "12px" }}>
                    {item?.objKennzeichen}
                  </p>
                </Col>
                <Col
                  span={5}
                  style={{
                    padding: 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#038fde",
                    }}
                  >
                    <img
                      src={lastCheckIcon}
                      style={{ height: "25px", marginRight: "5px" }}
                    ></img>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setUpdateApplianceValues(item?.objStkey);
                      }}
                    >
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {item?.lastCheck
                          ? double_to_string(item?.lastCheck)
                          : ""}{" "}
                      </p>
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {item?.checkPerson}
                      </p>
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {item?.checkIntervall}
                      </p>
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {item?.checkType}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  span={7}
                  style={{
                    padding: 0,
                  }}
                >
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor:
                            getWarning(item?.warn2) > 0
                              ? "red"
                              : getWarning(item?.warn1) < 0
                              ? "green"
                              : "yellow",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div style={{ paddingLeft: "8px" }}>
                        <p style={{ margin: 0, fontSize: "12px" }}>
                          {item?.nextCheck
                            ? double_to_string(item?.nextCheck)
                            : ""}
                        </p>
                        <p style={{ margin: 0, fontSize: "12px" }}>
                          {item?.checkIntervall}
                        </p>
                        <p style={{ margin: 0, fontSize: "12px" }}>
                          {item?.checkType}
                        </p>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={boardwPenIcon}
                        style={{ height: "20px", marginRight: "5px" }}
                      ></img>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          color: "#038fde",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNewInspection({
                            ...item,
                          });
                        }}
                      >
                        <IntlMessages id="cockpit.NewInspection" />
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={data?.length}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
