import { url } from "../../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getFloors = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "3",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 5,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: val, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addFloor = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntries`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: parent,
          stclass: 2,
          storder: 0,
          treepos: 1,
          disptree: 0,
        },
        obj_Data: {
          active: 1,
          group: "1",
          stclass: 3,
          ...values,
          gisid: "",
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editFloors = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "1",
        stclass: 3,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getMachines = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "10",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 5,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: val, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addMachine = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntries`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: parent,
          stclass: 2,
          storder: 0,
          treepos: 1,
          disptree: 0,
        },
        obj_Data: {
          active: 1,
          group: "1",
          stclass: 10,
          ...values,
          gisid: "",
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editMachine = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "1",
        stclass: 10,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
