import { Modal, Input, Form, Menu, Typography } from "antd";
import { useRef, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { editCoordinate } from "./network_requests";

export default function EditAction(props) {
  const { open, setOpen } = props;
  const history = useHistory();
  const ref = useRef();

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    const stringValue = value.toString();
    return parseFloat(stringValue.includes(",") ? stringValue.replace(/,/g, ".") : stringValue);
  };

  return (
    <Modal
      title={"Edit Coordinate"}
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
      }}
      style={{
        maxWidth: "920px",
      }}
    >
      <Form
        name="add action"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{ ...open }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editCoordinate({
            ...open,
            ...values,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setLoad(false);
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item label={"Name"} name="cfeld1" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item label={"Latitude"} name="nfeld1" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>

        <Form.Item
          label={"Longitude"}
          name="nfeld2"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>

        <Form.Item label={"Order"} name="nfeld3" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
