import { Col, Row, Pagination, Typography, Spin } from "antd";
import { useEffect, useState } from "react";
import { getChecks, getFields } from "./network_requests";
import { DownOutlined } from "@ant-design/icons";
import { double_to_string } from "../../../../constants";
import AddCheck from "./AddCheck";
import EditCheck from "./EditCheck";
import IntlMessages from "util/IntlMessages";

export default function Checks(props) {
  const { stkey, action, setAction } = props;
  const { Text } = Typography;
  const [page, setPage] = useState(1);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [checks, setChecks] = useState([]);
  const [fields, setFields] = useState(null);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setAddOpen(action === "addCheck" ? true : false);
  }, [action]);

  useEffect(async () => {
    setLoad(true);
    const checks_ = await getChecks(0, 10, stkey);
    const root = await getFields("G03C02D01F05");
    const stem = await getFields("G03C02D01F04");
    const crown = await getFields("G03C02D01F03");

    setFields({
      root: root,
      stem: stem,
      crown: crown,
    });
    setChecks(checks_?.list ? checks_?.list : []);
    setCount(checks_?.count ? checks_?.count : 0);
    setLoad(false);
  }, [update]);

  const add1year = (string) => {
    const breakDate = string?.split(".");
    breakDate[2] = parseInt(breakDate[2]) + 1;
    return breakDate?.join(".");
  };
  return (
    <>
      <AddCheck
        stkey={stkey}
        open={addOpen}
        setOpen={setAddOpen}
        fields={fields}
        setAction={setAction}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      <EditCheck
        stkey={stkey}
        open={editOpen}
        setOpen={setEditOpen}
        fields={fields}
        setAction={setAction}
        setLoad={setLoad}
        update={update}
        setUpdate={setUpdate}
      />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col xs={12} sm={12} xl={18} style={{ padding: 0 }}>
              <Text>{<IntlMessages id="outdoor.previouschecks" />}</Text>
            </Col>
            <Col
              xs={12}
              sm={12}
              xl={6}
              style={{ padding: 0, justifyContent: "flex-end" }}
            >
              <Text><IntlMessages id="more_actions" /></Text>
            </Col>
          </Row>

          {checks?.map((check) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {double_to_string(check?.nfeld1)} , {<IntlMessages id="outdoor.nextcheck" />}:{" "}
                      {add1year(double_to_string(check?.nfeld1))},{" "}
                      {check?.cfeld2}
                    </Text>
                    <br />
                    <br />
                    <Text
                      style={{
                        color: "#038fde",
                        marginTop: "10px",
                      }}
                    >
                      <IntlMessages id="outdoor.findingsroot" /> : {check?.cfeld5}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: "#038fde",
                        marginTop: "10px",
                      }}
                    >
                      <IntlMessages id="outdoor.findingsstem" /> : {check?.cfeld4}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: "#038fde",
                        marginTop: "10px",
                      }}
                    >
                      <IntlMessages id="outdoor.findingscrown" /> : {check?.cfeld3}
                    </Text>
                  </div>
                </Col>

                <Col>
                  <Text
                    style={{
                      color: "#038fde",
                      paddingRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditOpen(check);
                    }}
                  >
                    <IntlMessages id="edit" />
                  </Text>
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    | <IntlMessages id="more_actions" />
                  </Text>
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 15]}
              style={{
                margin: 0,
              }}
              onChange={(newpage, pageSize_) => {
                setPage(newpage);
                setPageSize(pageSize_);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
