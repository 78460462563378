import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Button,
} from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { addIssue } from "./network_requests";
import MultiSelectPanel from "./MultiSelectPanel";
import { string_to_doubleDT } from "../../../../constants";
import red_icon from "../../../../assets/Icons_waste/task_open.png";
import ResolveIssue from "./ResolveIssue";

export default function AddIssue(props) {
  const { open, setOpen, setPage, latlng, posIssueOptions, typeIssueOptions } =
    props;
  const [nfeld4, setnfeld4] = useState(moment(moment(), "DD.MM.YYYY"));
  const [posIssue, setPosIssue] = useState([]);
  const [typeIssue, setTypeIssue] = useState([]);

  const history = useHistory();
  const ref = useRef();

  return (
    <Modal
      title={
        <>
          <IntlMessages id="issue" /> <IntlMessages id="add" />
        </>
      }
      width="660px"
      visible={open}
      centered
      destroyOnClose
      onCancel={() => {
        setOpen(!open);
      }}
      okText={<IntlMessages id="save" />}
      footer={[
        <Button
          key="extra"
          onClick={() => {
            console.log("hello");
          }}
          disabled
        >
          <IntlMessages id="Fotos" />
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <IntlMessages id="cancel" />
        </Button>,

        <Button
          key="ok"
          type="primary"
          onClick={() => {
            setOpen(!open);
            ref?.current?.submit();
          }}
        >
          <IntlMessages id="save" />
        </Button>,
      ]}
    >
      <Form
        name="add issue"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addIssue({
            ...values,
            nfeld1: latlng.lng,
            nfeld2: latlng.lat,
            typeOfTheIssue: [...typeIssue],
            positionOfTheIssue: [...posIssue],
            nfeld4: nfeld4 ? string_to_doubleDT(nfeld4) : 0,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          setnfeld4(null);
          setTypeIssue([]);
          setPosIssue([]);
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col xs={8}>
            <Form.Item
              label={<IntlMessages id="ErfasserIn" />}
              name="cfeld2"
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label={<IntlMessages id="Erfassungsdatum" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld4}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld4(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label={<IntlMessages id="Route" />}
              name="cfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="issue.PositionOfTheIssue" />}
          style={{ marginBottom: 0 }}
        >
          <MultiSelectPanel
            options={[...posIssueOptions]}
            selectedItems={posIssue}
            setSelectedItems={setPosIssue}
          />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="issue.TypeOfTheIssue" />}
          style={{ marginBottom: 0 }}
        >
          <MultiSelectPanel
            options={[...typeIssueOptions]}
            selectedItems={typeIssue}
            setSelectedItems={setTypeIssue}
          />
        </Form.Item>

        <Row>
          <Col span={18} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="street" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="No." />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld5"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>

        <Row
          style={{
            margin: "12px 0 0 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col xs={18} style={{ display: "flex", alignItems: "center" }}>
            <img src={red_icon} />
            <Typography.Text style={{ marginLeft: "10px" }}>
              <IntlMessages id="issue.IssueNotYetResolved" />
            </Typography.Text>
          </Col>
          <Col
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button disabled style={{ margin: 0 }}>
              <IntlMessages id="issue.ResolveIssue" />
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
