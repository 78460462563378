import { Form, Row, Typography, Col, Checkbox, Button, message } from "antd";
import { useEffect, useState } from "react";
import { editMultipleRequirements } from "../networkrequests";
import IntlMessages from "util/IntlMessages";

const success = () => {
  message.success("Checkliste wurde gespeichert!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

export default function Requirements(props) {
  const { reqs, selectedReqs, parent } = props;
  const [checkedReqs, setCheckedReqs] = useState(null);

  useEffect(() => {
    if (selectedReqs?.ercList) {
      var tempReqs = {};

      selectedReqs?.ercList?.map((category) => {
        category?.erList?.map((req) => {
          tempReqs[req?.detkey] = {
            cfeld1: category?.category,
            cfeld2: req?.requirementName,
            nfeld3: req?.reqIsSelected,
          };
        });
      });

      setCheckedReqs({ ...tempReqs });
    }
  }, [selectedReqs]);

  return (
    <Row
      style={{
        marginTop: "15px",
        borderRadius: "8px",
        border: "2px solid #ececec",
        padding: "20px",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Typography.Text
          style={{ fontSize: "16px", textTransform: "uppercase" }}
        >
          
          {<IntlMessages id="events.chooseRequirements" />}
        </Typography.Text>
      </Row>
      {checkedReqs ? (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={async (values) => {
            var payload = [];
            var keys = Object.keys(checkedReqs);

            keys?.map((req) => {
              payload.push({
                ...{
                  detTable__: "cldetail_g25",
                  detkey: req,
                  active: 1,
                  stkey: parent,
                  stclass: 1,
                  detclass: 2,
                  gisid: null,
                  cfeld3: null,
                  cfeld4: null,
                  cfeld5: null,
                  cfeld6: null,
                  cfeld7: null,
                  cfeld8: null,
                  cfeld9: null,
                  cfeld10: null,
                  cfeld11: null,
                  cfeld12: null,
                  cfeld13: null,
                  cfeld14: null,
                  cfeld15: null,
                  cfeld16: null,
                  cfeld17: null,
                  cfeld18: null,
                  cfeld19: null,
                  cfeld20: null,
                  nfeld1: 0.0,
                  nfeld2: 0.0,
                  nfeld4: null,
                  nfeld5: null,
                  nfeld6: null,
                  nfeld7: null,
                  nfeld8: null,
                  nfeld9: null,
                  nfeld10: null,
                  ...checkedReqs[req],
                },
              });
            });
            const response = await editMultipleRequirements([...payload]);

            if (response?.success) {
              success();
            } else {
              error();
            }
          }}
          autoComplete="off"
          style={{ width: "100%", marginTop: "15px" }}
        >
          <Row style={{ width: "100%", padding: "0 10px" }}>
            {reqs?.ercList?.map((category) => {
              return (
                <Col
                  xs={8}
                  style={{
                    margin: "15px 0",
                  }}
                >
                  <Row style={{ width: "100%" }}>
                    <Typography.Text
                      style={{
                        fontSize: "14px",
                        textTransform: "uppercase",
                        marginBottom: "15px",
                      }}
                    >
                      {category?.category}
                    </Typography.Text>
                  </Row>
                  {category?.erList?.map((req) => {
                    return (
                      <Row style={{ width: "100%" }}>
                        <Form.Item
                          name={req?.detkey}
                          labelCol={{ span: 0 }}
                          wrapperCol={{ span: 24 }}
                          style={{ width: "100%", height: "10px" }}
                        >
                          <Row>
                            <Col xs={3}>
                              <Checkbox
                                defaultChecked={
                                  checkedReqs[req?.detkey]?.nfeld3
                                }
                                onChange={(event) => {
                                  checkedReqs[req?.detkey] = {
                                    cfeld1: category?.category,
                                    cfeld2: req?.requirementName,
                                    nfeld3: event?.target?.checked ? 1 : 0,
                                  };
                                }}
                              />
                            </Col>

                            <Col xs={21}>
                              <Typography.Text>
                                {req?.requirementName}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Row>
                    );
                  })}
                </Col>
              );
              // return catagory?.map((req) => {
              //   return (

              //   );
              // });
            })}
          </Row>

          <Form.Item wrapperCol={{ offset: 21, span: 3 }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {<IntlMessages id="events.save" />}
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Row>
  );
}
