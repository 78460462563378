import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Popover,
  Popconfirm,
} from "antd";
import { deleteUser, getUsers, getUserGroups } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import AddUser from "./addUser";
import EditUser from "./editUser";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function User(props) {
  const [User, setUser] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(async () => {
    setLoad(true);
    const User_ = await getUsers((page - 1) * 10);

    if (User_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    const groups = await getUserGroups();
    setUserGroups(groups);
    setUser(User_ ? User_ : []);
    setCount(User_?.length ? User_?.length : 0);
    setLoad(false);
  }, [page, updateData]);

  return (
    <>
      <AddUser
        open={addUser}
        setOpen={setAddUser}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        groups={userGroups}
      />
      <EditUser
        user={editUser}
        setUser={setEditUser}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        groups={userGroups}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <IntlMessages id="user.title" />
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text> User</Text>
            </Col>
            <Col xs={12} sm={12} xl={6}>
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {User?.map((user) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditUser(user);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {user?.username}
                  </Text>
                </Col>

                <Col>
                  <Text
                    style={{
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpen(user.userkey);
                    }}
                  >
                    <IntlMessages id="more_actions" />
                    {user.userkey === open ? (
                      <Popover
                        content={
                          <Popconfirm
                            placement="bottomLeft"
                            title={"Do you really want to delete the User?"}
                            onConfirm={async () => {
                              setOpen(!open);
                              await deleteUser(open);
                              setUpdateData(!updateData);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Text
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              Delete
                            </Text>
                          </Popconfirm>
                        }
                        trigger="click"
                        visible={open}
                        onVisibleChange={() => {
                          setOpen(!open);
                        }}
                      ></Popover>
                    ) : null}
                  </Text>
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddUser(!addUser);
              }}
            >
              <IntlMessages id="user.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
