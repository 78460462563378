import { Row, Typography, Col, Spin, Empty } from "antd";
import { getCatagories } from "./network_requests";
import { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import vehicle from "../../../assets/Icons_waste/vehicle.png";
import info from "../../../assets/Icons_waste/info_blue.png";

const { Text } = Typography;

export default function Catagories(props) {
  const { showStates, setShowStates } = props;
  const [Catagories, setCatagories] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const Catagories_ = await getCatagories();
    if (Catagories_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setCatagories(Catagories_ ? Catagories_ : []);
    setLoad(false);
  }, []);

  return (
    <>
      <>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
            }}
          >
            Übersicht für {window.localStorage.getItem("uname")}
          </Typography>
        </div>

        <div
          style={{
            backgroundColor: "#f3f7ff",
            left: 0,
            padding: "20px",
            width: "100%",
            color: "gray",
            textTransform: "uppercase",
          }}
        >
          Kategorien
        </div>
      </>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          {Catagories?.length === 0 ? <Empty /> : null}

          {Catagories?.map((Category) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  // borderBottom: "1px solid #eaeaea",
                  padding: "20px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={18}
                  style={{
                    padding: 0,
                  }}
                  onClick={() => {
                    setShowStates({
                      ...showStates,
                      page: 2,
                      name: Category?.title,
                      key: Category?.categoryKey,
                    });
                  }}
                >
                  <Text>
                    <img
                      src={
                        Category.icon === "info.png"
                          ? info
                          : Category.icon === "vehicle.png"
                          ? vehicle
                          : null
                      }
                      height={"22px"}
                      style={{ marginRight: "10px" }}
                    />
                    {Category?.title}
                  </Text>
                </Col>

                <Col
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <RightOutlined
                    size="large"
                    onClick={() => {
                      setShowStates({
                        ...showStates,
                        page: 2,
                        name: Category?.title,
                        key: Category?.categoryKey,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
        </>
      ) : null}
    </>
  );
}
