import { Input, Form, Typography, Button, Row } from "antd";
import { useRef } from "react";
import { editCategory } from "./network_requests";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";

export default function EditCategory(props) {
  const { category, setCategory } = props;

  const ref = useRef();
  const history = useHistory();

  return (
    <div
      style={{
        backgroundColor: "#f3f7ff",
        height: "87vh",
      }}
    >
      <>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#038fde",
              cursor: "pointer",
            }}
            onClick={() => {
              setCategory(null);
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "22px", fontWeight: "bold" }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Edit Category
          </Typography>
        </div>
      </>
      <Form
        name="edit Category"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          padding: "10px 30px",
        }}
        initialValues={{
          ...category,
        }}
        onFinish={async (values) => {
          if (values?.cfeld2) {
            setCategory(null);
            props.setLoad(true);
            const response = await editCategory({
              ...category,
              ...values,
            });

            if (response.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }

            props.setUpdate(!props.update);
          }
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item label={"Title"} name="cfeld2" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Row
          style={{
            marginTop: "16px",
            position: "absolute",
            bottom: "50px",
            display: "flex",
            justifyContent: "center",
            width: "85%",
          }}
        >
          <Button
            type="primary"
            style={{
              width: "150px",
              marginRight: "20px",
            }}
            onClick={async () => {
              props.setLoad(true);
              const response = await editCategory({
                ...category,
                active: 0,
              });

              if (response.status === 401) {
                localStorage.removeItem("user_id");
                history.go(0);
              }

              props.setUpdate(!props.update);
            }}
          >
            Remove
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "150px",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
