import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { addComment, deleteComment, getComments } from "./network_requests";
import moment from "moment";

import { doubleDT_to_string, string_to_doubleDT } from "../../../constants";

import { useHistory } from "react-router-dom";

const success = () => {
  message.success("Comment added successfully.");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function Comments(props) {
  const { stkey } = props;
  const [comments, setComments] = useState(null);
  const [text, setText] = useState("");
  const [update, setUpdate] = useState(false);
  const [push, setPush] = useState(false);
  const [email, setEmail] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const data_ = await getComments(stkey);
      if (data_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setComments({ ...data_ });
    })();
  }, [update]);

  return (
    <Row
      style={{
        borderRadius: "8px",
        border: "2px solid #ececec",
        padding: "20px",
        width: "100%",
        marginTop: "20px",
        backgroundColor: "#fff",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Typography.Text
          style={{ fontSize: "16px", textTransform: "uppercase" }}
        >
          Please explain your opinion on dissens
        </Typography.Text>
      </Row>
      <Row
        style={{
          width: "100%",
          margin: "20px 0",
          padding: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col xs={1} style={{ padding: 0 }}>
          <Avatar size={24} icon={<UserOutlined />} />
        </Col>
        <Col xs={20}>
          <Input
            allowClear
            value={text}
            onChange={(e) => {
              setText(e?.target?.value);
            }}
          />
        </Col>
        <Col xs={3} style={{ padding: 0, margin: 0, paddingLeft: "10px" }}>
          <Button
            type="primary"
            style={{ margin: 0 }}
            onClick={async () => {
              const time = moment(moment()).format("DD.MM.YYYY HH:mm");

              const response = await addComment(
                {
                  cfeld1: window.localStorage.getItem("uname"),
                  cfeld2: text,
                  nfeld1: string_to_doubleDT(time),
                },
                comments?.list[0]?.stkey
              );
              if (response?.success) {
                success();
                setText("");
                setUpdate(!update);
              } else {
                error();
              }
            }}
          >
            senden
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
        }}
        align="middle"
      >
        {comments?.list?.map((comment) => {
          return (
            <Row style={{ width: "100%", marginTop: "10px" }}>
              <Col xs={1}>
                <Avatar size={24} icon={<UserOutlined />} />
              </Col>
              <Col xs={21}>
                <Row style={{ width: "100%", padding: 0, margin: 0 }}>
                  <Typography.Text style={{ fontWeight: "bold" }}>
                    {doubleDT_to_string(comment?.nfeld1)}, {comment?.cfeld1}:
                  </Typography.Text>
                </Row>

                <Row style={{ width: "100%", padding: 0, margin: 0 }}>
                  <Typography.Text
                    style={{ paddingLeft: "5px", textTransform: "none" }}
                  >
                    {comment?.cfeld2}{" "}
                    <DeleteOutlined
                      onClick={async () => {
                        await deleteComment({ ...comment });
                        setUpdate(!update);
                      }}
                    />
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Row>
    </Row>
  );
}
