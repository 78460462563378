import { Col, Collapse, List, Modal, Row, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ServiceActions from "./MoreActions";
import {
  deleteAppointmentTemplates,
  deleteInfoTemplates,
  deleteMailSMSTemplates,
} from "./network_requests";
import ShowAppointments from "./ShowAppointments";
import { CalendarOutlined } from "@ant-design/icons";
import { useState } from "react";
import mailIcon from "../../../assets/Icons_waste/email.png";
import smsIcon from "../../../assets/Icons_waste/sms.png";
import infoIcon from "../../../assets/Icons_waste/info.png";
import "./header.css";

const { Panel } = Collapse;
export default function TemplateMain({
  categories,
  setInsertTemplate,
  templates,
  setEditTemplate,
  update,
  setUpdate,
  setType,
}) {
  const history = useHistory();
  let { page, subkey } = useParams();
  const [openModal, setOpenModal] = useState(null);
  const action = (template, link, isEdit) => {
    setEditTemplate({ ...template });
    setType(isEdit ? "edit" : "send");
    history.push(link);
  };

  const deleteAppointment = async (key) => {
    const response_ = await deleteAppointmentTemplates(key);
    if (response_?.status === 401) {
      localStorage.removeItem("company_id");
      history.go(0);
    } else if (response_?.success === "success") {
      setUpdate(!update);
    }
  };

  const deleteMailSMS = async (key) => {
    const response_ = await deleteMailSMSTemplates(key);
    if (response_?.status === 401) {
      localStorage.removeItem("company_id");
      history.go(0);
    } else if (response_?.success === "success") {
      setUpdate(!update);
    }
  };

  const deleteInfo = async (key) => {
    const response_ = await deleteInfoTemplates(key);
    if (response_?.status === 401) {
      localStorage.removeItem("company_id");
      history.go(0);
    } else if (response_?.success === "success") {
      setUpdate(!update);
    }
  };

  return (
    <>
      <ShowAppointments
        appointments={templates?.appointmentList ?? []}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <Collapse
        style={{
          width: "100%",
          borderRadius: "5px",
        }}
        defaultActiveKey={[1, 2, 3]}
      >
        {categories?.map((category, index) => {
          return (
            <Panel
              header={
                <>
                  <Row style={{ width: "100%" }}>
                    {/* <Col xs={1} /> */}
                    <Col
                      xs={22}
                      style={{
                        // width: window.matchMedia("(min-width: 1756px)")?.matches
                        //   ? "46vw"
                        //   : "40vw",
                        paddingLeft: "35px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {category}
                    </Col>
                    <Col xs={2}>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "20px",
                          width: "30px",
                          color: "white",
                          backgroundColor: "#1890ff",
                          borderRadius: "6px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setEditTemplate(null);
                          setType("insert");
                          history.push(
                            `/main/service_areas/${
                              index === 0
                                ? "appointment"
                                : index === 1
                                ? "mailSMS"
                                : "info"
                            }/${subkey}`
                          );
                        }}
                      >
                        +
                      </div>
                    </Col>
                  </Row>
                </>
              }
              key={index + 1}
              style={{
                borderRadius: 0,

                borderBottomLeftRadius:
                  index === categories?.length - 1 ? "8px" : 0,
                borderBottomRightRadius:
                  index === categories?.length - 1 ? "8px" : 0,
              }}
            >
              <List>
                {index === 0
                  ? templates?.appointmentList?.map((appointment) => {
                      {
                        return (
                          <List.Item style={{ padding: "5px" }}>
                            <Row style={{ width: "100%" }}>
                              <Col xs={18}>
                                <Typography
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    action(
                                      { ...appointment },
                                      `/main/service_areas/appointment/${subkey}`
                                    );
                                  }}
                                >
                                  <img
                                    src={mailIcon}
                                    style={{ height: "22px" }}
                                  />{" "}
                                  <img
                                    src={smsIcon}
                                    style={{ height: "22px" }}
                                  />{" "}
                                  <CalendarOutlined /> {appointment?.title}
                                </Typography>
                              </Col>
                              <Col
                                xs={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <ServiceActions
                                  template={{ ...appointment }}
                                  action={action}
                                  link={`/main/service_areas/appointment/${subkey}`}
                                  deleteTemplate={deleteAppointment}
                                  setOpenModal={setOpenModal}
                                  openModal={openModal}
                                  showModal={true}
                                />
                              </Col>
                            </Row>
                          </List.Item>
                        );
                      }
                    })
                  : index === 1
                  ? templates?.mailsmsList?.map((mailSMS) => {
                      {
                        return (
                          <List.Item style={{ padding: "5px" }}>
                            <Row style={{ width: "100%" }}>
                              <Col xs={18}>
                                <Typography
                                  onClick={() => {
                                    action(
                                      { ...mailSMS },
                                      `/main/service_areas/mailSMS/${subkey}`
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={mailIcon}
                                    style={{ height: "22px" }}
                                  />{" "}
                                  <img
                                    src={smsIcon}
                                    style={{ height: "22px" }}
                                  />{" "}
                                  {mailSMS?.title}
                                </Typography>
                              </Col>
                              <Col
                                xs={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <ServiceActions
                                  template={{ ...mailSMS }}
                                  action={action}
                                  link={`/main/service_areas/mailSMS/${subkey}`}
                                  deleteTemplate={deleteMailSMS}
                                />
                              </Col>
                            </Row>
                          </List.Item>
                        );
                      }
                    })
                  : templates?.infoList?.map((info) => {
                      {
                        return (
                          <List.Item style={{ padding: "5px" }}>
                            <Row style={{ width: "100%" }}>
                              <Col xs={18}>
                                <Typography
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    action(
                                      { ...info },
                                      `/main/service_areas/info/${subkey}`
                                    );
                                  }}
                                >
                                  <img
                                    src={infoIcon}
                                    style={{ height: "22px" }}
                                  />{" "}
                                  {info?.title}
                                </Typography>
                              </Col>
                              <Col
                                xs={6}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <ServiceActions
                                  template={{ ...info }}
                                  action={action}
                                  link={`/main/service_areas/info/${subkey}`}
                                  deleteTemplate={deleteInfo}
                                />
                              </Col>
                            </Row>
                          </List.Item>
                        );
                      }
                    })}
              </List>
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
}
