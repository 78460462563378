import { url } from "../../../../constants";
import axios from "axios";

const latDegree = () => {
  return (0.1 / 6378) * (180 / Math.PI);
};
const lngDegree = (lat) => {
  return ((0.1 / 6378) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180);
};

export const searchByName = async (start, end, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g06",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "cfeld1", val: search, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchByStreet = async (start, end, search) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g06",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "cfeld3", val: search, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchByCenter = async (start, end, lat, lng) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g06",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        nfields: [
          {
            fieldNo: "nfeld2",
            fromVal: lat - latDegree(),
            toVal: lat + latDegree(),
            opVal: "between",
          },
          {
            fieldNo: "nfeld1",
            fromVal: lng - lngDegree(lng),
            toVal: lng + lngDegree(lng),
            opVal: "between",
          },
        ],
        cfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOtherWaterConnectors = async (start, end) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g06",
        stClass: "3",
        type: "none",
        limitFrom: start,
        limitTo: end,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addOtherWaterConnector = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        active: 1,
        group: "6",
        stclass: 3,
        stkey: "",
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editOtherWaterConnector = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "6",
        stclass: 3,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
