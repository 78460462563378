
import { Button } from "antd";
import { useState, useEffect } from "react";
import Buildings from "./components/Buildings";
import Estates from "./components/Estates";
import Floors from "./components/Floors";
import Room from "./components/Room";
import RoomChart from "./components/RoomChart";
import IntlMessages from "util/IntlMessages";

export const footerContent = (emptyFooter, handOkClick, handleCancelClick) => {

  return emptyFooter == true ? null :
  [
    <Button key="back" onClick={() => { handleCancelClick(); }}><IntlMessages id="cancel" /></Button>,
    <Button key="submit" type="primary" onClick={ () => { handOkClick(); }}><IntlMessages id="save" /></Button>,
  ] ;
};

export default function Overview() {
  const [mainPage, setMainPage] = useState(1);
  const [mainStates, setMainStates] = useState({});

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "20px",
        minHeight: "70vh",
        padding: "20px 10px",
        marginBottom: "20px",
      }}
    >
      {mainPage === 1 ? (
        <Estates
          setMainPage={setMainPage}
          mainStates={mainStates}
          setMainStates={setMainStates}
        />
      ) : mainPage === 2 ? (
        <Buildings
          setMainPage={setMainPage}
          mainStates={mainStates}
          setMainStates={setMainStates}
        />
      ) : mainPage === 3 ? (
        <Floors
          setMainPage={setMainPage}
          mainStates={mainStates}
          setMainStates={setMainStates}
        />
      ) : mainPage === 4 ? (
        <Room
          setMainPage={setMainPage}
          mainStates={mainStates}
          setMainStates={setMainStates}
        />
      ) : mainPage === 5 ? (
        <RoomChart
          setMainPage={setMainPage}
          mainStates={mainStates}
          setMainStates={setMainStates}
        />
      ) : null}
    </div>
  );
}
