import {
  Row,
  Col,
  Spin,
  Card, 
  Collapse,  
} from "antd";
import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { callsearch } from "./network_requests";
import ShowData from "./showData";
import ShowPdf from "./showPdf";
import CatSwitch from "./catSwitch";
import SearchResultRow from "./searchResultRow";
import { url } from "constants/index";
import axios from "axios";

import EditEstates from "../overview-indoor/overview/components/Estates/editEstates";
import EditBuildings from "../overview-indoor/overview/components/Buildings/editBuildings";
import EditFloors from "../overview-indoor/overview/components/Floors/editFloor";
import EditMachine from "../overview-indoor/overview/components/Floors/editMachine";
import EditRoom from "../overview-indoor/overview/components/Room/editRoom";
import EditFacilities from "../overview-indoor/overview/components/RoomChart/editFacility";
import EditAppliance from "../overview-indoor/overview/components/RoomChart/editAppliance";
import EditITAppliance from "../overview-indoor/overview/components/RoomChart/editITAppliance";
import EditMedAppliance from "../overview-indoor/overview/components/RoomChart/editMedAppliance";
import EditVehicles from "../vehicle-overview/vehicles/editVehicles";

import mime from "mime-types"
import { editVehicle } from "../vehicle-overview/vehicles/network_requests";

export default function SearchResult(props) {
  const [User, setUser] = useState([]);
  const [SearchResultt, setSearchResult] = useState([]);
  const [showData, setShowData] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [count, setCount] = useState(0);
  const [results, setResults] = useState(0);  
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const Panel = Collapse.Panel;
  const [searchString, setSearchString] = useState(history?.location?.search.substring(1));
  const [catSwitches, setCatSwitches] = useState([]);
  const [catPanels, setCatPanels] = useState([]);

  const [editEstates, setEditEstates] = useState(null);
  const [editBuildings, setEditBuildings] = useState(null);
  const [editFloors, setEditFloors] = useState(null);
  const [editMachine, setEditMachine] = useState(null);
  const [editRoom, setEditRoom] = useState(null);
  const [editFacilities, setEditFacilities] = useState(null);
  const [editAppliance, setEditAppliance] = useState(null);
  const [editMedAppliance, setEditMedAppliance] = useState(null);
  const [editVehicles, setEditVehicles] = useState(null);
  const [editITAppliance, setEditITAppliance] = useState(null);

  const [fileId, setFileId] = useState(0);

  useEffect(async () => {

    setLoad(true);

    const SearchResult_ = await callsearch(history?.location?.search.substring(1));    
    if (SearchResult_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }    

    const User_ = SearchResult_?.categories;

    setSearchResult(SearchResult_ ? SearchResult_ : []);
    setUser(User_ ? User_ : []);
    
    var resultCnt = 0;
    var catCnt = 0;
    User_?.map((user) => {
      if (user?.commonClasses?.length > 0) {
        resultCnt += user?.commonClasses?.length;
        catCnt++;
      }      
    })
    setResults(resultCnt);
    setCount(catCnt);

    var cnt = 0;
    if (SearchResult_?.bestMatch != null && SearchResult_?.bestMatch !== undefined) {
      cnt++;
    }
    if (User_?.length > 0) {
      cnt += User_?.length;
    }    
    var tmpArr = Array(cnt).fill(false);
    
    if (SearchResult_?.bestMatch != null && SearchResult_?.bestMatch !== undefined) {
      tmpArr[User_?.length] = true;
      catPanels.push(User_?.length.toString());
    }
    setCatSwitches(tmpArr);
    
    setLoad(false);
    
  }, [history]);
  
const css = `.ant-collapse > .ant-collapse-item > .ant-collapse-header  {
  position: relative;
  padding: 12px 16px;  
  color: #535353;
  line-height: 1.3;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  padding-left: 40px !important;
}`;

function collapseCallback(activatedPanels) {

  let newCatSwitches = catSwitches.slice();
  for (let i = 0; i < newCatSwitches.length; i++) {
    newCatSwitches[i] = activatedPanels.includes(i.toString());
  }
  setCatSwitches(newCatSwitches);

  let newCatPanels = catPanels.slice();
  newCatPanels.splice(0, newCatPanels.length);
  newCatPanels = activatedPanels;
  setCatPanels(newCatPanels);
};

function handleClick(index, checkState) {
  
  let newCatSwitches = catSwitches.slice();
  newCatSwitches[index] = checkState;
  setCatSwitches(newCatSwitches);

  let newCatPanels = catPanels.slice();
  if (checkState && !newCatPanels.includes(index.toString())) {
    newCatPanels.push(index.toString());
  } else if (!checkState && newCatPanels.includes(index.toString())) {
    newCatPanels = newCatPanels.filter(item => item !== index.toString())
  }
  setCatPanels(newCatPanels);
}

function openPdfDialog(sr) {
  
  if (sr.cfeld2.toLowerCase().endsWith('.pdf')) {
    setFileId(sr.nfeld1);
    setShowPdf(true);
  } else {
    openFileInNewTab(sr.nfeld1, sr.cfeld1);    
  }      
}

  const openFileInNewTab = async (id, fileName) => {
    try {
      const token = window.localStorage.getItem("user_id");
      const response = await axios.get(
        `${url}search/getFile/` + id,
        {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      var type = mime.lookup(fileName) || 'application/octet-stream';
      var file = new Blob([response.data], {type: type} );
      var fileurl = window.URL.createObjectURL(file);

      if (type == "text/plain") {
        window.open(fileurl, "_blank");
      } else {
        var a = document.createElement("a");      
        a.href = fileurl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
      
      window.URL.revokeObjectURL(fileurl);

    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

/**
1 estate
2 buildings
3 floors
4 rooms
7 machines
8 med appliances
9 appliances
 */
function openDialog(sr) {

  if (sr.docType == "detail") {
    openDetailDialog(sr);
  } else {
    openClassDialog(sr);    
  }
}

function openClassDialog(sr) {

  var stClass = sr.stclass;
  if (sr.group == 28) {
    switch(stClass) {
      case 1:
        stClass = 8;
        break;
      case 2:
        stClass = 9;
        break;
    }
  }

  switch(stClass) {
    case 0:
      openPdfDialog(sr);
      break;
    case 1:
      setEditEstates(sr);
      break;
    case 2:
      setEditBuildings(sr);
      break;
    case 3:
      setEditFloors(sr);
      break;
    case 4:
      setEditRoom(sr);
      break;
    case 7,10,11,12:
      setEditMachine(sr);
      break;
    case 8:
      setEditMedAppliance(sr);
      break;
    case 9:
      setEditAppliance(sr);
      break;
    case 15:      
      setEditVehicles(sr);
      break;
    default:
      setShowData(sr);
      break;
  }
}

function openDetailDialog(sr) {

  switch(sr.stclass) {
    case 4:
      switch (sr.detclass)
      {
        case 1:
          setEditFacilities(sr);
          break;
        case 2:
          setEditITAppliance(sr);
          break;
        default:
          setShowData(sr);
          break;
      }      
      break;
    default:
      setShowData(sr);
      break;
  }
}

  return (
    <>
      <ShowData
        user={showData}
        setUser={setShowData}
        setLoad={setLoad}
      />

      { showPdf &&
      <ShowPdf
        searchQuery={searchString}
        showPdf={showPdf}
        setShowPdf={setShowPdf}
        fileId={fileId}        
      /> }

      <EditEstates
        estate={editEstates}
        setEstate={setEditEstates}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        emptyFooter={true}
      />
      <EditBuildings
        building={editBuildings}
        setBuilding={setEditBuildings}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        emptyFooter={true}
      />
      <EditFloors
        floor={editFloors}
        setFloor={setEditFloors}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        emptyFooter={true}
      />
        <EditMachine
        machine={editMachine}
        setMachine={setEditMachine}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        //fields={fields}
        emptyFooter={true}
      />
      <EditRoom
        room={editRoom}
        setRoom={setEditRoom}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        //fields={fields}
        emptyFooter={true}
      />
      <EditFacilities
        facility={editFacilities}
        setFacility={setEditFacilities}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={null}
        //fields={fields}
        emptyFooter={true}
      />
      <EditAppliance
        appliance={editAppliance}
        setAppliance={setEditAppliance}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        //fields={fields}
        emptyFooter={true}
      />
      <EditITAppliance
        appliance={editITAppliance}
        setAppliance={setEditITAppliance}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={null}
        //fields={fields}
        emptyFooter={true}
      />
      <EditMedAppliance
        appliance={editMedAppliance}
        setAppliance={setEditMedAppliance}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        //fields={fields}
        emptyFooter={true}
      />
      <EditVehicles
        vehicle={editVehicles}
        setVehicle={setEditVehicles}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        //fields={fields}
        emptyFooter={true}
      />

        <style>{css}</style>
      
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      ) : null}
      {!load ? (
        <> 
          <Row>
            <Col lg={18} md={18} sm={24} xs={24}>            
            <Card title={"Suchergebnisse für \'" + searchString+ "\': " + results + " Treffer in " + count + " Kategorie" + (count != 1 ? "n" : "") + " in: " + (SearchResultt?.neededTime == undefined ? 0 : SearchResultt?.neededTime) + " s"}  className="gx-card">   
          <Collapse activeKey={catPanels} acordion={true} onChange={collapseCallback}>
          {(SearchResultt?.bestMatch != null && SearchResultt?.bestMatch !== undefined) &&
          <Panel header="Best Match" key={User?.length} style={{ margin: "0" }}>
              <SearchResultRow
                numberOfItems={1}
                catType={SearchResultt?.bestMatch?.stclass == 0 ? "F" : "D"}
                innerobj={SearchResultt?.bestMatch}
                onClick={openDialog}
                bestMatch={true} />
            </Panel> }
          {User?.map((user, index) => {
            return (
              (user?.commonClasses?.length > 0) &&
              <Panel header={user?.name + " - " + user?.commonClasses?.length + " Treffer"} forceRender={true} key={index} style={{ margin: "0" }}>
                { user?.commonClasses?.map((innerobj) => {
            return (
              <SearchResultRow
                numberOfItems={user?.commonClasses?.length}
                catType={user?.typ}
                innerobj={innerobj}
                onClick={openDialog} />
            );}) }
              </Panel>
            );
          })}
          </Collapse>
          </Card>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
          <Card title="Kategorien" className="gx-card">
            { (SearchResultt?.bestMatch != null && SearchResultt?.bestMatch !== undefined) &&
          <div className="gx-mb-3">          
                       <CatSwitch categoryName="Best match"
                         catDisabled={(SearchResultt?.bestMatch == null || SearchResultt?.bestMatch === undefined)} 
                         catChecked={catSwitches[User?.length]}
                         onChange={handleClick}
                         catIndex={User?.length} />           
          </div>
}
           { User?.map((user, index) => {
            return (<div className="gx-mb-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <CatSwitch categoryName={user?.name}
                         catDisabled={user?.commonClasses?.length == 0} 
                         catChecked={catSwitches[index]}
                         onChange={handleClick}
                         catIndex={index} /></div>
            ); } ) }

          </Card>
          </Col>
        </Row>
          {/* <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >            
          </Row>           */}
        </>        
      ) : null}
    </>
  );
}
