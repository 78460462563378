import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getBuildings } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddBuildings from "./addBuildings";
import EditBuildings from "./editBuildings";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";
import buildingIcon from "../../assets/building.png";

const { Text } = Typography;

export default function Buildings(props) {
  const { setMainPage, mainStates, setMainStates } = props;
  const [buildings, setBuildings] = useState([]);
  const [addBuildings, setAddBuildings] = useState(false);
  const [editBuildings, setEditBuildings] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const buildings_ = await getBuildings((page - 1) * 10, mainStates?.estate);
    if (buildings_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setBuildings(buildings_?.list ? buildings_?.list : []);
    setCount(buildings_?.count ? buildings_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  return (
    <>
      <AddBuildings
        open={addBuildings}
        setOpen={setAddBuildings}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={mainStates?.estate}
      />
      <EditBuildings
        building={editBuildings}
        setBuilding={setEditBuildings}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />
      <Text
        style={{
          color: "#038fde",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(1);
          }}
        >
          {mainStates?.estateName ? (
            mainStates?.estateName
          ) : (
            <IntlMessages id="estate.title" />
          )}
        </span>
        {
          <>
            {" -> "}
            <IntlMessages id="building.title" />
          </>
        }
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={12}
              sm={6}
              xl={7}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="building.title" />
              </Text>
            </Col>
            <Col
              xs={0}
              sm={8}
              xl={11}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="address" />
              </Text>
            </Col>
            <Col xs={12} sm={10} xl={6}>
              <Text>
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {buildings?.length === 0 ? <Empty description="Keine Gebäude vorhanden!"/> : null}

          {buildings?.map((building) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={6}
                  xl={7}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditBuildings(building);
                  }}
                >
                  <img
                  src={
                      buildingIcon
                  }
                  style={{ height: "20px", marginRight: "8px" }}
                />
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {building?.cfeld1}
                  </Text>
                </Col>
                <Col
                  xs={0}
                  sm={8}
                  xl={11}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {building?.cfeld2} {building?.cfeld3}
                  </Text>
                </Col>

                <Col>
                  <MoreActions state={building} />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
                <Col>
                  <RightSquareOutlined
                    style={{
                      color: "#038fde",
                      paddingLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      setMainPage(3);
                      setMainStates({
                        ...mainStates,
                        building: building?.stkey,
                        buildingName: building?.cfeld1,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddBuildings(!addBuildings);
              }}
            >
              <IntlMessages id="building.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
