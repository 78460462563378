import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getContent, deleteContent } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../extraComponents/MoreActions";

const { Text } = Typography;

export default function CatagoriesStep1(props) {
  const { showStates, setShowStates } = props;
  const [Contents, setContents] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);

    const Contents_ = await getContent(showStates?.key);
    if (Contents_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setContents(Contents_ ? Contents_ : []);
    setLoad(false);
  }, [updateData]);

  return (
    <>
      <Text
        style={{
          color: "#038fde",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowStates({
            page: 1,
          });
        }}
      >
        <IntlMessages id="Categories" /> {" -> "} {showStates?.name}
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
            align="middle"
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="Inhalte" />
              </Text>
            </Col>
            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="aktionen" />
              </Text>
            </Col>

            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              {/* <TypeSelecter type={type} setType={setType} /> */}
            </Col>
          </Row>

          {Contents?.length === 0 ? <Empty /> : null}

          {Contents?.map((Content) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowStates({
                      ...showStates,
                      page: 3,
                      name1: Content?.title,
                      key1: Content?.contentKey,
                      content: Content?.content,
                    });
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {Content?.title}
                  </Text>
                </Col>

                <Col>
                  <MoreActions
                    state={Content}
                    noPic={true}
                    onDelete={async (data) => {
                      const response = await deleteContent({ ...data });
                      setUpdateData(!updateData);
                      return response;
                    }}
                    update={updateData}
                    setUpdate={setUpdateData}
                  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setShowStates({
                  ...showStates,
                  page: 3,
                  name1: "Add Content",
                });
              }}
            >
              <IntlMessages id="neuer Inhalt" />
            </Button>
          </Row>
        </>
      ) : null}
    </>
  );
}
