import CreateEvent from "./create";
import EditEvent from "./edit";

export default function Event(props) {
  const {
    events,
    setEvents,
    editEvent,
    setEditEvent,
    updateEvents,
    setUpdateEvents,
  } = props;
  const edit = editEvent || window.localStorage.getItem("editing");

  return (
    <>
      {edit ? (
        <EditEvent
          events={events}
          setEvents={setEvents}
          editEvent={editEvent}
          setEditEvent={setEditEvent}
          updateEvents={updateEvents}
          setUpdateEvents={setUpdateEvents}
        />
      ) : (
        <CreateEvent
          edit={editEvent}
          setEditEvent={setEditEvent}
          updateEvents={updateEvents}
          setUpdateEvents={setUpdateEvents}
        />
      )}
    </>
  );
}
