import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Empty,
  Select,
} from "antd";
import { deleteCategory, getFields, getCategorys } from "./network_requests";
import { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import AddCategory from "./addCategory";
import EditCategory from "./editCategory";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import CheckPointDetail from "./categoryDetail";

const { Text } = Typography;

export default function CategoryMobile(props) {
  const [Category, setCategory] = useState([]);
  const [addCategory, setAddCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const [listCategory, setListCategory] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [filter, setFilter] = useState("all");
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    document.body.style.zoom = "100%";
    setLoad(true);
    setListCategory(null);
    setAddCategory(false);

    const Category_ = await getCategorys((page - 1) * 10, filter);

    if (Category_?.status === 401) {
      localStorage.removeItem("category_id");
      history.go(0);
    }
    setCategory(Category_ ? Category_?.list : []);
    setCount(Category_?.count ? Category_?.count : 0);
    setLoad(false);
  }, [page, updateData, filter]);

  return (
    <>
      {listCategory ? (
        <CheckPointDetail
          category={listCategory}
          setCategory={setListCategory}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
        />
      ) : addCategory ? (
        <AddCategory
          open={addCategory}
          setOpen={setAddCategory}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
        />
      ) : (
        <div
          style={{
            height: "95vh",
            backgroundColor: "#f3f7ff",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                }}
              >
                CheckApp Management
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#f3f7ff",
                left: 0,
                padding: "20px",
                width: "100%",
              }}
            >
              <Row style={{ padding: "0px 20px 0px 20px" }}>
                <Text style={{ color: "gray", textTransform: "uppercase" }}>
                  categories
                </Text>
              </Row>
            </div>
          </>

          {load ? (
            <div
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Spin />
            </div>
          ) : null}
          {!load ? (
            <>
              {Category?.length === 0 ? <Empty /> : null}

              {[...Category]?.map((category) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      // borderBottom: "1px solid #eaeaea",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    <Col xs={2} />
                    <Col
                      xs={18}
                      style={{
                        padding: 0,
                      }}
                      onClick={() => {
                        setListCategory(category);
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {category?.cfeld2}
                      </Text>
                    </Col>

                    <Col
                      xs={3}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <RightOutlined
                        size="large"
                        onClick={() => {
                          setListCategory(category);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Row
                style={{
                  padding: "20px 30px 20px 30px",
                  backgroundColor: "#f3f7ff",
                  margin: "0",
                  height: "50%",
                }}
                justify="end"
              >
                <Pagination
                  defaultCurrent={1}
                  total={count}
                  current={page}
                  defaultPageSize={10}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage) => {
                    setPage(newpage);
                  }}
                />
                <Row
                  justify={"center"}
                  style={{
                    width: "100%",
                    marginTop: "10vh",
                    paddingBottom: "30px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setAddCategory(!addCategory);
                    }}
                  >
                    Add Category
                  </Button>
                </Row>
              </Row>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
