import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useRef, useState, useEffect } from "react";
import { editBuildings } from "../Buildings/network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { footerContent } from "../../index";
import {
  string_to_double,
  double_to_string,
} from "../../../../../../constants";

export default function EditBuildings(props) {
  const { building, setBuilding, setPage, emptyFooter } = props;
  const open = Boolean(building);
  const ref = useRef();
  const history = useHistory();
  const [nfeld5, setnfeld5] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setnfeld5(
      building?.nfeld5
        ? moment(double_to_string(building?.nfeld5), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setnfeld1(
      building?.nfeld1
        ? moment(double_to_string(building?.nfeld1), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [building]);

  function handOkClick() {
    setBuilding(null);
    ref?.current?.submit();
  }
  function handleCancelClick() {
    setBuilding(null);
  }

  const fc = footerContent(emptyFooter, handOkClick, handleCancelClick);

  return (
    <Modal
      title={
        <>
          <>
            <IntlMessages id="edit" /> <IntlMessages id="building.title" />
          </>
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      onOk={() => {
        setBuilding(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setBuilding(null);
      }}
      footer={fc}
    >
      <Form
        name="Edit buildings"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...building,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editBuildings({
            ...building,
            ...values,
            nfeld5: string_to_double(nfeld5),
            nfeld1: string_to_double(nfeld1),
          });

          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }

          props.setUpdate(!props.update);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="name_identifier" />}
              name="cfeld1"
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="building_no" />}
              name="cfeld2"
              rules={[
                {
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={15} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="street" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="zip_code" />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="catastral_commune" />}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="property_number" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="total_area" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="net_area" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="effective_area" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="next_planned_check" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld5}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld5(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="building_finished" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld9"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
