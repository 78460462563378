import { url } from "../../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getBuildings = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "2",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: val, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addBuildings = async (values, parent) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntries`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: parent,
          stclass: 1,
          storder: 0,
          treepos: 1,
          disptree: 0,
        },
        obj_Data: {
          active: 1,
          group: "1",
          stclass: 2,
          ...values,
          gisid: "",
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editBuildings = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "1",
        stclass: 2,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
