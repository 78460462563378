import { Row } from "antd";
import emptyStarIcon from "../../../assets/Icons_waste/green_star_empty.png";
import filledStarIcon from "../../../assets/Icons_waste/green_star_full.png";

export default function Ranking(props) {
  const { value, setValue, disable } = props;
  const emptyStar = new Array(5 - value).fill(1);
  const filledStar = new Array(value).fill(1);

  return (
    <Row
      style={{
        width: "100%",
        margin: 0,
        padding: 0,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {filledStar?.map((i, index) => {
        return (
          <img
            src={filledStarIcon}
            onClick={() => {
              if (disable) {
              } else {
                setValue(index + 1);
              }
            }}
            style={{
              height: "30px",
              marginRight: "10px",
              cursor: disable ? "" : "pointer",
            }}
          />
        );
      })}
      {emptyStar?.map((i, index) => {
        return (
          <img
            onClick={() => {
              if (disable) {
              } else {
                setValue(value + index + 1);
              }
            }}
            src={emptyStarIcon}
            style={{
              height: "30px",
              marginRight: "10px",
              cursor: disable ? "" : "pointer",
            }}
          />
        );
      })}
    </Row>
  );
}
