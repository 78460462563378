import { Modal, Input, Form, Row, Col, Dropdown, Typography, Menu } from "antd";
import { useRef, useState, useEffect } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";

export default function ShowData(props) {
  const { user, setUser } = props;
  const open = Boolean(user);
  const ref = useRef();
  const history = useHistory();
  const { Text } = Typography;

  useEffect(() => {
        
  }, [user]);

  return (
    <Modal
      title={
        <>
          Details
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText="Ok"
      onOk={() => {
        setUser(null);
      }}
      onCancel={() => {
        setUser(null);
      }}
    >     
    {
      (user !== undefined && user != null) &&
        Object.entries(user).map(([key, value]) => {
          return (
            key != 'parentClasses' && 
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Text>{key}</Text>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Text>{value}</Text>
              </Col>
            </Row>
          );
      })
           
     }
    </Modal>
  );
}
