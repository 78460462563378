import {
  Modal,
  Input,
  Form,
  Menu,
  Col,
  Row,
  Dropdown,
  Typography,
  InputNumber,
  DatePicker,
} from "antd";
import { useRef, useState } from "react";
import { addTask } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { string_to_doubleDT } from "../../../constants";

export default function AddTask(props) {
  const { open, setOpen, assignments, persons } = props;
  const [assignment, setAssignment] = useState("");
  const [person, setPerson] = useState("");
  const [nfeld5, setnfeld5] = useState(null);
  const [nfeld6, setnfeld6] = useState(null);
  const [nfeld7, setnfeld7] = useState(null);
  const { Text } = Typography;

  const assignment_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setAssignment(e.key);
      }}
    >
      {assignments?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const person_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setPerson(e.key);
      }}
    >
      {persons?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const ref = useRef();
  const history = useHistory();

  return (
    <Modal
      title={
        <>
           <IntlMessages id="tasks.addtask" />
        </>
      }
      width="660px"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="add Task"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          cfeld3: window.localStorage.getItem("uname"),
          nfeld3: moment()?.format("DD.MM.YYYY HH:mm"),
        }}
        onFinish={async (values) => {
          if (values?.cfeld1) {
            setOpen(!open);
            props.setLoad(true);
            const response = await addTask({
              ...values,
              cfeld4: assignment,
              cfeld5: person,
              nfeld3: string_to_doubleDT(values?.nfeld3),
              nfeld5: nfeld5 ? string_to_doubleDT(nfeld5) : 0,
              nfeld6: nfeld6 ? string_to_doubleDT(nfeld6) : 0,
              nfeld7: nfeld7 ? string_to_doubleDT(nfeld7) : 0,
            });

            if (response.status === 401) {
              localStorage.removeItem("task_id");
              history.go(0);
            }

            props.setUpdate(!props.update);
          }
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="tasks.title" />}
          name="cfeld1"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: "Please enter the title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="tasks.description" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <TextArea rows={6} />
        </Form.Item>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="tasks.Creator" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="tasks.creationdatetime" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item label={<IntlMessages id="tasks.assignment" />} style={{ marginBottom: 0 }}>
              <Dropdown overlay={assignment_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{assignment}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item label={<IntlMessages id="tasks.executionPerson" />} style={{ marginBottom: 0 }}>
              <Dropdown overlay={person_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{person}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="tasks.durationh" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="tasks.InProgressDateTime" />}
              name="nfeld5"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld5}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld5(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} style={{ margin: 0, padding: "0 18px 0 0" }}>
            <Form.Item
              label={<IntlMessages id="tasks.PlannedDateTime" />}
              name="nfeld6"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld6}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld6(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} style={{ margin: 0, padding: "0 0 0 18px" }}>
            <Form.Item
              label={<IntlMessages id="tasks.FinishedDateTime" />}
              name="nfeld7"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld7}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld7(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="tasks.comment" />}
          name="cfeld6"
          style={{ marginBottom: "15px" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
