import { Row, DatePicker, Form, Input, Button, Col } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";
import {
  addMailSMSTemplates,
  editMailSMSTemplates,
  sendEmail,
  sendSMS,
} from "../network_requests";
import UploadDocs from "./upload";
import { failed, success } from "..";

export default function AddMailSMS({
  tabState,
  title,
  mailSMS,
  setTabState,
  setmailSMS,
  setType,
  update,
  setUpdate,
  type,
}) {
  const [mailSMSData, setMailSMSData] = useState({ ...mailSMS });
  let { page, subkey } = useParams();
  const history = useHistory();

  useEffect(() => {
    setMailSMSData({ ...mailSMSData, ...mailSMS });
  }, [mailSMS]);

  return (
    <>
      {tabState === 0 ? (
        <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: 600,
            padding: "0px 15px",
          }}
          initialValues={{
            ...mailSMSData,
          }}
          onFinish={async (values) => {
            setMailSMSData({ ...mailSMSData, ...values, title });

            if (type === "send") {
              const response_ = await sendEmail({
                ...mailSMSData,
                ...values,
                title,
              });

              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              }
              if (response_?.success) {
                success();
              } else {
                failed();
              }
            } else {
              const response_ =
                Object.keys(mailSMS).length === 0
                  ? await addMailSMSTemplates(subkey, {
                      ...mailSMSData,
                      ...values,
                      title,
                    })
                  : await editMailSMSTemplates({
                      ...mailSMSData,
                      ...values,
                      title,
                    });
              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              } else if (response_?.success) {
                if (Object.keys(mailSMS).length === 0) {
                  setmailSMS({
                    ...mailSMSData,
                    ...values,
                    title,
                    templateKey: response_?.success,
                  });
                  setType("edit");
                }
                setUpdate(!update);
                success();
              } else {
                failed();
              }
            }
          }}
          autoComplete="off"
        >
          <Row>
            <Col xs={12}>
              <Form.Item
                label="Empfänger (E-Mail Adresse)"
                name="mailReceiverMailAdress"
                style={{ padding: 0, margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Interne Weiterleitung (E-Mail Adresse)"
                name="mailInternalForwardMailAdress"
                style={{ padding: 0, margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Betreff"
            name="mailReceiverSubject"
            rules={[
              {
                required: true,
                message: "Pflichtfeld!",
              },
            ]}
            style={{ padding: 0, margin: 0 }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Inhalt"
            name="mailReceiverContent"
            style={{ padding: 0, margin: 0 }}
          >
            <ReactQuill theme="snow" style={{ height: "300px" }} />
          </Form.Item>
          {mailSMS?.templateKey ? (
            <UploadDocs templateKey={mailSMS?.templateKey} />
          ) : null}

          <Form.Item
            style={{
              padding: 0,
              margin: "0",
              marginTop: mailSMS?.templateKey ? "10px" : "70px",
            }}
          >
            <Button type="primary" htmlType="submit">
              {type === "send" ? "senden" : "speichern"}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: 600,
            padding: "0px 15px",
          }}
          initialValues={{
            ...mailSMSData,
          }}
          onFinish={async (values) => {
            setMailSMSData({ ...mailSMSData, ...values, title });
            if (type === "send") {
              const response_ = await sendSMS({
                ...mailSMSData,
                ...values,
                title,
              });

              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              }
              if (response_?.success) {
                success();
              } else {
                failed();
              }
            } else {
              const response_ =
                Object.keys(mailSMS).length === 0
                  ? await addMailSMSTemplates(subkey, {
                      ...mailSMSData,
                      ...values,
                      title,
                    })
                  : await editMailSMSTemplates({
                      ...mailSMSData,
                      ...values,
                      title,
                    });
              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              } else if (response_?.success) {
                if (Object.keys(mailSMS).length === 0) {
                  setmailSMS({
                    ...mailSMSData,
                    ...values,
                    title,
                    templateKey: response_?.success,
                  });
                  setType("edit");
                }
                setUpdate(!update);
                success();
              } else {
                failed();
              }
            }
          }}
          autoComplete="off"
        >
          <Form.Item
            label="SMS Inhalt"
            name="smsReceiverContent"
            style={{ padding: 0, margin: 0 }}
          >
            <Input.TextArea rows={5} />
          </Form.Item>

          <Row>
            <Col xs={12}>
              <Form.Item
                label="Empfänger (Telefonnummer)"
                name="smsReceiverPhonenumber"
                rules={[
                  {
                    required: true,
                    message: "Pflichtfeld!",
                  },
                ]}
                style={{ padding: 0, margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Interne Weiterleitung (Mail Adresse)"
                name="smsInternalForwardMailAdress"
                style={{ padding: 0, margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ padding: 0, margin: "10px 0 0 0" }}>
            <Button type="primary" htmlType="submit">
              {type === "send"
                ? "Send"
                : type === "insert"
                ? "Insert"
                : "Update"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
