import {
    Modal,
    Input,
    Form,
    Row,
    Col,
    Typography,
    message,
  } from "antd";
  import { useRef} from "react";
  import IntlMessages from "util/IntlMessages";
import { editCostCenter } from "./network_requests";
  
  const success = () => {
    message.success("Die Dienststelle wurde bearbeitet.");
  };
  
  const error = () => {
    message.error("Ein Fehler ist passiert, bitte nochmal versuchen!");
  };
  
  export default function EditCostCenter(props) {
    const { costCenterItem, setEstate } = props;

 
    
  
    const ref = useRef();
    const { Text } = Typography;
  
    return (
      <Modal
        title={"Dienststelle bearbeiten"}
        visible={Boolean(costCenterItem)}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onOk={() => {
          // setOpen(!open);
          ref?.current?.submit();
        }}
        onCancel={() => {
          setEstate(null);
        }}
        style={{
          minWidth: "58vw",
        }}
      >
        <Form
          name="edit Cost center"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ...costCenterItem,
          }}
          style={{
            paddingLeft:"20px"
          }}
          onFinish={async (values) => {
            const response = await editCostCenter({
              ...costCenterItem,
              ...values,
            });
            if (response?.success) {
              success();
            } else {
              error();
            }
            props.setUpdate(!props.update);
            setEstate(null);
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={8}>
              <Form.Item
                name="cfeld1"
                label="Name/Type"
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                name="cfeld2"
                label="Nummer"
                style={{ marginBottom: 0 ,marginLeft:"10px" }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
           
          </Row>
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item name="cfeld3" label="Kommentar">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        
         
        </Form>
      </Modal>
    );
  }
  