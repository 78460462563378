import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { addNote, getNotes } from "../../networkrequests";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";

import {
  doubleDT_to_string,
  string_to_doubleDT,
} from "../../../../../../../constants";
import { useHistory } from "react-router-dom";

const success = () => {
  message.success("Note added successfully.");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function Notes(props) {
  const { event } = props;
  const [notes, setNotes] = useState(null);
  const [note, setNote] = useState("");
  const [update, setUpdate] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const data_ = await getNotes(event?.stkey);
      if (data_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setNotes({ ...data_ });
      setNote(data_?.cfeld3);
    })();
  }, [update]);

  console.log(notes);

  return (
    <Row
      style={{
        marginTop: "15px",
        borderRadius: "8px",
        border: "2px solid #ececec",
        padding: "20px",
      }}
    >
      {notes ? (
        <Row style={{ width: "80%" }}>
          <Col xs={10}>
            <Typography.Text
              style={{
                fontSize: "16px",
                textTransform: "uppercase",
                padding: "0 20px",
              }}
            >
              Notizen
            </Typography.Text>
          </Col>
          <Col xs={14} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography.Text
              style={{
                fontSize: "13px",  textTransform: "none"
              }}
            >
              Letzte Änderung: {doubleDT_to_string(notes?.nfeld1)} {notes?.cfeld2}
            </Typography.Text>
          </Col>
        </Row>
      ) : null}
      <Row
        style={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          padding: "0px 0px 20px 0",
        }}
      >
        <ReactQuill
          value={note}
          onChange={(e) => {
            setNote(e);
          }}
          theme="snow"
          style={{ height: "300px", width: "100%",  textTransform: "none" }}
        />
      </Row>
      <Row
        style={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          padding: "20px 0",
        }}
      >
        <Button
          type="primary"
          onClick={async () => {
            const time = moment()?.format("DD.MM.YYYY HH:mm");
            const res = await addNote(
              {
                ...notes,
                cfeld2: window.localStorage.getItem("uname"),
                cfeld3: note,
                nfeld1: string_to_doubleDT(time),
              },
              event?.stkey
            );
            if (res?.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            if (res?.success === "saved") {
              message.success("Notiz erfolgreich aktualisiert!");
              setUpdate(!update);
            }
          }}
        >
          speichern
        </Button>
      </Row>
    </Row>
  );
}
