import {
  Row,
  Typography,
  Upload,
  Button,
  Col,
  Card,
  message,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  downloadFile,
  getFiles,
  removeFile,
  uploadFile,
} from "./network_requests";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

function downloadToLocal(pdf, filename) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UploadDocs(props) {
  const { templateKey, setActive } = props;

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [attribute, setAttribute] = useState("general");

  const uploadProps = {
    accept: ".png,.jpg",
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList.forEach(function (file, index) {
        let reader = new FileReader();
        reader.onload = async (e) => {
          console.log(e.target.result);
          setFile({
            filename: file.name,
            file: e.target.result,
          });
        };

        reader.readAsDataURL(file.originFileObj);
      });
    },
  };

  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (file) {
        const response = await uploadFile(file, templateKey, attribute);
        if (response?.status === 401) {
          localStorage.removeItem("user_id");
          history.go(0);
        }
        if (response?.success) {
          success();
          setUpdate(!update);
        } else {
          error();
        }
      }
    })();
  }, [file?.file]);

  useEffect(() => {
    (async () => {
      const response = await getFiles(templateKey, "general");
      if (response?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }

      setFiles([...response]);
    })();
  }, [update]);

  return (
    <Row
      style={{
        marginLeft: "20px",
        padding: "20px 0px",
      }}
    >
      <Row style={{ width: "100%", padding: 0 }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#038fde",
              cursor: "pointer",
            }}
            onClick={() => {
              setActive(false);
            }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "22px", fontWeight: "bold" }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 450,
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Photos
          </Typography>
        </div>
      </Row>
      <Row style={{ width: "100%", marginLeft: "0px" }}>
        {files?.map((doc) => {
          return (
            <Row
              style={{
                padding: "10px",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Card style={{ width: "100%", margin: 0 }}>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col xs={2} style={{ margin: 0, padding: 0 }}>
                    <FileOutlined style={{ fontSize: "16px" }} />
                  </Col>
                  <Col xs={20} style={{ margin: 0, padding: 0 }}>
                    <Typography.Text
                      style={{
                        cursor: "pointer",
                        color: "#038fde",
                        textTransform: "lowercase",
                      }}
                      onClick={async () => {
                        const response = await downloadFile(
                          doc?.gisid,
                          doc?.cfeld1
                        );
                        if (response?.file) {
                          downloadToLocal(response?.file, response?.filename);
                          //  downloadPDF(response?.file, response?.filename);
                        }
                      }}
                    >
                      {doc?.cfeld1?.length < 35
                        ? doc?.cfeld1
                        : `${doc?.cfeld1?.substr(0, 35)}...`}
                    </Typography.Text>
                  </Col>
                  <Col xs={2} style={{ margin: 0, padding: 0 }}>
                    <Popconfirm
                      title="Are you sure you want to delete this file?"
                      onConfirm={async () => {
                        const response = await removeFile(
                          doc?.stkey,
                          doc?.cfeld1
                        );
                        if (response?.success) {
                          success();
                          setUpdate(!update);
                        } else {
                          error();
                        }
                      }}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          color: "#f32013",
                        }}
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              </Card>
            </Row>
          );
        })}
      </Row>
      <Row style={{ width: "100%", margin: "10px 0 0 10px" }}>
        <Upload {...uploadProps}>
          <Button
            onClick={() => {
              setAttribute("general");
            }}
            icon={<UploadOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </Row>
    </Row>
  );
}
