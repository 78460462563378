import { useState, useEffect } from "react";
import Ideas from "./ideas";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import AddIdea from "./addIdea";
import EditIdea from "./editIdea";
import { Button, Row } from "antd";

export default function IdeasApp() {
  const { page } = useParams();
  const [mainPage, setMainPage] = useState(page);
  const [idea, setIdea] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setMainPage(page);
  }, [page]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          minHeight: "70vh",
          padding: "20px 10px",
          marginBottom: "20px",
        }}
      >
        {mainPage === "add" ? (
          <AddIdea />
        ) : mainPage === "edit" ? (
          <EditIdea idea={idea} />
        ) : mainPage === "base" ? (
          <>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  history.push("./add");
                }}
                style={{ padding: "0px 60px" }}
              >
                Add Idea
              </Button>
            </Row>
            <Ideas
              title="Ideas with open Tasks for you"
              attribute="unrated"
              setIdea={setIdea}
            />
            <Ideas
              title="Ideas you already contributed to"
              attribute="rated"
              setIdea={setIdea}
            />
            <Ideas title="Archive" attribute="archive" />
          </>
        ) : null}
      </div>
    </>
  );
}
