import {
  Rate,
  Input,
  Form,
  Menu,
  Col,
  Row,
  Typography,
  Button,
  message,
} from "antd";
import { useRef, useState } from "react";
import { addIdea, addRating } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import Ranking from "./ranking";
import { string_to_doubleDT } from "../../../constants";
import moment from "moment";

export default function AddIdea(props) {
  const { open, setOpen, groups } = props;
  const [group, setGroup] = useState(null);
  const { Text } = Typography;

  const ref = useRef();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [explanation, setExplanation] = useState("");
  const [load, setLoad] = useState(false);

  return (
    <>
      <Text
        style={{
          color: "#727272",
          fontSize: "18px",
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("./base");
          }}
        >
          Idea
        </span>{" "}
        {" -> "} Create New Idea
      </Text>
      <Row
        style={{
          margin: "20px 30px",
          borderRadius: "12px",
          backgroundColor: "#fafafa",
          width: "100%",
        }}
      >
        <Form
          name="add Idea"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            padding: "10px 50px",
            width: "100%",
          }}
          initialValues={{
            cfeld3: window.localStorage.getItem("uname"),
          }}
          onFinish={async (values) => {
            if (!values?.cfeld1) {
              message?.warning("Please enter the title of the idea");
            } else if (!values?.cfeld2) {
              message?.warning("Please enter the description of the idea");
            } else if (!value) {
              message?.warning("Please rate your idea");
            } else if (!explanation) {
              message?.warning("Explaination missing for the idea!");
            } else {
              setLoad(true);

              const response = await addIdea({
                ...values,
                nfeld1: string_to_doubleDT(
                  moment()?.format("DD.MM.YYYY HH:mm")
                ),
              });
              if (response.status === 401) {
                localStorage.removeItem("user_id");
                history.go(0);
              } else if (response?.success) {
                const response1 = await addRating({
                  stKey: response?.success,
                  rating: value,
                  description: explanation,
                });
                if (response1?.success === "saved") {
                  message?.success("Idea saved successfully!");
                  setLoad(false);
                  history.push("./base");
                }
              }
            }
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
          <Row style={{ width: "100%" }}>
            <Col
              xs={24}
              style={{ padding: 0, fontSize: "18px", color: "#727272" }}
            >
              <Text>IDEA</Text>
            </Col>
            <Col xs={15}>
              <Form.Item
                label="Name / Title"
                name="cfeld1"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={9}>
              <Form.Item
                label="ErstellerIn"
                name="cfeld3"
                style={{ marginBottom: 0 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                label="Description"
                name="cfeld2"
                style={{ marginBottom: 0 }}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              padding: "10px 0px",
              width: "100%",
            }}
          >
            <Text
              style={{
                color: "#727272",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              MY RANKING
            </Text>
            <Ranking value={value} setValue={setValue} />
          </Row>
          <Row
            style={{
              width: "100%",
            }}
          >
            <Text
              style={{
                color: "#727272",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Explanation
            </Text>
            <Input.TextArea
              rows={5}
              value={explanation}
              onChange={(e) => {
                setExplanation(e.target.value);
              }}
            />
          </Row>
          <Row
            style={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ padding: "0px 60px" }}
              loading={load}
              onClick={() => {
                // setOpen(!open);
                ref?.current?.submit();
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </Row>
        </Form>
      </Row>
    </>
  );
}
