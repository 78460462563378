import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Dropdown,
  Menu,
  Typography,
  DatePicker,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { editFacilities } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { footerContent } from "../../index";
import {
  string_to_double,
  double_to_string,
} from "../../../../../../constants";
import moment from "moment";

export default function EditFacilities(props) {
  const { facility, setFacility, parent, fields, emptyFooter } = props;
  const open = Boolean(facility);
  const ref = useRef();
  const history = useHistory();
  const { Text } = Typography;
  const [group, setGroup] = useState(null);
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld2, setnfeld2] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setGroup(facility?.cfeld1 ? facility?.cfeld1 : null);
    setnfeld2(
      facility?.nfeld2
        ? moment(double_to_string(facility?.nfeld2), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setnfeld1(
      facility?.nfeld1
        ? moment(double_to_string(facility?.nfeld1), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [facility]);

  const group_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setGroup(e.key);
      }}
      style={{
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      {fields?.group_type?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  function handOkClick() {
    setFacility(null);
    ref?.current?.submit();
  }
  function handleCancelClick() {
    setFacility(null);
  }

  const fc = footerContent(emptyFooter, handOkClick, handleCancelClick);

  return (
    <Modal
      title={
        <>
          <IntlMessages id="edit" /> <IntlMessages id="facility.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      onOk={() => {
        setFacility(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setFacility(null);
      }}
      footer={fc}
    >
      <Form
        name="Edit facilities"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...facility,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editFacilities(
            {
              ...facility,
              ...values,
              cfeld1: group,
              nfeld1: string_to_double(nfeld1),
              nfeld2: string_to_double(nfeld2),
            },
            parent,
            1
          );

          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }

          props.setUpdate(!props.update);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={
                <>
                  <IntlMessages id="group" /> / <IntlMessages id="type" />
                </>
              }
              name="cfeld1"
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={group_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={22}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{group}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="name" />}
              name="cfeld2"
              rules={[
                {
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="internal_no" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="QR_code" />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="serial_no" />}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cost_center" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="supplier" />}
              name="cfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="taken_over" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld1}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld1(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="taken_over_by" />}
              name="cfeld9"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="last_seen" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld2}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld2(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld6"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
