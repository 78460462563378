import { Row, DatePicker, Form, Input, Button, Col } from "antd";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";
import { addInfoTemplates, editInfoTemplates } from "../network_requests";
import { failed, success } from "..";

export default function AddInfo({ title, info, update, setUpdate, type }) {
  const [infoData, setInfoData] = useState({ ...info });
  let { page, subkey } = useParams();
  const history = useHistory();

  return (
    <>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: 600,
          padding: "0px 15px",
        }}
        initialValues={{
          ...infoData,
        }}
        onFinish={async (values) => {
          setInfoData({ ...infoData, ...values, title });
          const response_ =
            Object.keys(info).length === 0
              ? await addInfoTemplates(subkey, {
                  ...infoData,
                  ...values,
                  title,
                })
              : await editInfoTemplates({
                  ...infoData,
                  ...values,
                  title,
                });

          if (response_?.status === 401) {
            localStorage.removeItem("company_id");
            history.go(0);
          } else if (response_?.success === "success") {
            setUpdate(!update);
            success();
            if (type !== "edit") {
              history.push(`/main/service_areas/main/${subkey}`);
            }
          } else {
            failed();
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Info"
          name="textInformation"
          style={{ padding: 0, margin: 0 }}
        >
          <ReactQuill theme="snow" style={{ height: "400px", width:"600px" }} />
        </Form.Item>
        <Form.Item
          style={{ padding: 0, margin: "10px 0 0 0", marginTop: "70px" }}
        >
          {type === "send" ? null : (
            <Button type="primary" htmlType="submit">
              {Object.keys(info).length === 0 ? "speichern" : "Update"}
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
