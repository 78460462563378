import { useEffect, useState } from "react";
import { getServiceAreas } from "./network_requests";
import { useHistory, useParams } from "react-router-dom";
import { Col, Collapse, Row, Typography, message } from "antd";
import Services from "./Services";
import ServiceTemplates from "./ServiceTemplates";
import LogBook from "./logbook";

const { Panel } = Collapse;

const findstkey = (services, stkey) => {
  const found = services?.find((service) =>
    service?.subareas?.some((subarea) => subarea?.stkey === stkey)
  );
  return found;
};

export const success = () => {
  message.success("Action Successful");
};

export const failed = () => {
  message.error("Action Failed");
};

export default function ServiceAreas() {
  const [load, setLoad] = useState(false);
  let { page, subkey } = useParams();
  const [services, setServices] = useState([]);
  const [service, setService] = useState(null);
  const history = useHistory();
  const [showAll, setShowAll] = useState(subkey ? true : false);

  useEffect(async () => {
    setLoad(true);
    const services_ = await getServiceAreas(showAll ? "all" : "userspecific");
    if (services_?.status === 401) {
      localStorage.removeItem("company_id");
      history.go(0);
    }
    setServices(services_);
    setService(findstkey(services_, subkey));
  }, [showAll]);

  useEffect(() => {
    setShowAll(subkey ? true : false);
    setService(findstkey(services, subkey));
  }, [subkey]);

  return (
    <Row style={{ width: "100%" }}>
      <Col
        xs={15}
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          minHeight: "70vh",
          padding: "20px 15px",
          marginBottom: "20px",
        }}
      >
        {service ? (
          <ServiceTemplates service={service} setService={setService} />
        ) : (
          <Services services={services} setShowAll={setShowAll} />
        )}
      </Col>
      <Col xs={1} />
      <Col
        xs={8}
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          minHeight: "70vh",
          padding: "20px 30px",
          marginBottom: "20px",
        }}
      >
        <LogBook />
      </Col>
    </Row>
  );
}
