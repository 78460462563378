import { Dropdown, Menu, Typography } from "antd";
import IntlMessages from "util/IntlMessages";

const { Text } = Typography;

export default function ServiceActions({
  template,
  action,
  link,
  deleteTemplate,
  showModal,
  openModal,
  setOpenModal,
}) {
  const menu = (
    <Menu>
      {showModal ? (
        <Menu.Item
          key="0"
          onClick={() => {
            setOpenModal(template?.templateKey);
          }}
        >
          Termine anzeigen
        </Menu.Item>
      ) : null}
      <Menu.Item
        key="1"
        onClick={() => {
          action(template, link, true);
        }}
      >
        Template bearbeiten
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          deleteTemplate(template?.templateKey);
        }}
      >
        löschen
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Text
        style={{
          color: "#038fde",
          cursor: "pointer",
        }}
      >
        <IntlMessages id="more_actions" />
      </Text>
    </Dropdown>
  );
}
