import { Row, Typography, Upload, Button, Col, Card, message } from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";

import { downloadFile, getFiles } from "./network_requests";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const success = () => {
  message.success("Speichern erfolgreich!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

function downloadToLocal(pdf, filename) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UploadDocs(props) {
  const { templateKey } = props;

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(false);
  const [attribute, setAttribute] = useState("employeecontent");

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await getFiles(templateKey, "employeecontent");
      if (response?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }

      setFiles([...response]);
    })();
  }, [update]);

  return (
    <>
      <Typography style={{ marginTop: "40px", fontSize: "18px" }}>
        Documents
      </Typography>

      {files?.map((doc) => {
        return (
          <Typography
            style={{
              cursor: "pointer",
              color: "#038fde",
              textTransform: "lowercase",
              textDecoration: "underline",
              margin: "10px 0",
            }}
            onClick={async () => {
              const response = await downloadFile(doc?.gisid, doc?.cfeld1);
              if (response?.file) {
                downloadToLocal(response?.file, response?.filename);
                //  downloadPDF(response?.file, response?.filename);
              }
            }}
          >
            {doc?.cfeld1}
          </Typography>
        );
      })}
    </>
  );
}
