import { Modal, Input, Form, Row, Col } from "antd";
import { useRef, useState } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { addStation } from "./network_requests";

export default function AddStation(props) {
  const { open, setOpen, setPage, latlng } = props;
  const history = useHistory();
  const ref = useRef();

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="station" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
    >
      <Form
        name="add station"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addStation({
            ...values,
            nfeld1: latlng.lng,
            nfeld2: latlng.lat,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="name_identifier" />}
          name="cfeld1"
          rules={[
            {
              required: true,
              message: "The field should not be empty!",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={18} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="street" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="No." />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
