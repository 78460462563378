import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { addComment, getComments } from "../../networkrequests";
import moment from "moment";

import {
  doubleDT_to_string,
  string_to_doubleDT,
} from "../../../../../../../constants";
import { useHistory } from "react-router-dom";

const success = () => {
  message.success("Comment added successfully.");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function Comments(props) {
  const { event } = props;
  const [comments, setComments] = useState(null);
  const [text, setText] = useState("");
  const [update, setUpdate] = useState(false);
  const [push, setPush] = useState(false);
  const [email, setEmail] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const data_ = await getComments(event?.stkey);
      if (data_?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setComments({ ...data_ });
    })();
  }, [update]);

  return (
    <Row
      style={{
        marginTop: "15px",
        borderRadius: "8px",
        border: "2px solid #ececec",
        padding: "20px",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Typography.Text
          style={{ fontSize: "16px", textTransform: "uppercase" }}
        >
          Nachrichten
        </Typography.Text>
      </Row>
      <Row
        style={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col xs={1}>
          <Avatar size={24} icon={<UserOutlined />} />
        </Col>
        <Col xs={17}>
          <Input
            allowClear
            value={text}
            onChange={(e) => {
              setText(e?.target?.value);
            }}
          />
        </Col>
        <Col xs={2} style={{ padding: 0, margin: 0, paddingLeft: "10px" }}>
          <Button
            type="primary"
            style={{ margin: 0 }}
            onClick={async () => {
              const time = moment(moment()).format("DD.MM.YYYY HH:mm");

              const response = await addComment(
                {
                  cfeld1: window.localStorage.getItem("uname"),
                  cfeld2: text,
                  nfeld1: string_to_doubleDT(time),
                  nfeld10: push ? 1 : 0,
                  nfeld9: email ? 1 : 0,
                },
                comments?.list[0]?.stkey
              );
              if (response?.success) {
                success();
                setText("");
                setUpdate(!update);
              } else {
                error();
              }
            }}
          >
            senden
          </Button>
        </Col>
        {/*<Col
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Checkbox
            style={{ paddingRight: "5px" }}
            checked={push}
            onChange={(e) => {
              setPush(e.target.checked);
            }}
          />
          <Typography.Text>Push</Typography.Text>
          </Col>*/}
        <Col
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Checkbox
            style={{ paddingRight: "5px" }}
            checked={email}
            onChange={(e) => {
              setEmail(e.target.checked);
            }}
          />
          <Typography.Text>Email</Typography.Text>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
        }}
        align="middle"
      >
        {comments?.list?.map((comment) => {
          return (
            <Row style={{ width: "100%", margin: "0" }}>
              <Col xs={1}>
                <Avatar size={24} icon={<UserOutlined />} />
              </Col>
              <Col xs={23}>
                <Typography.Text style={{ fontWeight: "bold" }}>
                  {doubleDT_to_string(comment?.nfeld1)}, {comment?.cfeld1}:
                </Typography.Text>
                <Typography.Text style={{ paddingLeft: "5px",textTransform: "none" }}>
                  {comment?.cfeld2}
                </Typography.Text>
              </Col>
            </Row>
          );
        })}
      </Row>
    </Row>
  );
}
