import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useRef, useState } from "react";
import { addAllocation } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { string_to_double, double_to_string } from "../../../../constants";

export default function AddAllocations(props) {
  const { open, setOpen, stkey } = props;
  const history = useHistory();
  const ref = useRef();

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="allocation.title_exit" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(!open);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(!open);
      }}
      style={{ minWidth: "660px" }}
    >
      <Form
        name="add allocations"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            // remember: true,
          }
        }
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await addAllocation(stkey, {
            ...values,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="name_identifier" />}
          name="cfeld1"
          rules={[
            {
              required: true,
              message: "The field should not be empty!",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="description" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
