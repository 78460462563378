import { url } from "../../../constants";
import axios from "axios";
import moment from "moment";

export const getUrgentChecks = async (order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitContent`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        type: type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForUrgentChecks = async (order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForPruefCockpitContent`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        type: "none",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const getBuildings = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPropertiesAndBuildings`,
      {
        group: "class_g01",
        stClass: "2",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUrgentChecksforBuildings = async (stkey, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitChecksForBuilding`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        buildingStKey: stkey,
        type: type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForUrgentChecksforBuildings = async (stkey,name, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForPruefCockpitChecksForBuilding`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        buildingStKey: stkey,
        buildingName:name,
        type: "none",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUrgentChecksforRecommendations = async (stkey, order) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitChecksForBuildingWithOrder`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        buildingStKey: stkey,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUrgentChecksforUsers = async (username, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getPruefCockpitChecksForPerson`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        username: username,
        type: type,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getExcelForgetUrgentChecksforUsers= async (username, order, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getExcelForPruefCockpitChecksForPerson`,
      {
        orderby: `D.nfeld7 ${order}, D.nfeld8 ${order}, D.nfeld9 ${order}`,
        fromday: moment(new Date()).format("YYYYMMDD"),
        username: username?.username,
        type: "none",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllTypes = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.get(`${url}getInternalCockpitTypeList`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
