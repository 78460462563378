import { Row, Typography, Col, Button, Pagination, Spin, Empty, Popconfirm, Tooltip,} from "antd";
import { getVehicles, deleteVehicle, excelForExitVehicles } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddVehicles from "./addVehicles";
import EditVehicles from "./editVehicles";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../extraComponents/MoreActions";
import { double_to_string } from "../../../../constants";
import ModelInspections from "../../../extraComponents/MoreActions/ModelInspections";
import { FileExcelOutlined } from "@ant-design/icons";

const { Text } = Typography;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function Vehicles(props) {
  const { mainStates, setMainStates } = props;
  const [vehicles, setVehicles] = useState([]);
  const [addVehicles, setAddVehicles] = useState(false);
  const [editVehicles, setEditVehicles] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const vehicles_ = await getVehicles(
      (page - 1) * 10,
      mainStates?.allocation
    );
    if (vehicles_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setVehicles(vehicles_?.list ? vehicles_?.list : []);
    setCount(vehicles_?.count ? vehicles_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  return (
    <>
      <AddVehicles
        open={addVehicles}
        setOpen={setAddVehicles}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        stkey={mainStates?.allocation}
      />
      <EditVehicles
        vehicle={editVehicles}
        setVehicle={setEditVehicles}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <Button
          type="link"
          onClick={() => {
            history?.push("/main/vehicles_exit_overview/1");
          }}
          style={{ margin: 0, padding: 0 }}
        >
          {"Zuordnung Ausgeschiedene > "} {mainStates?.allocationName}
        </Button>
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
          >
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="vehicle.name" />
              </Text>
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="vehicle.registrationNo." />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="vehicle.firstRegistration" />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="vehicle.AM-Check" />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="vehicle.inventoryNo." />
              </Text>
            </Col>

            <Col xs={4}>
              <Popconfirm
                title="Archiv Fahrzeuge Excel Datei erzeugen?"
                onConfirm={async () => {
                  const response = await excelForExitVehicles({
                    stkey: mainStates?.allocation,
                  });
                  if (response?.file) {
                    downloadToLocal(response?.file, response?.filename);
                    //  downloadPDF(response?.file, response?.filename);
                  }
                }}
                okText="Ja"
                cancelText="Nein"
              >
                <Tooltip title="Archiv Excel Datei aus der Fahzeugen erzeugen">
                  <FileExcelOutlined style={{ cursor: "pointer" }} />
                </Tooltip>
              </Popconfirm>
            </Col>

          </Row>

          {vehicles?.length === 0 ? <Empty /> : null}

          {vehicles?.map((vehicle) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditVehicles(vehicle);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {vehicle?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{vehicle?.cfeld2}</Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{double_to_string(vehicle?.nfeld1)}</Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{vehicle?.cfeld7}</Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{vehicle?.cfeld5}</Text>
                </Col>

                {/* <Col
                  xs={12}
                  sm={4}
                  xl={2}
                  style={{
                    borderRight: "1px solid #eaeaea",
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        vehicle: vehicle?.stkey,
                        vehicleName: vehicle?.cfeld1,
                      });
                    }}
                  >
                    <IntlMessages id="edit" />
                  </Text>
                </Col> */}
                <Col xs={4}>
                  <MoreActions
                    state={vehicle}
                    onDelete={deleteVehicle}
                    update={updateData}
                    setUpdate={setUpdateData}
                    inspection={true}
                    stClass={"15"}
                  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
                {/* <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <RightSquareOutlined
                    size="large"
                    style={{
                      color: "#038fde",
                      marginLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        vehicle: vehicle?.stkey,
                        vehicleName: vehicle?.cfeld1,
                      });
                    }}
                  />
                </Col> */}
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
           
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
