import { useEffect, useState } from "react";
import {
  Row,
  Typography,
  Col,
  Pagination,
  Spin,
  Empty,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import IntlMessages from "../../../../../../util/IntlMessages";
import {
  getOrder,
  wordInternalBillForOrder,
  removeOrder,
  wordAuftragForOrder,
} from "./network_Request";
import {
  double_to_string,
  doubleDT_to_string,
} from "../../../../../../constants";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import {
  FileWordOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import red from "../assets/red.png";
import green from "../assets/green.png";
import yellow from "../assets/yellow.png";
const { Text } = Typography;

const success = () => {
  message.success("Der Auftrag wurde gelöscht!");
};

const error = () => {
  message.error("Ein Fehler ist passiert, bitte nochmal versuchen!");
};

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function WorkOrdersMain(props) {
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { updateData, setUpdateData, filter, dates } = props;

  const [count, setCount] = useState(0);
  const [editStaff, setEditStaff] = useState(null);
  const history = useHistory();

  useEffect(async () => {
    setLoad(true);
    const checks = await getOrder((page - 1) * 10, filter, dates);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setData(checks?.list ? checks?.list : []);
    setCount(checks?.count ? checks?.count : 0);
    setLoad(false);
  }, [page, updateData, filter, dates]);

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0 15px 5px 15px",
            }}
          >
            <Col
              span={2}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.status" />
              </Text>
            </Col>
            <Col
              span={6}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.titel" />
              </Text>
            </Col>
            <Col
              span={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.created" />
              </Text>
            </Col>
            <Col
              span={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.planned" />
              </Text>
            </Col>
            <Col
              span={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.ressources" />
              </Text>
            </Col>
            <Col
              span={2}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.costStaffVeh" />
              </Text>
            </Col>
            <Col
              span={2}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="wordorder.costMat" />
              </Text>
            </Col>
            <Col
              span={2}
              style={{
                padding: 0,
              }}
            >
              <Text></Text>
            </Col>
          </Row>

          {data?.length === 0 ? (
            <Empty description="Keine Aufträge vorhanden!" />
          ) : null}

          {data?.map((item) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0 15px",
                }}
              >
                <Col
                  span={2}
                  style={{
                    padding: 0,
                    alignItems: "center",
                  }}
                >
                  {item.nfeld3 > 0 && item.nfeld7 === 0 && item.nfeld5 === 0 ? (
                    <img src={red} alt="red" width="30px" />
                  ) : item.nfeld3 > 0 &&
                    item.nfeld7 > 0 &&
                    item.nfeld5 === 0 ? (
                    <img src={yellow} alt="Yellow" width="30px" />
                  ) : item.nfeld3 > 0 && item.nfeld7 > 0 && item.nfeld5 > 0 ? (
                    <img src={green} alt="green" width="30px" />
                  ) : null}
                </Col>
                <Col
                  span={6}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // const encodedItem = encodeURIComponent(JSON.stringify(item));
                    history.push(
                      `/main/baseData-Order?editOrderKey=${item.stkey}`
                    );
                  }}
                >
                  <p style={{ margin: 0, color: "#038fde" }}>
                    {item?.cfeld1 ? item?.cfeld1 : "-"}
                  </p>
                </Col>
                <Col
                  span={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld3 ? doubleDT_to_string(item?.nfeld3) : null}
                  </Text>
                  <br />
                  <Text>{item?.cfeld4}</Text>
                </Col>
                <Col
                  span={3}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {item?.nfeld7 ? double_to_string(item?.nfeld7) : "-"}
                  </Text>
                </Col>
                <Col
                  span={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{item?.cfeld5 ? item?.cfeld5 : "-"}</Text>
                </Col>
                <Col
                  span={2}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{item?.nfeld9 ? `${item?.nfeld9} €` : "-€"}</Text>
                </Col>
                <Col
                  span={2}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>{item?.nfeld10 ? `${item?.nfeld10} €` : "-€"}</Text>
                </Col>
                <Col
                  span={2}
                  style={{
                    padding: 0,
                  }}
                >
                  <Popconfirm
                    title="Interne Rechnung erzeugen?"
                    onConfirm={async () => {
                      const response = await wordInternalBillForOrder({
                        stkey: item?.stkey,
                      });
                      if (response?.file) {
                        downloadToLocal(response?.file, response?.filename);
                        //  downloadPDF(response?.file, response?.filename);
                      }
                    }}
                    okText="Ja"
                    cancelText="Nein"
                  >
                    <FileWordOutlined style={{ cursor: "pointer" }} />
                  </Popconfirm>
                  <Popconfirm
                    title="Arbeitsauftrag erzeugen?"
                    onConfirm={async () => {
                      const response = await wordAuftragForOrder({
                        stkey: item?.stkey,
                      });
                      if (response?.file) {
                        downloadToLocal(response?.file, response?.filename);
                        //  downloadPDF(response?.file, response?.filename);
                      }
                    }}
                    okText="Ja"
                    cancelText="Nein"
                  >
                    <FileWordOutlined
                      style={{ cursor: "pointer", padding: 5 }}
                    />
                  </Popconfirm>
                  <Popconfirm
                    title="Auftrag löschen?"
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    onConfirm={async () => {
                      const response = await removeOrder({ ...item });
                      if (response?.success) {
                        success();
                        setUpdateData(!updateData);
                      } else {
                        error();
                      }
                    }}
                  >
                    <DeleteOutlined style={{ cursor: "pointer" }} />
                  </Popconfirm>
                </Col>
              </Row>
            );
          })}

          <Row
            style={{
              padding: "10px 30px",
              margin: "0 15px",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
