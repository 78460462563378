import Typography from "antd/lib/typography/Typography";
import { Button, Card, Popconfirm } from "antd";
import { Input, Form, Row, Col, DatePicker, message } from "antd";
import { Transfer } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { AssignStaffForOrder, addMaterial, addOrder_baseData, getAllStaff, getAllVehicle, getMaterialForOrder, removeMaterial } from "./network_Request";
import IntlMessages from "../../../../../../util/IntlMessages";
import { useHistory,useLocation } from "react-router-dom";
import { Table, Divider } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import EditMaterialForOrder from "./editMaterialForOrder";


const success = () => {
  message.success("Das Material wurde zum Auftrag gespeichert.");
};

const error = () => {
  message.error("Ein Fehler ist passiert- bitte nochmal versuchen!");
};

const deletesuccess =() =>{
  message.success("Das Material wurde erfolgreich gelöscht.");
}

export default function MaterialForOrder(props) {

  const [data, setData] = useState([]);
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const stKeyFromURL = queryParams.get('stKey');

  const [count, setCount] = useState(0);
  const history = useHistory();
  const ref = useRef();
  const [materialAdded, setMaterialAdded] = useState(false);
  const [editMaterial ,setEditMaterial] = useState(null)
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(async () => {
    
    if(stKeyFromURL || props.orderMaterial){ 
      const stKey = stKeyFromURL ? stKeyFromURL : props.orderMaterial.stkey;
    const checks = await getMaterialForOrder(stKey);
    if (checks?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setData(checks?.list ? checks?.list : []);
    setCount(checks?.count ? checks?.count : 0);
    }
    else{
      setData([])
    }
   
  }, [materialAdded,stKeyFromURL,updateData,props?.orderMaterial?.stkey]);

  
  const handleDelete = async (record) => {
    try {
      const response = await removeMaterial(record);
      if (response?.success) {
        deletesuccess();
        setMaterialAdded(!materialAdded);
        const newData = data.filter(item => item.detkey !== record.detkey);
        setData(newData);
      } else {
        error();
      }
    } catch (error) {
      console.error(error);
      error();
    }
  };

  const commatoPointParser = (value) => {
    // Convert commas to dots and parse as float
    const stringValue = value.toString();
    return parseFloat(stringValue.includes(",") ? stringValue.replace(/,/g, ".") : stringValue);
  };


  const columns = [
    {
      title: <IntlMessages id="workorder.materialname"/>,
      dataIndex: 'cfeld1',
      render: (text, record) => (
        <a style={{color:"#545454"}} onClick={() => handleEdit(record)}>{text}</a>
      ),
    },
    {
      title: <IntlMessages id="workorder.materialprice"/>,
      dataIndex: 'nfeld2',
      render: (text, record) => (
        <a style={{color:"#545454"}}  onClick={() => handleEdit(record)}>{text}</a>
      ),
    },
    {
      title: '',
      dataIndex:'',
      render: (_, record) =>
      data.length >= 1 ? (
        <Popconfirm title="Wirklich löschen?" onConfirm={() => handleDelete(record)}>
          <DeleteOutlined />
         </Popconfirm>
      ) : null,
     
    },
  ];

  const handleEdit = (record) => {
    setEditMaterial(record);
  };

  return (
    <>
    <EditMaterialForOrder
    editMaterial={editMaterial}
    setEstate={setEditMaterial}
    setLoad={setLoad}
    update={updateData}
    setUpdate={setUpdateData}
    />

      <Card
        style={{
          borderRadius: "8px",
          margin: "20px 70px 20px 60px",
          padding: 0,
          height: "auto",
          // width: "100%",
        }}
      >
        <Typography.Text><IntlMessages id="workorder.materialforthisorder"/></Typography.Text>

        <Row style={{ width: "100%", marginTop: "30px" }}>
            <Col xs={24}>
            <Row style={{width:"100%", marginLeft:"20px"}}>
            <Table columns={columns} dataSource={data} size="middle" pagination={false} 
            style={{width:"500px"}}/>
            </Row>
            <Form
          name="add staff"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            {
              // remember: true,
            }
          }
          style={{
            padding:"0px 30px 0px 55px"
          }}
          onFinish={async (values) => {
            const stkey = props.orderMaterial ? props.orderMaterial.stkey: stKeyFromURL; 
            const response = await addMaterial(
              {
                ...values,
                nfeld2: commatoPointParser(values.nfeld2),
              },
              stkey
            );
            if (response?.success) {
              success();
              setMaterialAdded(!materialAdded)
              ref.current.setFieldsValue({
                nfeld1: '',
                cfeld1: '', 
              });
            } else {
              error();
            }
            // props.setUpdate(!props.update);
            // setnfeld3(moment(moment(), "DD.MM.YYYY"));
            // setOpen(!open);
          }}
        //     // onFinishFailed={onFinishFailed}
        //     autoComplete="off"
        >

            <Row width={{width:"100%", height:"75px"}}>
                <Col xs={8}>
                <Form.Item
                 name="cfeld1">
                <Input 
                 placeholder='Material'
                 style={{ marginTop: "20px" }}
                 />
               </Form.Item>
                </Col>
                <Col xs={1}/>
                <Col  xs={6}>
                <Form.Item
                 name="nfeld2">
                <Input 
                 placeholder='1,0€'
                 style={{ marginTop: "20px" }}/>
                  </Form.Item>
                </Col>


                <Col xs={8}>
                    <Button type="primary"  style={{ marginTop: "20px" , padding:"0px 60px"}}  onClick={() => {
              // setOpen(!open);
              ref?.current?.submit();
            }}>
                        <IntlMessages id='add'/>

                    </Button>
                </Col>

            </Row>

            </Form>
            </Col>
         
        </Row>
      </Card>
    </>
  );
}
