import { Modal, Row, Col, Dropdown, Typography } from "antd";
import { useEffect, useState } from "react";
import { getTemplateLogs } from "./network_requests";
import { useHistory } from "react-router-dom";
import { string_to_doubleDT, doubleDT_to_string } from "../../../constants";

const { Text } = Typography;

export default function ShowAppointments({ openModal, setOpenModal }) {
  const [appointments, setAppointments] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    const response = await getTemplateLogs(openModal);
    if (response?.status === 401) {
      localStorage.removeItem("company_id");
      history.go(0);
    } else {
      setAppointments(response?.list);
    }
  }, [openModal]);
  return (
    <Modal
      title={"Appointments Scheduale"}
      visible={Boolean(openModal)}
      onCancel={() => {
        setOpenModal(null);
      }}
      onOk={() => {
        setOpenModal(null);
      }}
    >
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
          margin: "20px 0 0 0",
        }}
      >
        <Col
          xs={8}
          style={{
            padding: 0,
          }}
        >
          <Text>Name</Text>
        </Col>
        <Col xs={8}>
          <Text>Appointment</Text>
        </Col>
        <Col xs={8}>
          <Text>More Actions</Text>
        </Col>
      </Row>
      {appointments?.map((appointment) => {
        return (
          <Row
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eaeaea",
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              <Text>{appointment?.cfeld1}</Text>
            </Col>
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {appointment?.nfeld1
                  ? doubleDT_to_string(appointment?.nfeld1)
                  : ""}
              </Text>
              {" - "}
              <Text>
                {appointment?.nfeld2
                  ? doubleDT_to_string(appointment?.nfeld2)
                  : ""}
              </Text>
            </Col>
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              <Dropdown>
                <Text
                  style={{
                    color: "#038fde",
                    cursor: "pointer",
                  }}
                >
                  More Actions
                </Text>
              </Dropdown>
            </Col>
          </Row>
        );
      })}
    </Modal>
  );
}
