import { url } from "../../../constants";
import axios from "axios";

export const getTrackings = async (start, filter) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "10",
        type: "none",
        limitFrom: start,
        limitTo: start + 10,
        orderBy: "nfeld1 DESC,nfeld2 DESC",
        cfields: filter?.cfields ?? [],
        nfields: filter?.nfields ?? [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTracking = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 20,
        stkey: "",
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTracking = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 20,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCostCenter = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getCostCenterListForOrder`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "3",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllStaff = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "2",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getVehicles = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromDetailCommon`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: "47D68CEA8CA340",
        detail: "cldetail_g33",
        detClass: "1",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTracking = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        active: 0,
        group: 20,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
