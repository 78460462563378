import { Row, Typography, Col, Button, Pagination, Spin, Empty,Tooltip,Popconfirm } from "antd";
import { editAllocation, getAllocations,excelForAllAppliances,excelForAllVehicles } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddAllocation from "./addAllocation";
import EditAllocation from "./editAllocation";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../extraComponents/MoreActions";
import TypeSelecter from "../../../extraComponents/MoreActions/typeSelecter";
import { FileExcelOutlined } from "@ant-design/icons";
import placeIcon from "../assets/place.png";

const { Text } = Typography;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function Allocations(props) {
  const { mainStates, setMainStates } = props;
  const [allocations, setAllocations] = useState([]);
  const [addAllocations, setAddAllocations] = useState(false);
  const [editAllocations, setEditAllocations] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [type, setType] = useState("none");

  useEffect(async () => {
    setLoad(true);
    const allocations_ = await getAllocations((page - 1) * 10, type);
    if (allocations_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setAllocations(allocations_?.list ? allocations_?.list : []);
    setCount(allocations_?.count ? allocations_?.count : 0);
    setLoad(false);
  }, [page, updateData, type]);

  return (
    <>
      <AddAllocation
        open={addAllocations}
        setOpen={setAddAllocations}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        stkey="47df310efa4720"
      />
      <EditAllocation
        allocation={editAllocations}
        setAllocation={setEditAllocations}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        stkey="47df310efa4720"
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <IntlMessages id="allocation.title" />
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
            align="middle"
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="allocation.title" />
              </Text>
            </Col>
            <Col
              xs={0.5}
              sm={0.5}
              xl={0.5}
              style={{
                padding: 2,
              }}
            >
               <Popconfirm
                title="Excel Datei erzeugen?"
                onConfirm={async () => {
                  const response = await excelForAllVehicles({
                  });
                  if (response?.file) {
                    downloadToLocal(response?.file, response?.filename);
                  }
                }}
                okText="Ja"
                cancelText="Nein"
              >
                <Tooltip title="Excel Datei für alle Fahrzeuge erzeugen.">
                  <FileExcelOutlined style={{ cursor: "pointer" }} />
                </Tooltip>
               </Popconfirm>

               
            </Col>
            <Col
              xs={0.5}
              sm={0.5}
              xl={0.5}
              style={{
                padding: 2,
              }}
            >
              <Popconfirm
                title="Excel Datei erzeugen?"
                onConfirm={async () => {
                  const response = await excelForAllAppliances({
                  });
                  if (response?.file) {
                    downloadToLocal(response?.file, response?.filename);
                  }
                }}
                okText="Ja"
                cancelText="Nein"
              >
                <Tooltip title="Excel Datei für alle Geräte erzeugen.">
                  <FileExcelOutlined style={{ cursor: "pointer" }} />
                </Tooltip>
               </Popconfirm>
            </Col>

            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              {/* <TypeSelecter type={type} setType={setType} /> */}
            </Col>
          </Row>

          {allocations?.length === 0 ? <Empty /> : null}

          {allocations?.map((allocation) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditAllocations(allocation);
                  }}
                >
                   <img
                  src={
                      placeIcon
                  }
                  style={{ height: "20px", marginRight: "8px" }}
                />
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {allocation?.cfeld1}
                  </Text>
                </Col>

                {/* <Col
                  xs={12}
                  sm={4}
                  xl={2}
                  style={{
                    borderRight: "1px solid #eaeaea",
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        allocation: allocation?.stkey,
                        allocationName: allocation?.cfeld1,
                      });
                    }}
                  >
                    <IntlMessages id="edit" />
                  </Text>
                </Col> */}
                <Col>
                  <MoreActions
                    state={allocation}
                    noPic={true}
                    onDelete={editAllocation}
                    update={updateData}
                    setUpdate={setUpdateData}
                  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <RightSquareOutlined
                    size="large"
                    style={{
                      color: "#038fde",
                      marginLeft: "40px",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      history?.push("/main/vehicles_overview/2");
                      setMainStates({
                        ...mainStates,
                        allocation: allocation?.stkey,
                        allocationName: allocation?.cfeld1,
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddAllocations(!addAllocations);
              }}
            >
              <IntlMessages id="allocation.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
