import { Row, DatePicker, Form, Input, Button, Col } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { string_to_doubleDT, doubleDT_to_string } from "../../../../constants";
import { useHistory, useParams } from "react-router-dom";
import {
  addAppointmentTemplates,
  editAppointmentTemplates,
  sendEmail,
  sendSMS,
} from "../network_requests";
import moment from "moment";
import { update } from "lodash";
import UploadDocs from "./upload";
import { failed, success } from "..";

export default function AddAppointment({
  tabState,
  title,
  appointment,
  setTabState,
  setAppointment,
  update,
  setUpdate,
  type,
  setType,
}) {
  const [appontmentData, setAppointmentData] = useState({ ...appointment });
  const [appointmentStart, setAppointnentStart] = useState();
  const [appointmentEnd, setAppointnentEnd] = useState(null);
  let { page, subkey } = useParams();
  const history = useHistory();

  console.log(appointment);

  useEffect(() => {
    if (appointment?.appointmentStart) {
      setAppointnentStart(
        moment(
          doubleDT_to_string(appointment?.appointmentStart),
          "DD.MM.YYYY HH:mm"
        )
      );
    }
    if (appointment?.appointmentEnd) {
      setAppointnentEnd(
        moment(
          doubleDT_to_string(appointment?.appointmentEnd),
          "DD.MM.YYYY HH:mm"
        )
      );
    }
    setAppointmentData({ ...appontmentData, ...appointment });
  }, [appointment]);

  return (
    <>
      {tabState === 0 ? (
        <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          style={{
            maxWidth: 600,
            width: "100%",
            padding: "0px 15px",
          }}
          initialValues={{
            ...appontmentData,
          }}
          onFinish={async (values) => {
            setAppointmentData({
              ...appontmentData,
              ...values,
              appointmentStart: appointmentStart
                ? string_to_doubleDT(appointmentStart)
                : 0,
              appointmentEnd: appointmentEnd
                ? string_to_doubleDT(appointmentEnd)
                : 0,
              title,
            });
            if (type !== "send") {
              const response_ =
                Object.keys(appointment).length === 0
                  ? await addAppointmentTemplates(subkey, {
                      ...appontmentData,
                      ...values,
                      appointmentStart: appointmentStart
                        ? string_to_doubleDT(appointmentStart)
                        : 0,
                      appointmentEnd: appointmentEnd
                        ? string_to_doubleDT(appointmentEnd)
                        : 0,
                      title,
                    })
                  : await editAppointmentTemplates({
                      ...appontmentData,
                      ...values,
                      appointmentStart: appointmentStart
                        ? string_to_doubleDT(appointmentStart)
                        : 0,
                      appointmentEnd: appointmentEnd
                        ? string_to_doubleDT(appointmentEnd)
                        : 0,
                      title,
                    });

              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              } else if (response_?.success) {
                if (Object.keys(appointment).length === 0) {
                  setAppointment({
                    ...appontmentData,
                    ...values,
                    appointmentStart: appointmentStart
                      ? string_to_doubleDT(appointmentStart)
                      : 0,
                    appointmentEnd: appointmentEnd
                      ? string_to_doubleDT(appointmentEnd)
                      : 0,
                    title,
                    templateKey: response_?.success,
                  });
                  setType("edit");
                }

                success();
                setUpdate(!update);
              } else {
                failed();
              }
            }
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Vor- und Nachname"
            name="personName"
            style={{ padding: 0, margin: 0 }}
          >
            <Input disabled={type !== "send"} />
          </Form.Item>

          <Form.Item label="Beginn" style={{ padding: 0, margin: 0 }}>
            <DatePicker
              style={{ width: "100%" }}
              disabled={type !== "send"}
              defaultValue={
                appontmentData?.appointmentStart
                  ? moment(
                      doubleDT_to_string(appontmentData?.appointmentStart),
                      "DD.MM.YYYY HH:mm"
                    )
                  : null
              }
              showTime={{ format: "HH:mm" }}
              format="DD.MM.YYYY HH:mm"
              minuteStep={15}
              onChange={(e, d) => {
                setAppointnentStart(d);
              }}
            />
          </Form.Item>

          <Form.Item label="Ende" style={{ padding: 0, margin: 0 }}>
            <DatePicker
              style={{ width: "100%" }}
              disabled={type !== "send"}
              defaultValue={
                appontmentData?.appointmentEnd
                  ? moment(
                      doubleDT_to_string(appontmentData?.appointmentEnd),
                      "DD.MM.YYYY HH:mm"
                    )
                  : null
              }
              showTime={{ format: "HH:mm" }}
              format="DD.MM.YYYY HH:mm"
              minuteStep={15}
              onChange={(e, d) => {
                setAppointnentEnd(d);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Kommentar"
            name="appointmentcomment"
            style={{ padding: 0, margin: 0 }}
          >
            <Input disabled={type !== "send"} />
          </Form.Item>
          {type === "send" ? (
            <Form.Item style={{ padding: 0, margin: "10px 0 0 0" }}>
              <Button type="primary" htmlType="submit">
                speichern
              </Button>
            </Form.Item>
          ) : null}
        </Form>
      ) : tabState === 1 ? (
        <>
          <Form
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              maxWidth: 600,
              padding: "0px 15px",
            }}
            initialValues={{
              ...appontmentData,
            }}
            onFinish={async (values) => {
              setAppointmentData({ ...appontmentData, ...values, title });
              if (type === "send") {
                const response_ = await sendEmail({
                  ...appontmentData,
                  ...values,
                  title,
                });

                if (response_?.status === 401) {
                  localStorage.removeItem("company_id");
                  history.go(0);
                }
                if (response_?.success) {
                  success();
                } else {
                  failed();
                }
              } else {
                const response_ =
                  Object.keys(appointment).length === 0
                    ? await addAppointmentTemplates(subkey, {
                        ...appontmentData,
                        ...values,
                        appointmentStart: appointmentStart
                          ? string_to_doubleDT(appointmentStart)
                          : 0,
                        appointmentEnd: appointmentEnd
                          ? string_to_doubleDT(appointmentEnd)
                          : 0,
                        title,
                      })
                    : await editAppointmentTemplates({
                        ...appontmentData,
                        ...values,
                        appointmentStart: appointmentStart
                          ? string_to_doubleDT(appointmentStart)
                          : 0,
                        appointmentEnd: appointmentEnd
                          ? string_to_doubleDT(appointmentEnd)
                          : 0,
                        title,
                      });

                if (response_?.status === 401) {
                  localStorage.removeItem("company_id");
                  history.go(0);
                } else if (response_?.success) {
                  if (Object.keys(appointment).length === 0) {
                    setAppointment({
                      ...appontmentData,
                      ...values,
                      appointmentStart: appointmentStart
                        ? string_to_doubleDT(appointmentStart)
                        : 0,
                      appointmentEnd: appointmentEnd
                        ? string_to_doubleDT(appointmentEnd)
                        : 0,
                      title,
                      templateKey: response_?.success,
                    });
                    setType("edit");
                  }
                  success();
                  setUpdate(!update);
                } else {
                  failed();
                }
              }
            }}
            autoComplete="off"
          >
            <Row>
              <Col xs={12}>
                <Form.Item
                  label="Empfänger (E-Mail Adresse)"
                  name="mailReceiverMailAdress"
                  style={{ padding: 0, margin: 0 }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Interne Weiterleitung (E-Mail Adresse)"
                  name="mailInternalForwardMailAdress"
                  style={{ padding: 0, margin: 0 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Betreff"
              name="mailReceiverSubject"
              rules={[
                {
                  required: true,
                  message: "Pflichtfeld!",
                },
              ]}
              style={{ padding: 0, margin: 0 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Inhalt"
              name="mailReceiverContent"
              style={{ padding: 0, margin: 0 }}
            >
              <ReactQuill theme="snow" style={{ height: "300px" }} />
            </Form.Item>
            {appointment?.templateKey ? (
              <UploadDocs templateKey={appointment?.templateKey} />
            ) : null}

            <Form.Item
              style={{
                padding: 0,
                margin: "0",
                marginTop: appointment?.templateKey ? "10px" : "70px",
              }}
            >
              <Button type="primary" htmlType="submit">
                {type === "send" ? "senden" : "speichern"}
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: 600,
            padding: "0px 15px",
          }}
          initialValues={{
            ...appontmentData,
          }}
          onFinish={async (values) => {
            setAppointmentData({ ...appontmentData, ...values, title });
            if (type !== "send") {
              const response_ =
                Object.keys(appointment).length === 0
                  ? await addAppointmentTemplates(subkey, {
                      ...appontmentData,
                      ...values,
                      title,
                    })
                  : await editAppointmentTemplates({
                      ...appontmentData,
                      ...values,
                      title,
                    });

              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              } else if (response_?.success) {
                if (Object.keys(appointment).length === 0) {
                  setAppointment({
                    ...appontmentData,
                    ...values,
                    appointmentStart: appointmentStart
                      ? string_to_doubleDT(appointmentStart)
                      : 0,
                    appointmentEnd: appointmentEnd
                      ? string_to_doubleDT(appointmentEnd)
                      : 0,
                    title,
                    templateKey: response_?.success,
                  });
                  setType("edit");
                }
                setUpdate(!update);
                success();
              } else {
                failed();
              }
            } else {
              const response_ = await sendSMS({
                ...appontmentData,
                ...values,
                title,
              });

              if (response_?.status === 401) {
                localStorage.removeItem("company_id");
                history.go(0);
              }
              if (response_?.success) {
                success();
              } else {
                failed();
              }
            }
          }}
          autoComplete="off"
        >
          <Form.Item
            label="SMS Inhalt"
            name="smsReceiverContent"
            style={{ padding: 0, margin: 0 }}
          >
            <Input.TextArea rows={5} />
          </Form.Item>

          <Row>
            <Col xs={12}>
              <Form.Item
                label="Empfänger (Telefonnummer)"
                name="smsReceiverPhonenumber"
                rules={[
                  {
                    required: true,
                    message: "Pflichtfeld!",
                  },
                ]}
                style={{ padding: 0, margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Interne Weiterleitung (E-Mail Adresse)"
                name="smsInternalForwardMailAdress"
                style={{ padding: 0, margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ padding: 0, margin: "10px 0 0 0" }}>
            <Button type="primary" htmlType="submit">
              {type === "send" ? "Send" : type === "insert" ? "Insert" : "Save"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
