import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Empty,
  Select,
} from "antd";
import { getCheckPoints } from "./network_requests";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import AddCheckPoint from "./addCheckPoint";
import EditCheckPoint from "./editCheckPoint";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import EditCategory from "./editCategory";

import nfcicon from "../../../assets/Icons_waste/contactless_icon.png";

const { Text } = Typography;

export default function CheckPointDetail(props) {
  const { category, setCategory } = props;
  const [CheckPoint, setCheckPoint] = useState([]);
  const [addCheckPoint, setAddCheckPoint] = useState(false);
  const [editCheckPoint, setEditCheckPoint] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    document.body.style.zoom = "100%";
    setLoad(true);
    setEditCheckPoint(null);
    setAddCheckPoint(false);

    const CheckPoint_ = await getCheckPoints((page - 1) * 10, category?.cfeld2);

    if (CheckPoint_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setCheckPoint(CheckPoint_ ? CheckPoint_?.list : []);
    setCount(CheckPoint_?.count ? CheckPoint_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  return (
    <>
      {editCategory ? (
        <EditCategory
          category={editCategory}
          setCategory={setEditCategory}
          setLoad={setLoad}
          update={props?.update}
          setUpdate={props?.setUpdate}
        />
      ) : editCheckPoint ? (
        <EditCheckPoint
          checkPoint={editCheckPoint}
          setCheckPoint={setEditCheckPoint}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
        />
      ) : addCheckPoint ? (
        <AddCheckPoint
          category={category?.cfeld2}
          open={addCheckPoint}
          setOpen={setAddCheckPoint}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
        />
      ) : (
        <div
          style={{
            height: "95vh",
            backgroundColor: "#f3f7ff",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#038fde",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCategory(null);
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                {category?.cfeld2}
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#f3f7ff",
                left: 0,
                padding: "20px",
                width: "100%",
              }}
            >
              <Row style={{ padding: "0px 20px 0px 20px" }}>
                <Text style={{ color: "gray", textTransform: "uppercase" }}>
                  check points
                </Text>
              </Row>
            </div>
          </>

          {load ? (
            <div
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Spin />
            </div>
          ) : null}
          {!load ? (
            <>
              {CheckPoint?.length === 0 ? <Empty /> : null}

              {[...CheckPoint]?.map((checkPoint) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      // borderBottom: "1px solid #eaeaea",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={21}
                      style={{
                        padding: 0,
                      }}
                      onClick={() => {
                        setEditCheckPoint(checkPoint);
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {checkPoint?.cfeld1}
                      </Text>
                    </Col>

                    <Col
                      xs={1}
                      style={{
                        display: checkPoint?.nfeld5 ? "flex" : "none",
                        justifyContent: "flex-end",
                        padding: 0,
                      }}
                    >
                      <img src={nfcicon} height={"16px"} />
                    </Col>

                    <Col
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 0,
                      }}
                    >
                      <RightOutlined
                        size="large"
                        onClick={() => {
                          setEditCheckPoint(checkPoint);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Row
                style={{
                  padding: "20px 30px 20px 30px",
                  backgroundColor: "#f3f7ff",
                  margin: "0",
                  height: "50%",
                }}
                justify="end"
              >
                <Pagination
                  defaultCurrent={1}
                  total={count}
                  current={page}
                  defaultPageSize={10}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage) => {
                    setPage(newpage);
                  }}
                />
                <Row
                  justify={"center"}
                  style={{
                    width: "100%",
                    marginTop: "10vh",
                    paddingBottom: "30px",
                  }}
                >
                  <Col
                    xs={12}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setEditCategory(category);
                      }}
                    >
                      Edit Category
                    </Button>
                  </Col>
                  <Col
                    xs={12}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setAddCheckPoint(!addCheckPoint);
                      }}
                    >
                      Add CheckPoint
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
