import Typography from "antd/lib/typography/Typography";
import { Button, Card } from "antd";
import { Input, Form, Row, Col, DatePicker, message } from "antd";
import { Transfer } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { AssignVehicleForOrder, DeAssignVehicleForOrder,  getVehicleForOrder } from "./network_Request";
import IntlMessages from "../../../../../../util/IntlMessages";
import { useHistory , useLocation} from "react-router-dom";


const success = () => {
  message.success("Fahrzeuge für den Auftrag gespeichert!");
};

const error = () => {
  message.error("Error happened, please try again.");
};

export default function VehiclesForOrder(props) {
  const [data, setData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stKeyFromURL = queryParams.get('stKey');

  const [count, setCount] = useState(0);
  const history = useHistory();

  useEffect(async () => {

    if(stKeyFromURL || props.orderVehicle)
  
   { const stKey = stKeyFromURL ? stKeyFromURL : props.orderVehicle.stkey;

      const checks = await getVehicleForOrder(stKey);
      if (checks?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setCount(checks?.count ? checks?.count : 0);
  
      const allVehicles = checks?.list || [];
  
      setData(allVehicles);
      
      const VehicleWithNField10Equals1 = allVehicles.filter(item => item.nfeld10 === 1.0);
      
      setTargetKeys(VehicleWithNField10Equals1);}

   else{
    setData([]);
   }
  }, [stKeyFromURL,props.orderVehicle]);

  

  const handleSave = async () => {
    try {
      const response = await AssignVehicleForOrder(targetKeys);
      const dataNotInTarget = data.filter(item => !targetKeys.some(targetItem => targetItem.detkey === item.detkey));

      // Pass the filtered data to the DeAssignStaffForOrder function
      const response1 = await DeAssignVehicleForOrder(dataNotInTarget);
      success(); 
    } catch (error) {
      error(); 
      console.error(error);
    }
  };
  const [targetKeys, setTargetKeys] = useState([]);

  const handleChange = (newTargetKeys, direction, moveKeys) => {
    console.log(newTargetKeys, direction, moveKeys);
    const selectedStaff = data.filter(item => newTargetKeys.includes(item.detkey)).map(item => ({ ...item, stkey: '47e031dad175f8' }));
    setTargetKeys(selectedStaff);
  };

  const filterOption = (inputValue, option) =>
    option.description.indexOf(inputValue) > -1;

  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };

  return (
    <>
      <Card
        style={{
          borderRadius: "8px",
          margin: "20px 70px 20px 60px",
          padding: 0,
          height: "auto",
          // width: "100%",
        }}
      >
        <Typography.Text><IntlMessages id="workorder.vehicleforthisorder" /></Typography.Text>

        <Row style={{ width: "100%", marginTop: "30px" }}>
          <Col xs={18}>
            <Transfer
              selectAllLabels={[
                ({ selectedCount, totalCount }) => (
                  <span>
                    {totalCount}
                    {<IntlMessages id="workorder.vehicle" />}
                  </span>
                ),
                ({ selectedCount, totalCount }) => (
                  <span>
                    {totalCount}
                    {<IntlMessages id="workorder.vehiclesadded" />}
                  </span>
                ),
              ]}
              dataSource={data}
              showSearch
              listStyle={{
                width: 300,
                height: 300,
              }}
              targetKeys={targetKeys.map(item => item.detkey)}
              onChange={handleChange}
              render={(item) => item.cfeld1}
              rowKey={(item) => item.detkey}
            />
          </Col>

          <Col
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button type="primary"  onClick={handleSave} style={{ padding:"0px 60px"}}>
              <IntlMessages id="save"/>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
}
