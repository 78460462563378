import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import {
  deleteEmployee,
  editEmployee,
  getEmployees,
  getUserGroups,
} from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddEmployee from "./addEmployee";
import EditEmployee from "./editEmployee";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../extraComponents/MoreActions";
import TypeSelecter from "../../../extraComponents/MoreActions/typeSelecter";

const { Text } = Typography;

export default function Employees(props) {
  const { mainStates, setMainStates } = props;
  const [Employees, setEmployees] = useState([]);
  const [addEmployees, setAddEmployees] = useState(false);
  const [editEmployees, setEditEmployees] = useState(null);

  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [type, setType] = useState("none");
  const [userGroups, setUserGroups] = useState([]);

  useEffect(async () => {
    setLoad(true);
    const Employees_ = await getEmployees();
    if (Employees_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    const groups = await getUserGroups();
    setUserGroups(groups);

    setEmployees(Employees_ ? Employees_ : []);
    setLoad(false);
  }, [page, updateData, type]);

  return (
    <>
      <AddEmployee
        open={addEmployees}
        setOpen={setAddEmployees}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        groups={userGroups}
      />
      <EditEmployee
        Employee={editEmployees}
        setEmployee={setEditEmployees}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        groups={userGroups}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <IntlMessages id="Employees Overview" />
      </Text>

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "20px 0 0 0",
            }}
            align="middle"
          >
            <Col
              xs={12}
              sm={12}
              xl={18}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="Employees" />
              </Text>
            </Col>
            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              <Text>
                {" "}
                <IntlMessages id="actions" />
              </Text>
            </Col>

            <Col
              xs={6}
              sm={6}
              xl={3}
              style={{
                padding: 0,
              }}
            >
              {/* <TypeSelecter type={type} setType={setType} /> */}
            </Col>
          </Row>

          {Employees?.length === 0 ? <Empty /> : null}

          {Employees?.map((Employee) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={12}
                  sm={12}
                  xl={18}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditEmployees(Employee);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {Employee?.firstname} {Employee?.lastname}
                  </Text>
                </Col>

                {/* <Col
                  xs={12}
                  sm={4}
                  xl={2}
                  style={{
                    borderRight: "1px solid #eaeaea",
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setMainPage(2);
                      setMainStates({
                        ...mainStates,
                        Employee: Employee?.stkey,
                        EmployeeName: Employee?.cfeld1,
                      });
                    }}
                  >
                    <IntlMessages id="edit" />
                  </Text>
                </Col> */}
                <Col>
                  <MoreActions
                    state={Employee}
                    noPic={true}
                    onDelete={async (data) => {
                      const response = await deleteEmployee({ ...data });
                      setUpdateData(!updateData);
                      return response;
                    }}
                    update={updateData}
                    setUpdate={setUpdateData}
                  />
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddEmployees(!addEmployees);
              }}
            >
              <IntlMessages id="Add Employee" />
            </Button>
          </Row>
        </>
      ) : null}
    </>
  );
}
