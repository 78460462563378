import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Menu,
  Dropdown,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { editRooms } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import moment from "moment";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { footerContent } from "../../index";
import {
  string_to_double,
  double_to_string,
} from "../../../../../../constants";

export default function EditRooms(props) {
  const { room, setRoom, fields, emptyFooter } = props;
  const open = Boolean(room);
  const ref = useRef();
  const { Text } = Typography;
  const history = useHistory();

  double_to_string(20211002);

  const [checkInterval, setCheckInterval] = useState(null);
  const [cleaning_program, setCleaning_program] = useState(null);
  const [coveragetype, setCoveragetype] = useState(null);
  const [flooring, setFlooring] = useState(null);
  const [windowtype, setWindowtype] = useState(null);
  const [cost_center, setCost_center] = useState(null);

  useEffect(() => {
    setCheckInterval(room?.cfeld26 ? room?.cfeld26 : null);
    setCleaning_program(room?.cfeld17 ? room?.cfeld17 : null);
    setCoveragetype(room?.cfeld20 ? room?.cfeld20 : null);
    setFlooring(room?.cfeld15 ? room?.cfeld15 : null);
    setWindowtype(room?.cfeld14 ? room?.cfeld14 : null);
    setCost_center(room?.cfeld13 ? room?.cfeld13 : null);
  }, [room]);

  const interval_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCheckInterval(e.key);
      }}
    >
      {fields?.check_interval?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const cleaning_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCleaning_program(e.key);
      }}
    >
      {fields?.cleaning_program?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const coverage_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCoveragetype(e.key);
      }}
    >
      {fields?.coverage_type?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const flooring_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setFlooring(e.key);
      }}
    >
      {fields?.flooring?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const windowType_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setWindowtype(e.key);
      }}
    >
      {fields?.windowType?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  const costcenter_menu = (
    <Menu
      title="Choose field"
      onClick={(e) => {
        setCost_center(e.key);
      }}
    >
      {fields?.costcenter?.map((field) => {
        return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
      })}
    </Menu>
  );

  function handOkClick() {
    setRoom(null);
    ref?.current?.submit();
  }
  function handleCancelClick() {
    setRoom(null);
  }

  const fc = footerContent(emptyFooter, handOkClick, handleCancelClick);

  return (
    <Modal
      title={
        <>
          <IntlMessages id="edit" /> <IntlMessages id="room.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      onOk={() => {
        setRoom(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setRoom(null);
      }}
      footer={fc}
    >
      <Form
        name="add rooms"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...room,
        }}
        onFinish={async (values) => {
          props.setLoad(true);

          const response = await editRooms({
            ...room,
            ...values,
            cfeld26: checkInterval,
            cfeld17: cleaning_program,
            cfeld20: coveragetype,
            cfeld15: flooring,
            cfeld14: windowtype,
            cfeld13: cost_center,
           
          });

          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }

          props.setUpdate(!props.update);
        }}
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="room_no." />}
              name="cfeld1"
              rules={[
                {
                  required: true,
                  message: "The field should not be empty!",
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={
                <>
                  <IntlMessages id="room_type" /> I{" "}
                </>
              }
              name="cfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={
                <>
                  <IntlMessages id="room_type" /> II
                </>
              }
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="architect_ID" />}
              name="cfeld23"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="QR_code" />}
              name="cfeld22"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cleaning_program" />}
              name="cfeld17"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={cleaning_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={22}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{cleaning_program}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="group" />}
              name="cfeld21"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cost_center" />}
              name="cfeld13"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={costcenter_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                    display: "flex",
                  }}
                >
                  <Col
                    span={22}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{cost_center}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="department" />}
              name="cfeld18"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
          <Form.Item
              label={<IntlMessages id="next_check" />}
              name="cfeld19"
              style={{ marginBottom: 0 }}
            >
             <Input />
             </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="check_intervall" />}
              name="cfeld26"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={interval_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={22}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{checkInterval}</Text>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="net_area" />}
              name="nfeld2"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="coverage" />}
              name="nfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="height" />}
              name="nfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="window_type" />}
              name="cfeld14"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={windowType_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{windowtype}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="flooring" />}
              name="cfeld15"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={flooring_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{flooring}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="glass_area" />}
              name="nfeld1"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="coverage_type" />}
              name="cfeld20"
              style={{ marginBottom: 0 }}
            >
              <Dropdown overlay={coverage_menu} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{coveragetype}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="window_area" />}
              name="nfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="jalousie" />}
              name="nfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="wet_room" />}
              name="cfeld26"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="fire_alarm" />}
              name="cfeld24"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="emergency_escape" />}
              name="cfeld25"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld9"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
