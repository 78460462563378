import { Card, Row, Col, Typography } from "antd";
import IntlMessages from "util/IntlMessages";

export default function (props) {
  const { tabState, setTabState } = props;

  return (
    <Row
      style={{
        marginTop: "40px",
        borderRadius: "8px",
        padding: 0,
        height: "100px",
        display: "flex",
        width: "100%",
      }}
    >
      <Row
        style={{ width: "100%", justifyContent: "center", padding: "0px 15px" }}
      >
        <Col xs={9}>
          <Row align="middle">
            <Col
              xs={13}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: tabState !== 0 ? "pointer" : "",
              }}
              onClick={() => {
                setTabState(0);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 0 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 0 ? "#fff" : "",
                  border: tabState !== 0 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                Hm
              </div>
              <Typography.Text style={{ fontWeight: "bold", fontSize: "12px" }}>
                Zeit Slot
              </Typography.Text>
            </Col>
            <Col xs={10}>
              <div style={{ width: "100%", border: "1px solid #eaeaea" }} />
            </Col>
          </Row>
          <Typography.Text
            style={{ marginLeft: "40px", fontWeigh: 200, fontSize: "12px" }}
          >
            Zeitraum wählen
          </Typography.Text>
        </Col>
        <Col xs={9}>
          <Row align="middle">
            <Col
              xs={14}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: tabState === 1 || props.creating ? "" : "pointer",
              }}
              onClick={() => {
                setTabState(props?.creating ? 0 : 1);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 1 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 1 ? "#fff" : "",
                  border: tabState !== 1 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                EM
              </div>
              <Typography.Text style={{ fontWeight: "bold", fontSize: "12px" }}>
                Email
              </Typography.Text>
            </Col>
            <Col xs={9}>
              <div style={{ width: "100%", border: "1px solid #eaeaea" }} />
            </Col>
          </Row>
          <Typography.Text
            style={{ marginLeft: "40px", fontWeigh: 200, fontSize: "12px" }}
          >
            Email senden
          </Typography.Text>
        </Col>
        <Col xs={6}>
          <Row align="middle">
            <Col
              xs={24}
              style={{
                display: "flex",
                alignItems: "center",

                cursor: tabState === 2 || props.creating ? "" : "pointer",
              }}
              onClick={() => {
                setTabState(props?.creating ? 0 : 2);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 2 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 2 ? "#fff" : "",
                  border: tabState !== 2 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                SMS
              </div>
              <Typography.Text style={{ fontWeight: "bold", fontSize: "12px" }}>
                SMS
              </Typography.Text>
            </Col>
          </Row>
          <Row style={{ marginLeft: "35px" }}>
            <Typography.Text style={{ fontWeigh: 200, fontSize: "12px" }}>
              SMS senden
            </Typography.Text>
          </Row>
        </Col>
      </Row>
    </Row>
  );
}
