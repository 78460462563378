import { url } from "../../../constants";
import axios from "axios";
import moment from "moment";

export const getTasks = async (start, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g20",
        stClass: "1",
        type: "none",
        limitFrom: start,
        limitTo: start + 10,
        orderBy: "nfeld3,nfeld5,nfeld6,nfeld7",
        cfields: [],
        nfields:
          type === "all"
            ? [{ fieldNo: "nfeld3", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "planned"
            ? [{ fieldNo: "nfeld5", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "inProgress"
            ? [{ fieldNo: "nfeld6", fromVal: "0", toVal: "0", opVal: ">" }]
            : type === "finished"
            ? [{ fieldNo: "nfeld7", fromVal: "0", toVal: "0", opVal: ">" }]
            : [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadImage = async (file, objID) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const date = moment().format("YYYYMMDD");
    const time = moment().format("HHmm");
    const response = await axios.post(
      `${url}uploadImage`,
      {
        filename: `${date}${time}_${objID}.png`,
        file: file?.slice(22),
        attribute: "signature",
        group: 20,
        stclass: 1,
        detclass: 0,
        date: parseInt(date),
        time: parseInt(time),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: objID,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 20,
        stkey: "",
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 20,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        active: 0,
        group: 20,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
