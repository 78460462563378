import Modal from "antd/lib/modal/Modal";
import Upload from "./Upload";

const UploadModal = ({
  attribute,
  title,
  isModalVisible,
  setIsModalVisible,
  stkey,
}) => {
  return (
    <Modal
      title={`Files ${title}`}
      width={450}
      visible={isModalVisible}
      okButtonProps={{ style: { display: "none" } }}
      onOk={() => {
        setIsModalVisible(!isModalVisible);
      }}
      onCancel={() => {
        setIsModalVisible(!isModalVisible);
      }}
    >
      <Upload attribute={attribute} stkey={stkey} />
    </Modal>
  );
};

export default UploadModal;
