import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  message,
  Dropdown,
  Menu,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { editInspection } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { string_to_double, double_to_string } from "../../../constants";
import { DownOutlined } from "@ant-design/icons";

const success = () => {
  message.success("Inspection updated successfully");
};

const error = () => {
  message.error("Error! Please try again");
};

const { Text } = Typography;

export default function EditInspection(props) {
  const { open, setOpen } = props;
  const history = useHistory();
  const ref = useRef();
  const [inspectionDate, setinspectionDate] = useState(
    moment(moment(), "DD.MM.YYYY")
  );
  const [nextInspectionDate, setnextInspectionDate] = useState(null);
  const [condition, setCondition] = useState(1.0);
  useEffect(() => {
    setinspectionDate(
      open?.nfeld1
        ? moment(double_to_string(open?.nfeld1), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setnextInspectionDate(
      open?.nfeld3
        ? moment(double_to_string(open?.nfeld3), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [open]);

  return (
    <Modal
      title={
        <>
          <IntlMessages id="edit" /> <IntlMessages id="Inspection" />
        </>
      }
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(null);
        if (props.reset) {
          props.reset(null);
        }
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
        if (props.reset) {
          props.reset(null);
        }
      }}
    >
      <Form
        name="edit Inspection"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          approvedBy: open?.cfeld2,
          comment: open?.cfeld4,
          inspectedBy: open?.cfeld5,
          condition: open?.nfeld2,
          inspectionType: open?.cfeld1,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editInspection({
            ...open,
            nfeld1: string_to_double(inspectionDate),
            nfeld3: nextInspectionDate
              ? string_to_double(nextInspectionDate)
              : 0.0,
            cfeld2: values?.approvedBy,
            cfeld4: values?.comment,
            cfeld5: values?.inspectedBy,
            nfeld2: values?.condition,

            //open,
          });

          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          if (response.success === "saved") {
            success();
          } else {
            error();
          }
          props.setLoad(false);
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.inspectionDate" />}
              name="inspectionDate"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={inspectionDate}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setinspectionDate(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="condition" />}
              name="condition"
              style={{ marginBottom: 0 }}
            >
              <Dropdown
                overlay={
                  <Menu
                    title="Choose field"
                    onClick={(e) => {
                      setCondition(e.key);
                    }}
                    style={{
                      overflowY: "scroll",
                      maxHeight: "250px",
                    }}
                  >
                    <Menu.Item key={1.0}>Ok</Menu.Item>
                    <Menu.Item key={0.0}>Fehlerhaft</Menu.Item>
                  </Menu>
                }
                trigger="click"
              >
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={20}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{condition == 0 ? "Fehlerhaft" : "Ok"}</Text>
                  </Col>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.inspectionInspectedBy" />}
              name="approvedBy"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.inspectionApprovedBy" />}
              name="inspectedBy"
              style={{ marginBottom: 0 }}
            >
              <Input defaultValue={window.localStorage.getItem("uname")} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Inspection Type" />}
              name="inspectionType"
              style={{ marginBottom: 0 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="cockpit.nextInspectionDate" />}
              name="nextInspectionDate"
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nextInspectionDate}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnextInspectionDate(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="comment"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
