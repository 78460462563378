import { url } from "../../../../constants";
import axios from "axios";
import moment from "moment";

export const getAllocations = async (limitFrom, type) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "4",
        type: type,
        limitFrom: limitFrom,
        limitTo: "10",
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: "47df310efa4720", opVal: "=" }],
        nfields: [{fieldNo:"active",fromVal:1,opVal:"="}],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addAllocation = async (stkey, values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntries`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: stkey,
          stclass: "3",
          storder: "0",
          treepos: "1",
          disptree: "0",
        },
        obj_Data: {
          group: 1,
          stkey: "",
          active: 1,
          stclass: 4,
          gisid: " ",
          cfeld1: " ",
          cfeld2: " ",
          cfeld3: " ",
          cfeld4: " ",
          cfeld5: " ",
          cfeld6: " ",
          cfeld7: " ",
          cfeld8: " ",
          cfeld9: " ",
          cfeld10: " ",
          cfeld11: " ",
          cfeld12: " ",
          cfeld13: " ",
          cfeld14: " ",
          cfeld15: " ",
          cfeld16: " ",
          cfeld17: " ",
          cfeld18: " ",
          cfeld19: " ",
          cfeld20: " ",
          cfeld21: " ",
          cfeld22: " ",
          cfeld23: " ",
          cfeld24: " ",
          cfeld25: " ",
          cfeld26: " ",
          cfeld27: " ",
          cfeld28: " ",
          cfeld29: " ",
          cfeld30: " ",
          nfeld1: 1.0,
          nfeld2: 0.0,
          nfeld3: 0.0,
          nfeld4: 0.0,
          nfeld5: 0.0,
          nfeld6: 0.0,
          nfeld7: 0.0,
          nfeld8: 0.0,
          nfeld9: 0.0,
          nfeld10: 0.0,
          ...values,
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editAllocation = async (values, deleteIt) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: "1",
        stclass: 4,
        ...values,
        active: deleteIt ? 0 : 1,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const excelForAllAppliances = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}excelForAllAppliances`,
      {
        group: "1",
        stclass: 9,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const excelForAllVehicles = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}excelForAllVehicles`,
      {
        group: "1",
        stclass: 9,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};