import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Button,
} from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { addIssue } from "./network_requests";
import MultiSelectPanel from "./MultiSelectPanel";
import { doubleDT_to_string, string_to_doubleDT } from "../../../../constants";
import red_icon from "../../../../assets/Icons_waste/task_open.png";
import UploadModal from "./UploadModal";

export default function ResolveIssue(props) {
  const {
    open,
    setOpen,
    setResolveValues,
    fixAppliedOptions,
    resolveValues,
    stkey,
    title,
  } = props;
  const [nfeld5, setnfeld5] = useState(null);
  const [fixAppliedIssue, setFixAppliedIssue] = useState([]);
  const [openPhotos, setOpenPhotos] = useState(false);

  const history = useHistory();
  const ref = useRef();

  useEffect(() => {
    setnfeld5(
      resolveValues?.nfeld5
        ? moment(resolveValues.nfeld5, "DD.MM.YYYY HH:mm")
        : moment(moment(), "DD.MM.YYYY")
    );
    setFixAppliedIssue(resolveValues.fixapplied);
  }, [resolveValues]);

  return (
    <Modal
      title={<><IntlMessages id="issue" /> <IntlMessages id="beheben" /></>}
      width="660px"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onCancel={() => {
        setOpen(!open);
      }}
      footer={[
        <Button
          key="extra"
          onClick={() => {
            setOpenPhotos(!openPhotos);
          }}
        >
          Fotos
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <IntlMessages id="cancel" />
        </Button>,

        <Button
          key="ok"
          type="primary"
          onClick={() => {
            setOpen(!open);
            ref?.current?.submit();
          }}
        >
          <IntlMessages id="save" />
        </Button>,
      ]}
    >
      <UploadModal
        attribute="fix"
        title={title}
        isModalVisible={openPhotos}
        setIsModalVisible={setOpenPhotos}
        stkey={stkey}
      />

      <Form
        name="resolve issue"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...resolveValues,
          // remember: true,
        }}
        onFinish={async (values) => {
          setResolveValues({
            ...values,
            fixapplied: [...fixAppliedIssue],
            nfeld5: string_to_doubleDT(nfeld5),
          });
          setnfeld5(null);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col xs={12}>
            <Form.Item
              label={<IntlMessages id="issue.ResolvedBy" />}
              name="cfeld9"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label={<IntlMessages id="Datum" />}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld5}
                showTime={{ format: "HH:mm" }}
                format="DD.MM.YYYY HH:mm"
                minuteStep={15}
                onChange={(e, d) => {
                  setnfeld5(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="issue.FixApplied" />}
          style={{ marginBottom: 0 }}
        >
          <MultiSelectPanel
            options={[...fixAppliedOptions]}
            selectedItems={fixAppliedIssue}
            setSelectedItems={setFixAppliedIssue}
          />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld10"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
