import { Card, Row, Col, Typography } from "antd";
import IntlMessages from "util/IntlMessages";

export default function (props) {
  const { tabState, setTabState } = props;

  return (
    <Card
      style={{
        marginTop: "15px",
        borderRadius: "8px",
        padding: 0,
        height: "100px",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Col xs={5}>
          <Row align="middle">
            <Col
              xs={18}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: tabState !== 0 ? "pointer" : "",
              }}
              onClick={() => {
                setTabState(0);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 0 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 0 ? "#fff" : "",
                  border: tabState !== 0 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                BD
              </div>
              <Typography.Text style={{ fontWeight: "bold", fontSize: "12px" }}>
                {<IntlMessages id="events.EventBaseData" />}
              </Typography.Text>
            </Col>
            <Col xs={6}>
              <div style={{ width: "100%", border: "1px solid #eaeaea" }} />
            </Col>
          </Row>
          <Typography.Text
            style={{
              marginLeft: "40px",
              fontWeigh: 200,
              fontSize: "12px",
              display: "flex",
            }}
          >
            Veranstaltungsdefinition
          </Typography.Text>
        </Col>
        <Col xs={5}>
          <Row align="middle">
            <Col
              xs={17}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: tabState === 1 || props.creating ? "" : "pointer",
              }}
              onClick={() => {
                setTabState(props?.creating ? 0 : 1);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 1 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 1 ? "#fff" : "",
                  border: tabState !== 1 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                CL
              </div>
              <Typography.Text style={{ fontWeight: "bold", fontSize: "12px" }}>
                Checklist
              </Typography.Text>
            </Col>
            <Col xs={7}>
              <div style={{ width: "100%", border: "1px solid #eaeaea" }} />
            </Col>
          </Row>
          <Typography.Text
            style={{
              marginLeft: "40px",
              fontWeigh: 200,
              fontSize: "12px",
              display: "flex",
               textTransform: "none"
            }}
          >
            Verwaltung der Anforderungen
          </Typography.Text>
        </Col>
        <Col xs={5}>
          <Row align="middle">
            <Col
              xs={15}
              style={{
                display: "flex",
                alignItems: "center",

                cursor: tabState === 2 || props.creating ? "" : "pointer",
              }}
              onClick={() => {
                setTabState(props?.creating ? 0 : 2);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 2 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 2 ? "#fff" : "",
                  border: tabState !== 2 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                  fontSize: "12px",
                }}
              >
                UL
              </div>
              <Typography.Text style={{ fontWeight: "bold", fontSize: "12px" }}>
                Upload
              </Typography.Text>
            </Col>
            <Col xs={8}>
              <div style={{ width: "100%", border: "1px solid #eaeaea" }} />
            </Col>
          </Row>
          <Row style={{ marginLeft: "40px" }}>
            <Typography.Text
              style={{ fontWeigh: 200, fontSize: "12px", display: "flex",  textTransform: "none" }}
            >
              Dokumente verwalten
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={5}>
          <Row align="middle">
            <Col
              xs={16}
              style={{
                display: "flex",
                alignItems: "center",

                cursor: tabState === 3 || props.creating ? "" : "pointer",
              }}
              onClick={() => {
                setTabState(props?.creating ? 0 : 3);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 3 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 3 ? "#fff" : "",
                  border: tabState !== 3 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                }}
              >
                LB
              </div>
              <Typography.Text
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                Logbuch
              </Typography.Text>
            </Col>
            <Col xs={8}>
              <div style={{ width: "100%", border: "1px solid #eaeaea" }} />
            </Col>
          </Row>
          <Row style={{ marginLeft: "40px" }}>
            <Typography.Text style={{ fontWeigh: 200, fontSize: "12px" }}>
              Nachrichten
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={4}>
          <Row align="middle">
            <Col
              xs={24}
              style={{
                display: "flex",
                alignItems: "center",

                cursor: tabState === 4 || props.creating ? "" : "pointer",
              }}
              onClick={() => {
                setTabState(props?.creating ? 0 : 4);
              }}
            >
              <div
                style={{
                  backgroundColor: tabState === 4 ? "#0092e3" : "",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: tabState === 4 ? "#fff" : "",
                  border: tabState !== 4 ? "1px solid #eaeaea" : "",
                  marginRight: "10px",
                }}
              >
                NT
              </div>
              <Typography.Text
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                Notizen
              </Typography.Text>
            </Col>
          </Row>
          <Row style={{ marginLeft: "40px" }}>
            <Typography.Text
              style={{ fontWeigh: 200, fontSize: "12px", display: "flex",  textTransform: "none" }}
            >
              Notizen zur Vst.
            </Typography.Text>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
