import { Row, Typography, Col, Pagination, Spin, Tabs, Empty,Popconfirm,Tooltip  } from "antd";
import { getUrgentChecksforUsers,getExcelForgetUrgentChecksforUsers } from "./network_requests";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { double_to_string, getWarning } from "../../../constants";
import { getUsersForAnlagenPruefung } from "../overview-indoor/userManagement/network_requests";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { FileExcelOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { TabPane } = Tabs;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function UserChecks({ type }) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [order, setOrder] = useState("ASC");

  useEffect(async () => {
    setLoad(true);
    const users_ = await getUsersForAnlagenPruefung();
    if (users_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setUsers(users_);
    setUser(users_?.length > 0 ? users_[0]?.username : null);
  }, []);

  useEffect(async () => {
    if (user) {
      setLoad(true);
      const checks = await getUrgentChecksforUsers(user, order, type);
      if (checks?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setData(checks);
    }
    setLoad(false);
  }, [user, order, type]);

  return (
    <>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <Tabs
                defaultActiveKey={user}
                tabPosition="left"
                style={{ height: 520 }}
                size="small"
                onChange={(key) => {
                  setUser(key);
                }}
              >
                {users?.map((user, i) => {
                  return (
                    <TabPane
                      tab={user?.username + " " + user?.lastname}
                      key={user?.username}
                    >
                      <Row
                        style={{
                          backgroundColor: "#FAFAFA",
                          borderBottom: "1px solid #eaeaea",
                          fontWeight: 500,
                          padding: "10px 30px",
                          margin: "0 15px",
                        }}
                      >
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="cockpit.position" />
                          </Text>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="cockpit.pruefung" />
                          </Text>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Text>
                            <IntlMessages id="cockpit.LastCheck" />
                          </Text>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Row style={{ display: "flex", alignItems: "center" }}>
                            <Col
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                            >
                              <Text>
                                <IntlMessages id="cockpit.NextCheck" />

                                {order === "ASC" ? (
                                  <ArrowDownOutlined
                                    onClick={() => {
                                      setOrder("DESC");
                                    }}
                                    style={{ paddingLeft: "5px" }}
                                  />
                                ) : (
                                  <ArrowUpOutlined
                                    onClick={() => {
                                      setOrder("ASC");
                                    }}
                                    style={{ paddingLeft: "5px" }}
                                  />
                                )}
                              </Text>
                              </Col>
                              <Col
                              xs={12}
                              style={{ display: "flex", alignItems: "center" }}
                              >
                                <Popconfirm
                                title="Excel Datei erzeugen?"
                                onConfirm={async () => {
                                  const response = await getExcelForgetUrgentChecksforUsers(user, order, type);
                                  if (response?.file) {
                                    downloadToLocal(response?.file, response?.filename);
                                  }
                                }}
                                okText="Ja"
                                cancelText="Nein"
                                >
                                <Tooltip title="Excel Datei aus der Liste erzeugen">
                                  <FileExcelOutlined style={{ cursor: "pointer" }} />
                                </Tooltip>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {data?.length === 0 ? <Empty description="Keine Prüfungen vorhanden!"/> : null}

                      {data?.slice((page - 1) * 10, page * 10)?.map((item) => {
                        return (
                          <Row
                            style={{
                              backgroundColor: "#fff",
                              borderBottom: "1px solid #eaeaea",
                              padding: "10px 30px",
                              margin: "0 15px",
                            }}
                          >
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.objPosition}
                              </p>
                              <p style={{ margin: 0, fontSize: "14px" }}>{item?.objID} {item?.objTagID}</p>
                              <p style={{ margin: 0, fontSize: "14px" }}>{item?.objGroup}</p>
                              <p style={{ margin: 0, fontSize: "14px" }}>{item?.objType}</p>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.objGroup}
                              </p>
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.checkType}
                              </p>
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.checkIntervall}
                              </p>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.lastCheck
                                  ? double_to_string(item?.lastCheck)
                                  : ""}{" "}
                              </p>
                              <p style={{ margin: 0, fontSize: "14px" }}>
                                {item?.checkPerson}
                              </p>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                padding: 0,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor:
                                      getWarning(item?.warn2) > 0
                                        ? "red"
                                        : getWarning(item?.warn1) < 0
                                        ? "green"
                                        : "yellow",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div style={{ paddingLeft: "5px" }}>
                                  <p style={{ margin: 0, fontSize: "14px" }}>
                                    {item?.nextCheck
                                      ? double_to_string(item?.nextCheck)
                                      : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: "14px" }}>
                                    {item?.checkPerson}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                    </TabPane>
                  );
                })}
              </Tabs>
            </Col>
          </Row>

          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={data?.length}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
