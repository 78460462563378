import { Row, Col, Typography, Pagination, Button, Empty,Popconfirm,Tooltip } from "antd";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import MoreActions from "../../../extraComponents/MoreActions";
import { getAppliances, getFields, deleteAppliance,excelForExitAppliances } from "./network_requests";
import { useHistory } from "react-router-dom";
import AddAppliance from "./addAppliance";
import EditAppliance from "./editAppliance";
import { FileExcelOutlined } from "@ant-design/icons";

const { Text } = Typography;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}


export default function Appliances(props) {
  const { mainStates, setMainStates } = props;
  const [appliances, setAppliances] = useState([]);
  const [addAppliance, setAddAppliance] = useState(false);
  const [editAppliance, setEditAppliance] = useState(null);
  const [countApp, setCountApp] = useState(0);
  const [pageApp, setPageApp] = useState(1);
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [exitObject, setExitObject] = useState(null);
  const [fields, setFields] = useState({});
  const [load, setLoad] = useState(false);
  const [updateDataApp, setUpdateDataApp] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    const group_type = await getFields("G01C04D01F01");
    const condition = await getFields("G01C08D00F02");
    const check_necessary = await getFields("G01C08D00F10");
    const check_necessary_appl = await getFields("G01C09D00F01");
    const check_interval = await getFields("G01C08D00F17");
    const appliance_type = await getFields("G01C09D00F04");
    const appliance_group = await getFields("G01C09D00F03");
    setFields({
      ...fields,
      condition: condition,
      group_type: group_type,
      check_necessary: check_necessary,
      check_necessary_appl: check_necessary_appl,
      check_interval: check_interval,
      appliance_type: appliance_type,
      appliance_group: appliance_group,
    });
  }, []);

  useEffect(async () => {
    setLoad(true);
    const appliances_ = await getAppliances(
      (pageApp - 1) * 5,
      mainStates?.allocation
    );

    if (appliances_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setAppliances(appliances_?.list ? appliances_?.list : []);
    setCountApp(appliances_?.count ? appliances_?.count : 0);
    setLoad(false);
  }, [pageApp, updateDataApp]);
  return (
    <>
      <AddAppliance
        open={addAppliance}
        setOpen={setAddAppliance}
        setPage={setPageApp}
        setLoad={setLoad}
        update={updateDataApp}
        setUpdate={setUpdateDataApp}
        parent={mainStates?.allocation}
        fields={fields}
      />
      <EditAppliance
        appliance={editAppliance}
        setAppliance={setEditAppliance}
        setLoad={setLoad}
        update={updateDataApp}
        setUpdate={setUpdateDataApp}
        fields={fields}
      />
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
          margin: "20px 0 0 0",
        }}
      >
        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            {" "}
            <IntlMessages id="appliance.title" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            {" "}
            <IntlMessages id="Serien No." />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            {" "}
            <IntlMessages id="Kostenst." />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            {" "}
            <IntlMessages id="Hersteller" />
          </Text>
        </Col>

        <Col
          xs={4}
          style={{
            padding: 0,
          }}
        >
          <Text>
            {" "}
            <IntlMessages id="Lieferant" />
          </Text>
        </Col>

        <Col xs={4}>
          <Popconfirm
            title="Archiv Excel Datei erzeugen?"
            onConfirm={async () => {
              const response = await excelForExitAppliances({
                stkey: mainStates?.allocation,
              });
              if (response?.file) {
                downloadToLocal(response?.file, response?.filename);
              }
            }}
            okText="Ja"
            cancelText="Nein"
          >
            <Tooltip title="Archiv Excel Datei aus der Liste erzeugen">
              <FileExcelOutlined style={{ cursor: "pointer" }} />
            </Tooltip>
          </Popconfirm>
        </Col>
      </Row>

      {appliances?.length === 0 ? <Empty /> : null}

      {appliances?.map((appliance) => {
        return (
          <Row
            style={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #eaeaea",
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={4}
              style={{
                padding: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                setEditAppliance(appliance);
              }}
            >
              <Text
                style={{
                  color: "#038fde",
                }}
              >
                {appliance?.cfeld1}
              </Text>
              <p style={{ margin: 0, fontSize: "12px" }}>
                {appliance?.cfeld3} {appliance?.cfeld4}
              </p>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>{appliance?.cfeld6}</Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>{appliance?.cfeld7}</Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>{appliance?.cfeld13}</Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>{appliance?.cfeld14}</Text>
            </Col>

            <Col xs={4}>
              <MoreActions
                state={appliance}
                onDelete={deleteAppliance}
                update={updateDataApp}
                setUpdate={setUpdateDataApp}
                inspection={true}
                stClass={"9"}
              />
              <DownOutlined
                size="small"
                style={{
                  color: "#038fde",
                  paddingLeft: "10px",
                  fontSize: "10px",
                }}
              />
            </Col>
          </Row>
        );
      })}
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          borderBottom: "1px solid #eaeaea",
          fontWeight: 500,
          padding: "10px 30px",
          margin: "0",
        }}
        justify="end"
      >
        
      </Row>
      <Row
        style={{
          backgroundColor: "#fff",
          padding: "10px 30px 0 30px",
          margin: "0",
        }}
        justify="end"
      >
        <Pagination
          defaultCurrent={1}
          total={countApp}
          current={pageApp}
          defaultPageSize={5}
          style={{
            margin: 0,
          }}
          onChange={(newpage) => {
            setPageApp(newpage);
          }}
        />
      </Row>
    </>
  );
}
