import {
  Form,
  Input,
  Row,
  Typography,
  Col,
  DatePicker,
  TimePicker,
  Checkbox,
  Select,
  InputNumber,
  Button,
  message,
  ConfigProvider,
} from "antd";

import { useEffect, useState } from "react";
import moment from "moment";
import IntlMessages from "util/IntlMessages";

import {
  editEvent,
  getEventRequirements,
  getSelectedEventRequirements,
} from "../../networkrequests";

import {
  doubleDT_to_string,
  double_to_string,
  string_to_double,
  string_to_doubleDT,
} from "../../../../../../../constants";
import { useHistory } from "react-router-dom";
import Requirements from "../requirements";
import { useForm } from "antd/lib/form/Form";

const success = () => {
  message.success("Die Daten wurden erfolgreich gespeichert!");
};

const error = () => {
  message.error("Fehler beim Speichern, bitte nochmal versuchen!");
};

export default function BaseData(props) {
  const { events, setEvents, edit, setEdit, updateEvents, setUpdateEvents } =
    props;

  const [form] = Form.useForm();

  const [reqs, setReqs] = useState(null);
  const [selectedReqs, setSelectedReqs] = useState(null);
  const [mendatoryBox, setMendatoryBox] = useState(
    edit?.cfeld4 === "y" ? true : false
  );
  const [mendatoryBox1, setMendatoryBox1] = useState(
    edit?.cfeld3 === "y" ? true : false
  );

  const [nfeld1, setnfeld1] = useState(
    edit?.nfeld1
      ? moment(doubleDT_to_string(edit?.nfeld1), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld2, setnfeld2] = useState(
    edit?.nfeld2
      ? moment(doubleDT_to_string(edit?.nfeld2), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld3, setnfeld3] = useState(
    edit?.nfeld3
      ? moment(doubleDT_to_string(edit?.nfeld3), "DD.MM.YYYY HH:mm")
      : null
  );
  const [nfeld4, setnfeld4] = useState(
    edit?.nfeld4
      ? moment(doubleDT_to_string(edit?.nfeld4), "DD.MM.YYYY HH:mm")
      : null
  );
  var time = edit?.nfeld5?.toString();

  if (time) {
    const minute = (
      time[time?.length - 2] ? time[time?.length - 2] : "0"
    ).concat(time[time?.length - 1] ? time[time?.length - 1] : "0");
    const hour = (time[time?.length - 4] ? time[time?.length - 4] : "0").concat(
      time[time?.length - 3] ? time[time?.length - 3] : "0"
    );
    time = hour + ":" + minute;
  }
  const [nfeld5, setnfeld5] = useState(time);
  const [cfeld26, setCfeld26] = useState(edit?.cfeld26);

  const [nfeld7, setnfeld7] = useState(
    edit?.nfeld7 ? moment(double_to_string(edit?.nfeld7), "DD.MM.YYYY") : ""
  );
  const navigate = useHistory();

  useEffect(() => {
    window.localStorage.setItem("editing", true);
    if (!edit) {
      navigate.push("/main/eventManagement/home");
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (edit?.stkey) {
        const reqs_ = await getEventRequirements(edit?.stkey, edit?.cfeld30);

        if (reqs_?.status === 401) {
          localStorage.removeItem("user_id");
          navigate.go(0);
        }
        setReqs({ ...reqs_ });

        const selectedReqs_ = await getSelectedEventRequirements(
          edit?.stkey,
          edit?.cfeld30
        );

        if (selectedReqs_?.status === 401) {
          localStorage.removeItem("user_id");
          navigate.go(0);
        }
        setSelectedReqs({ ...selectedReqs_ });
      }
    })();
  }, [edit]);

  return (
    <>
      <Row
        style={{
          marginTop: "15px",
          borderRadius: "8px",
          border: "2px solid #ececec",
          padding: "20px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Typography.Text
            style={{ fontSize: "16px", textTransform: "uppercase" }}
          >
            <IntlMessages id="events.EventBaseData" />
          </Typography.Text>
        </Row>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          form={form}
          wrapperCol={{ span: 16 }}
          initialValues={{
            ...edit,
            cfeld3: edit?.cfeld3 === "y",
            cfeld4: edit?.cfeld4 === "y",
            cfeld5: edit?.cfeld5 === "y",
            nfeld3: nfeld3,
            nfeld4: nfeld4,
          }}
          onFinish={async (values) => {
            var a = nfeld5?.substr(0, 2);
            var b = nfeld5?.substr(3, 2);
            const response = await editEvent(
              {
                ...values,
                nfeld1: nfeld1 ? string_to_doubleDT(nfeld1) : 0,
                nfeld2: nfeld2 ? string_to_doubleDT(nfeld2) : 0,
                nfeld3: nfeld3
                  ? string_to_doubleDT(nfeld3?.format("DD.MM.YYYY HH:mm"))
                  : 0,
                nfeld4: nfeld4
                  ? string_to_doubleDT(nfeld4?.format("DD.MM.YYYY HH:mm"))
                  : 0,
                nfeld7: nfeld7 ? string_to_double(nfeld7) : 0,
                cfeld30: props?.cfeld30,
                nfeld5: nfeld5 ? parseFloat(a + b) : null,
                cfeld3: values?.cfeld3 ? "y" : "n",
                cfeld4: values?.cfeld4 ? "y" : "n",
                cfeld5: values?.cfeld5 ? "y" : "n",
                cfeld26: cfeld26,
              },
              edit?.stkey
            );
            if (response?.success) {
              success();
              setUpdateEvents(!updateEvents);

              setEdit({
                ...edit,
                nfeld1: nfeld1 ? string_to_doubleDT(nfeld1) : 0,
                nfeld2: nfeld2 ? string_to_doubleDT(nfeld2) : 0,
                nfeld3: nfeld3
                  ? string_to_doubleDT(nfeld3?.format("DD.MM.YYYY HH:mm"))
                  : 0,
                nfeld4: nfeld4
                  ? string_to_doubleDT(nfeld4?.format("DD.MM.YYYY HH:mm"))
                  : 0,
                nfeld7: nfeld7 ? string_to_double(nfeld7) : 0,
                cfeld30: props?.cfeld30,
                nfeld5: nfeld5 ? parseFloat(a + b) : null,
                cfeld3: values?.cfeld3 ? "y" : "n",
                cfeld4: values?.cfeld4 ? "y" : "n",
                cfeld5: values?.cfeld5 ? "y" : "n",
              });
            } else {
              error();
            }
          }}
          autoComplete="off"
          style={{ width: "100%", marginTop: "15px" }}
        >
          <Row style={{ width: "100%" }}>
            <Col xs={12}>
              <Form.Item
                name="cfeld1"
                label={<IntlMessages id="events.Title" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                name="cfeld2"
                label={<IntlMessages id="events.Place" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.Construction" />}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={nfeld1}
                  showTime={{ format: "HH:mm" }}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  onChange={(e, d) => {
                    setnfeld1(d);
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.StartDate" />}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="nfeld3"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  showTime={{ format: "HH:mm" }}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  defaultValue={nfeld3}
                  onChange={(e, d) => {
                    setnfeld3(d ? moment(d, "DD.MM.YYYY HH:mm") : null);
                    setnfeld4(d ? moment(d, "DD.MM.YYYY HH:mm") : null);
                    form.setFieldsValue({
                      nfeld4: d ? moment(d, "DD.MM.YYYY HH:mm") : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.entrance" />}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <TimePicker
                  format={"HH:mm"}
                  minuteStep={15}
                  defaultValue={nfeld5 ? moment(nfeld5, "HH:mm") : ""}
                  style={{ width: "100%" }}
                  onChange={(e, d) => {
                    setnfeld5(d);
                  }}
                />
              </Form.Item>
            </Col>

            {/* <Col xs={3}>
            <Form.Item
              name="cfeld3"
              label="Wirtschaftshof"
              labelCol={{ span: 17 }}
              wrapperCol={{ span: 2 }}
              valuePropName="checked"
              rules={[
                {
                  required: mendatoryBox || mendatoryBox1 ? false : true,
                  transform: (value) => value || undefined, // Those two lines
                  type: "boolean",
                  message: "Pflichtfeld!",
                },
              ]}
            >
              <Checkbox
                onChange={(e) => {
                  setMendatoryBox1(e.target.checked);
                }}
              />
            </Form.Item>
              </Col>*/}
          </Row>

          <Row style={{ width: "100%" }}>
            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.Destruction" />}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={nfeld2}
                  showTime={{ format: "HH:mm" }}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  onChange={(e, d) => {
                    setnfeld2(d);
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.EndDate" />}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="nfeld4"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  //defaultValue={nfeld4}
                  showTime={{ format: "HH:mm" }}
                  format="DD.MM.YYYY HH:mm"
                  minuteStep={15}
                  defaultValue={
                    nfeld4 ? moment(nfeld4, "DD.MM.YYYY HH:mm") : null
                  }
                  onChange={(e, d) => {
                    setnfeld4(d ? moment(d, "DD.MM.YYYY HH:mm") : null);
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item
                name="nfeld6"
                label={<IntlMessages id="events.Visitor" />}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {/*<Col xs={3}>
            <Form.Item
              name="cfeld4"
              label="Freizeitzentrum"
              labelCol={{ span: 17 }}
              wrapperCol={{ span: 2 }}
              valuePropName="checked"
              rules={[
                {
                  required: mendatoryBox || mendatoryBox1 ? false : true,
                  transform: (value) => value || undefined, // Those two lines
                  type: "boolean",
                  message: "Pflichtfeld!",
                },
              ]}
            >
              <Checkbox
                onChange={(e) => {
                  setMendatoryBox(e.target.checked);
                }}
              />
            </Form.Item>
              </Col>*/}
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.Freigegeben" />}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={nfeld7}
                  format="DD.MM.YYYY"
                  onChange={(e, d) => {
                    setnfeld7(d);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.Creater" />}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                name="cfeld24"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: "25px" }}>
            <Typography.Text
              style={{ fontSize: "16px", textTransform: "uppercase" }}
            >
              <IntlMessages id="events.ResponsiblePersonInternal" />
            </Typography.Text>
          </Row>

          <Row style={{ width: "100%", marginTop: "15px" }}>
            <Col xs={8}>
              <Form.Item
                label={<IntlMessages id="events.responsiblePerson" />}
                name="cfeld25"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item label={<IntlMessages id="events.PresenseRequired" />}
               labelCol={{ span: 10 }}
               wrapperCol={{ span: 14 }}
               >
                <Select
                  value={cfeld26}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setCfeld26(value);
                  }}
                  options={[
                    { value: " ", label: " " },
                    { value: "yes", label: "Ja" },
                    { value: "no", label: "Nein" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item label={<IntlMessages id="events.PersonPresent" />} name="cfeld27"
               labelCol={{ span: 9 }}
               wrapperCol={{ span: 15 }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: "25px" }}>
            <Typography.Text
              style={{ fontSize: "16px", textTransform: "uppercase" }}
            >
              Basisdaten zum Veranstalter
            </Typography.Text>
          </Row>
          <Row style={{ width: "100%", marginTop: "15px" }}>
            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.NameCompany" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="cfeld6"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.firstname" />}
                name="cfeld7"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.phone" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="cfeld8"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.EMail" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="cfeld9"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                label={<IntlMessages id="events.StreetNo" />}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="cfeld10"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                label={<IntlMessages id="events.ZIPPlace" />}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="cfeld11"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: "25px" }} align="middle">
            <Typography.Text
              style={{
                fontSize: "16px",
                textTransform: "uppercase",
                marginRight: "15px",
              }}
            >
              {<IntlMessages id="events.responsiblePerson" />}
            </Typography.Text>
            <Button
              type="primary"
              onClick={() => {
                form.setFieldsValue({
                  cfeld18: form.getFieldValue("cfeld6"),
                  cfeld19: form.getFieldValue("cfeld7"),
                  cfeld20: form.getFieldValue("cfeld8"),
                  cfeld21: form.getFieldValue("cfeld9"),
                  cfeld22: form.getFieldValue("cfeld10"),
                  cfeld23: form.getFieldValue("cfeld11"),
                });
              }}
            >
              {<IntlMessages id="events.copy" />}
            </Button>
          </Row>
          <Row style={{ width: "100%", marginTop: "15px" }}>
            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.NameCompany" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="cfeld18"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.firstname" />}
                name="cfeld19"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.phone" />}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
                name="cfeld20"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                label={<IntlMessages id="events.EMail" />}
                name="cfeld21"
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                label={<IntlMessages id="events.StreetNo" />}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="cfeld22"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                label={<IntlMessages id="events.ZIPPlace" />}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                name="cfeld23"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%", marginTop: "25px" }}>
            <Typography.Text
              style={{ fontSize: "16px", textTransform: "uppercase" }}
            >
              Rechnungsadresse (wenn nicht der veranstalter)
            </Typography.Text>
          </Row>
          <Row style={{ width: "100%", marginTop: "15px" }}>
            <Col xs={12}>
              <Form.Item
                name="cfeld12"
                label={<IntlMessages id="events.NameCompany" />}
                // rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                name="cfeld13"
                label={<IntlMessages id="events.firstname" />}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={12}>
              <Form.Item
                name="cfeld14"
                label={<IntlMessages id="events.phone" />}
                // rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                name="cfeld15"
                label={<IntlMessages id="events.EMail" />}
                // rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld16"
                label={<IntlMessages id="events.StreetNo" />}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                name="cfeld17"
                label={<IntlMessages id="events.ZIPPlace" />}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 21, span: 3 }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              {<IntlMessages id="events.save" />}
            </Button>
          </Form.Item>
        </Form>
      </Row>

      <Requirements
        reqs={reqs}
        selectedReqs={selectedReqs}
        parent={edit?.stkey}
      />
    </>
  );
}
