import {
  Rate,
  Input,
  Form,
  Menu,
  Col,
  Row,
  Typography,
  Button,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  getIdeaRating,
  addRating,
  editIdea,
  getIdeaDissens,
} from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import Ranking from "./ranking";
import { string_to_doubleDT } from "../../../constants";
import moment from "moment";
import OtherRatings from "./otherRatings";
import OtherDissens from "./dissens";

export default function EditIdea(props) {
  const { idea } = props;

  const { Text } = Typography;

  const myIdea = window.localStorage.getItem("uname") === idea?.cfeld3;

  const ref = useRef();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [explanation, setExplanation] = useState("");
  const [load, setLoad] = useState(false);
  const [myRating, setMyRating] = useState({
    nfeld2: 0,
    cfeld2: "",
  });
  const [otherRating, setOtherRating] = useState([]);
  const [dissens, setDissens] = useState([]);

  useEffect(async () => {
    const ratings_ = await getIdeaRating(idea?.stkey);

    if (ratings_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    if (ratings_?.list?.length > 0) {
      const myRating_ = ratings_?.list?.find(
        (rating) => rating?.cfeld1 == window.localStorage.getItem("uname")
      );
      setMyRating({
        ...myRating_,
      });
      setValue(myRating_?.nfeld2);
      setExplanation(myRating_?.cfeld2);
      const otherRating_ = ratings_?.list?.filter(
        (rating) => rating?.cfeld1 !== window.localStorage.getItem("uname")
      );
      setOtherRating([...otherRating_]);
    }

    const dissens_ = await getIdeaDissens(idea?.stkey);

    if (dissens_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    if (dissens_?.list?.length > 0) {
      setDissens(dissens_?.list);
    }
  }, [idea?.stKey]);

  return (
    <>
      <Text
        style={{
          color: "#727272",
          fontSize: "18px",
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("./base");
          }}
        >
          Idea
        </span>{" "}
        {" -> "} {idea?.cfeld1}
      </Text>
      <Row
        style={{
          margin: "20px 30px",
          borderRadius: "12px",
          backgroundColor: "#fafafa",
          width: "100%",
        }}
      >
        <Form
          name="add Idea"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            padding: "10px 50px",
            width: "100%",
          }}
          initialValues={{
            cfeld1: idea?.cfeld1,
            cfeld2: idea?.cfeld2,
            cfeld3: idea?.cfeld3,
          }}
          onFinish={async (values) => {
            if (!values?.cfeld1) {
              message?.warning("Please enter the title of the idea");
            } else if (!values?.cfeld2) {
              message?.warning("Please enter the description of the idea");
            } else if (!value) {
              message?.warning("Please rate your idea");
            } else if (!explanation) {
              message?.warning("Explaination missing for the idea!");
            } else {
              setLoad(true);
              if (myIdea) {
                const response = await editIdea({
                  ...idea,
                  ...values,
                });
                if (response.status === 401) {
                  localStorage.removeItem("user_id");
                  history.go(0);
                }
              }
              const response1 = await addRating({
                stKey: idea?.stkey,
                rating: value,
                description: explanation,
              });
              if (response1?.success === "saved") {
                message?.success("Idea saved successfully!");
                history.push("./base");
              } else {
                message?.error("Error occured. Please try again.");
              }
              setLoad(false);
            }
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
          <Row style={{ width: "100%" }}>
            <Col
              xs={24}
              style={{ padding: 0, fontSize: "18px", color: "#727272" }}
            >
              <Text>IDEA</Text>
            </Col>
            <Col xs={15}>
              <Form.Item
                label="Name / Title"
                name="cfeld1"
                style={{ marginBottom: 0 }}
              >
                <Input disabled={!myIdea} />
              </Form.Item>
            </Col>

            <Col xs={9}>
              <Form.Item
                label="ErstellerIn"
                name="cfeld3"
                style={{ marginBottom: 0 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item
                label="Description"
                name="cfeld2"
                style={{ marginBottom: 0 }}
              >
                <Input.TextArea rows={5} disabled={!myIdea} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              padding: "10px 0px",
              width: "100%",
            }}
          >
            <Text
              style={{
                color: "#727272",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              MY RANKING
            </Text>
            <Ranking value={value} setValue={setValue} />
          </Row>
          <Row
            style={{
              width: "100%",
            }}
          >
            <Text
              style={{
                color: "#727272",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Explanation
            </Text>
            <Input.TextArea
              rows={5}
              value={explanation}
              onChange={(e) => {
                setExplanation(e.target.value);
              }}
            />
          </Row>
          <Row
            style={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ padding: "0px 60px" }}
              loading={load}
              onClick={() => {
                // setOpen(!open);
                ref?.current?.submit();
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </Row>
        </Form>
      </Row>

      {otherRating?.length > 0 ? (
        <Row
          style={{
            padding: "20px 50px",
            width: "100%",
            margin: "20px 30px",
            borderRadius: "12px",
            backgroundColor: "#fafafa",
            width: "100%",
          }}
        >
          <Text
            style={{
              color: "#727272",
              fontSize: "18px",
              marginBottom: "10px",
            }}
          >
            OTHER USERS RANKING
          </Text>
          <OtherRatings ratings={otherRating} />
        </Row>
      ) : null}

      {dissens?.length > 0 ? (
        <Row
          style={{
            padding: "20px 50px",
            width: "100%",
            margin: "20px 30px",
            borderRadius: "12px",
            backgroundColor: "#fafafa",
            width: "100%",
          }}
        >
          <Text
            style={{
              color: "#727272",
              fontSize: "18px",
              marginBottom: "10px",
            }}
          >
            DISSENS RESOLUTION
          </Text>
          <OtherDissens dissens={dissens} />
        </Row>
      ) : null}
    </>
  );
}
