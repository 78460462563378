import { Modal, Input, Form, Row, Col } from "antd";
import { useRef, useState } from "react";
//import { addEstates } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { editAutomaticWatering } from "./network_requests";

export default function EditAutomaticWatering(props) {
  const { open, setOpen, setPage } = props;
  const history = useHistory();
  const ref = useRef();

  return (
    <Modal
      title={
        <>
          <IntlMessages id="edit" /> <IntlMessages id="automaticWatering" />
        </>
      }
      width="660"
      visible={Boolean(open)}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
      }}
    >
      <Form
        name="add automaticWatering"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editAutomaticWatering({
            ...open,
            ...values,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label={<IntlMessages id="Name/Identifier" />}
          name="cfeld1"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={18} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Street" />}
              name="cfeld3"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="No." />}
              name="cfeld4"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Close off" />}
              name="cfeld6"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Material" />}
              name="cfeld5"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Programming" />}
              name="cfeld7"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item
              label={<IntlMessages id="Floor Socket" />}
              name="cfeld8"
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld2"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
