import { Row, Typography, Col, Button, Form, Input } from "antd";
import { useEffect, useState, useRef } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import ReactQuill from "react-quill";
import UploadDocs from "./upload";
import { useHistory } from "react-router";
import MoreActions from "../../../extraComponents/MoreActions";
import { addContent, editContent } from "./network_requests";

const { Text } = Typography;

export default function CatagoriesStep2(props) {
  const { showStates, setShowStates } = props;
  const ref = useRef();
  const history = useHistory();

  return (
    <>
      <Text
        style={{
          color: "#038fde",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowStates({
            page: 2,
            name: showStates?.name,
            key: showStates?.key,
          });
        }}
      >
        <IntlMessages id="Categories" /> {" -> "} {showStates?.name} {" -> "}{" "}
        {showStates?.name1}
      </Text>
      <Form
        name="add Content"
        ref={ref}
        style={{ padding: "10px" }}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          title: showStates?.name1 ?? "",
          content: showStates?.content ?? "",
        }}
        onFinish={async (values) => {
          if (showStates?.key1) {
            const response = await editContent({
              contentKey: showStates?.key1,
              title: showStates?.name1,
              content: showStates?.content,
              ...values,
            });
            if (response?.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
          } else {
            const response = await addContent({
              ...values,
              categoryKey: showStates?.key,
            });
            if (response?.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
          }
          setShowStates({
            page: 2,
            name: showStates?.name,
            key: showStates?.key,
          });
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          style={{ padding: 0, margin: 0 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          style={{ padding: 0, margin: 0 }}
        >
          <ReactQuill theme="snow" style={{ height: "150px" }} />
        </Form.Item>
        {showStates?.key1 ? (
          <UploadDocs templateKey={showStates?.key1} />
        ) : null}

        <Form.Item
          style={{
            padding: 0,
            margin: "0",
            marginTop: showStates?.key1 ? "10px" : "70px",
          }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
