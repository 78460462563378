import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useRef, useState, useEffect } from "react";
import { editCategory } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

import { string_to_double, double_to_string } from "../../../../constants";

export default function EditCatagories(props) {
  const { Category, setCategory, setPage, emptyFooter } = props;
  const open = Boolean(Category);
  const ref = useRef();
  const history = useHistory();

  function handOkClick() {
    setCategory(null);
    ref?.current?.submit();
  }
  function handleCancelClick() {
    setCategory(null);
  }

  return (
    <Modal
      title={
        <>
          <IntlMessages id="add" /> <IntlMessages id="Category.title" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      onOk={() => {
        setCategory(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setCategory(null);
      }}
      style={{ minWidth: "660px" }}
    >
      <Form
        name="add Catagories"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...Category,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editCategory({
            ...Category,
            ...values,
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          props.setUpdate(!props.update);
        }}
      >
        <Form.Item
          label={<IntlMessages id="Title" />}
          name="title"
          rules={[
            {
              required: true,
              message: "The field should not be empty!",
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<IntlMessages id="Icon" />}
          name="icon"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
