import { Modal, Input, Form, Row, Col, Dropdown, Typography, Menu } from "antd";
import { useRef, useState, useEffect } from "react";
import { editEmployee } from "./network_requests";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";

export default function EditEmployee(props) {
  const { Employee, setEmployee, groups } = props;
  const open = Boolean(Employee);
  const ref = useRef();
  const history = useHistory();
  const [group, setGroup] = useState(null);
  const { Text } = Typography;

  useEffect(() => {
    if (Employee?.groupkey) {
      const group_ = groups?.find(
        (item) => item?.groupkey === Employee.groupkey
      );
      setGroup(group_);
    }
  }, [Employee]);

  const group_menu = (
    <Menu title="Choose field">
      {groups?.map((group) => {
        return (
          <Menu.Item
            key={group?.groupkey}
            onClick={(e) => {
              setGroup(group);
            }}
          >
            {group?.groupname}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Modal
      title={
        <>
          <IntlMessages id="edit" /> <IntlMessages id="Employee" />
        </>
      }
      width="660"
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setEmployee(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setEmployee(null);
      }}
    >
      <Form
        name="add Employee"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...Employee,
        }}
        onFinish={async (values) => {
          props.setLoad(true);
          const response = await editEmployee({
            userkey: Employee?.userkey,
            ...values,
            groupkey: group?.groupkey,
          });

          if (response.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }

          props.setUpdate(!props.update);
        }}
      >
        <Form.Item label="Username" name="username" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" style={{ marginBottom: 0 }}>
          <Input type="password" />
        </Form.Item>
        <Form.Item
          label="First name"
          name="firstname"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastname"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item label="Group" style={{ marginBottom: 0 }}>
          <Dropdown overlay={group_menu} trigger="click">
            <Row
              style={{
                border: "1px solid #d9d9d9",
                height: "36px",
                borderRadius: "6px",
                margin: "0px",
                display: "flex",
              }}
            >
              <Col span={20} style={{ display: "flex", alignItems: "center" }}>
                <Text>{group?.groupname}</Text>
              </Col>
              <Col span={2} style={{ display: "flex", alignItems: "center" }}>
                <DownOutlined style={{ color: "#d9d9d9" }} />
              </Col>
            </Row>
          </Dropdown>
        </Form.Item>
      </Form>
    </Modal>
  );
}
