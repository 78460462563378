import { Button, Row, Col } from "antd";
import { useState } from "react";
import BuildingChecks from "./BuildingChecks";
import UrgentChecks from "./UrgentChecks";
import IntlMessages from "../../../util/IntlMessages";
import CompanyChecks from "./CompanyCheck";
import RecommendationChecks from "./RecommendationsChecks";
import TypeSelecter from "../../extraComponents/MoreActions/typeSelecter";
import { getAllTypes } from "./network_requests";

const tabStyle = {
  margin: 0,
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function CockpitMain() {
  const [tabValue, setTabValue] = useState(1);
  const [type, setType] = useState("none");

  return (
    <div
      style={{
        backgroundColor: "#fff",
        minHeight: "80vh",
        margin: "0px 0px 20px 0px",
        borderRadius: "10px",
      }}
    >
      <Row align="middle" style={{ paddingRight: "30px" }}>
        <Col xs={14}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px 0",
            }}
          >
            <Button
              type={tabValue === 1 ? "primary" : "text"}
              onClick={() => {
                setTabValue(1);
              }}
              style={tabStyle}
            >
              <IntlMessages id="cockpit.urgent.title" />
            </Button>
            <Button
              type={tabValue === 2 ? "primary" : "text"}
              onClick={() => {
                setTabValue(2);
              }}
              style={tabStyle}
            >
              <IntlMessages id="Zuordnung" />
            </Button>
            <Button
              type={tabValue === 3 ? "primary" : "text"}
              onClick={() => {
                setTabValue(3);
              }}
              style={tabStyle}
            >
              <IntlMessages id="Prüfer" />
            </Button>
            {/*<Button
              type={tabValue === 4 ? "primary" : "text"}
              onClick={() => {
                setTabValue(4);
              }}
              style={tabStyle}
            >
              Recommendation (beta)
            </Button>*/}
          </div>
        </Col>
        <Col xs={2} />
        <Col xs={5}>
          <TypeSelecter
            type={type}
            setType={setType}
            getAllTypes={getAllTypes}
          />
        </Col>
      </Row>
      {tabValue === 1 ? (
        <UrgentChecks type={type} />
      ) : tabValue === 2 ? (
        <BuildingChecks type={type} />
      ) : tabValue === 3 ? (
        <CompanyChecks type={type} />
      ) : (
        <RecommendationChecks />
      )}
    </div>
  );
}
