import { Modal, Input, Form, Typography } from "antd";
import { useRef } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import {
  editAppliance,
  editITAppliance,
  editFacilities,
  editMedAppliance,
} from "./network_requests";

export default function ExitModal(props) {
  const { open, setOpen, openModal, setOpenModal, exitObject, parent } = props;
  const { Text } = Typography;

  const ref = useRef();
  const history = useHistory();

  return (
    <Modal
      title="Exit Object"
      width="660"
      visible={openModal}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        ref?.current?.submit();
        setOpen(null);
        setOpenModal(!openModal);
      }}
      onCancel={() => {
        setOpen(null);
        setOpenModal(!openModal);
      }}
    >
      <Text>If you want to exit this object, please enter a reason:</Text>
      <Form
        name="Edit facilities"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={async (values) => {
          if (exitObject === "facility") {
            const response = await editFacilities(
              { ...open, cfeld19: values.exit, cfeld20: values.comment },
              parent,
              0
            );
            if (response.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            props.setUpdate(!props.update);
          } else if (exitObject === "appliance") {
            const response = await editAppliance(
              { ...open, cfeld29: values.exit, cfeld30: values.comment },
              0
            );
            if (response.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            props.setUpdate(!props.update);
          } else if (exitObject === "ITAppliance") {
            const response = await editITAppliance(
              { ...open, cfeld19: values.exit, cfeld20: values.comment },
              0
            );
            if (response.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            props.setUpdate(!props.update);
          } else {
            const response = await editMedAppliance(
              { ...open, cfeld29: values.exit, cfeld30: values.comment },
              0
            );
            if (response.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            props.setUpdate(!props.update);
          }
        }}
      >
        <Form.Item label="Exit Reason" name="exit" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
        <Form.Item label="Comment" name="comment" style={{ marginBottom: 0 }}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
