import { url } from "../../../../constants";
import axios from "axios";
import moment from "moment";

export const getVehicles = async (limitFrom, val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommonInclBaseCl`,
      {
        group: "class_g01",
        stClass: "15",
        type: "none",
        limitFrom: limitFrom,
        limitTo: "10",
        orderBy: "cfeld1",
        cfields: [{ fieldNo: "stkey", val: val, opVal: "=" }],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addVehicle = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClassAndBaseEntriesRetStKey`,
      {
        parentBaseClEntry: {
          aufkey: "",
          stkey: stkey,
          stclass: "4",
          storder: "0",
          treepos: "1",
          disptree: "0",
        },
        obj_Data: {
          group: 1,
          stkey: "",
          active: 1,
          stclass: 15,
          gisid: " ",
          ...values,
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editVehicle = async (values, deleteIt) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: "1",
        stclass: 15,
        ...values,
        active: deleteIt ? 0 : 1,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteVehicle = async (values, deleteIt) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonMoveToEliminated`,
      {
        group: "1",
        stclass: 15,
        ...values,
        active: deleteIt ? 0 : 1,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const excelForVehicles = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}excelForVehicles`,
      {
        group: "1",
        stclass: 4,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const excelForVehiclesWithRepairs = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}excelForVehiclesWithRepairs`,
      {
        group: "1",
        stclass: 4,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};