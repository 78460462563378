import React, { useState } from "react";
import { Collapse, Checkbox, Button } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";

const { Panel } = Collapse;

const MultiSelectPanel = ({ options, selectedItems, setSelectedItems }) => {
  const onChange = (checkedValues) => {
    setSelectedItems(checkedValues);
  };

  return (
    <Collapse
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel
        header={
          selectedItems?.length > 0
            ? selectedItems.join(", ")
            : <IntlMessages id="issue.SelectOptions" />
        }
        key="1"
      >
        <Checkbox.Group
          value={selectedItems}
          onChange={onChange}
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {options.map((option, index) => (
            <Checkbox
              key={index}
              value={option?.lovvalue}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "8px 8px",
                fontSize: "14px",
              }}
            >
              {option?.lovname}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Panel>
    </Collapse>
  );
};

export default MultiSelectPanel;
