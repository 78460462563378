import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Empty,
  Select,
} from "antd";
import { deleteTask, getFields, getTasks } from "./network_requests";
import { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import in_progress from "../../../assets/Icons_waste/task_inprogress.png";
import taskOpen from "../../../assets/Icons_waste/task_open.png";
import planned from "../../../assets/Icons_waste/task_planned.png";
import finished from "../../../assets/Icons_waste/task_finished.png";
import AddTask from "./addTask";
import EditTask from "./editTask";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";

const { Text } = Typography;

export default function Task(props) {
  const [Task, setTask] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [persons, setPersons] = useState([]);
  const [filter, setFilter] = useState("all");
  const [load, setLoad] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    document.body.style.zoom = "100%";
    setLoad(true);
    setEditTask(null);
    setAddTask(false);

    const Task_ = await getTasks((page - 1) * 10, filter);

    if (Task_?.status === 401) {
      localStorage.removeItem("task_id");
      history.go(0);
    }
    const assignments_ = await getFields("G01C04D00F13");
    const persons_ = await getFields("G20C01D00F05");
    setAssignments(assignments_);
    setPersons(persons_);
    setTask(Task_ ? Task_?.list : []);
    setCount(Task_?.count ? Task_?.count : 0);
    setLoad(false);
  }, [page, updateData, filter]);

  return (
    <>
      {editTask ? (
        <EditTask
          task={editTask}
          setTask={setEditTask}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
          onDelete={deleteTask}
          persons={persons}
          assignments={assignments}
        />
      ) : addTask ? (
        <AddTask
          open={addTask}
          setOpen={setAddTask}
          setLoad={setLoad}
          update={updateData}
          setUpdate={setUpdateData}
          persons={persons}
          assignments={assignments}
        />
      ) : (
        <>
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                }}
              >
                Tasks Overview
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#f3f7ff",
                left: 0,
                padding: "20px",
                width: "100%",
              }}
            >
              <Row style={{ padding: "0px 20px 0px 20px" }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setAddTask(!addTask);
                    setEditTask(null);
                  }}
                >
                  Add Task
                </Button>
              </Row>
              <Row style={{ padding: "0px 20px 0px 20px" }}>
                <Select
                  defaultValue={filter}
                  style={{
                    width: "100%",
                  }}
                  onChange={(value) => {
                    setFilter(value);
                  }}
                  options={[
                    {
                      value: "all",
                      label: "All",
                    },
                    {
                      value: "planned",
                      label: "Planned",
                    },
                    {
                      value: "inProgress",
                      label: "In Progress",
                    },
                    {
                      value: "finished",
                      label: "Finished",
                    },
                  ]}
                />
              </Row>
              <Row style={{ padding: "10px 20px 0px 20px" }}>
                <Text style={{ color: "gray", textTransform: "uppercase" }}>
                  tasks
                </Text>
              </Row>
            </div>
          </>

          {load ? (
            <div
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Spin />
            </div>
          ) : null}
          {!load ? (
            <>
              {Task?.length === 0 ? <Empty /> : null}

              {Task?.map((task) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      // borderBottom: "1px solid #eaeaea",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={3}
                      style={{
                        padding: 0,
                      }}
                      onClick={() => {
                        setEditTask(task);
                      }}
                    >
                      <img
                        src={
                          task?.nfeld7
                            ? finished
                            : task?.nfeld5
                            ? in_progress
                            : task?.nfeld6
                            ? planned
                            : taskOpen
                        }
                        style={{ width: "28px" }}
                      />
                    </Col>

                    <Col
                      xs={15}
                      style={{
                        padding: 0,
                      }}
                      onClick={() => {
                        setEditTask(task);
                      }}
                    >
                      <Text>{task?.cfeld1}</Text>
                    </Col>

                    <Col
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <RightOutlined
                        size="large"
                        onClick={() => {
                          setEditTask(task);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Row
                style={{
                  padding: "20px 30px 20px 30px",
                  backgroundColor: "#f3f7ff",
                  borderBottom: "1px solid #eaeaea",
                  margin: "0",
                }}
                justify="end"
              >
                <Pagination
                  defaultCurrent={1}
                  total={count}
                  current={page}
                  defaultPageSize={10}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage) => {
                    setPage(newpage);
                  }}
                />
              </Row>
            </>
          ) : null}
        </>
      )}
    </>
  );
}
