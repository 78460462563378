import { Row, Col, Typography, Button, Modal, Empty, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { deleteInspection, getAllInspections } from "../network_requests";
import { double_to_string, getOkOrFailed } from "../../../../constants";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

const Inspections = (props) => {
  const { state, setNewInspection, setEditInspection } = props;
  const [inspections, setInspections] = useState([]);

  useEffect(async () => {
    const inspections_ = await getAllInspections(state?.stkey);
    setInspections(inspections_?.list);
  }, [props.update]);

  return (
    <>
      <Row>
        <Col xs={23}>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
            }}
          >
            <Col
              xs={6}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="inspections.name" />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="inspections.LastCheck" />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="inspections.NextCheck" />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="inspections.ApprovedBy" />
              </Text>
            </Col>

            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="inspections.InspectedBy" />
              </Text>
            </Col>

            <Col
              xs={2}
              style={{
                padding: 0,
              }}
            >
              <Text>
                <IntlMessages id="inspection.condition" />
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      {inspections?.length === 0 ? (
        <Empty description="Keine Überprüfungen eingetragen." />
      ) : null}

      {inspections?.map((inspection) => {
        return (
          <Row align="middle">
            <Col xs={23}>
              <Row
                style={{
                  padding: "10px 30px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEditInspection({ ...inspection });
                }}
              >
                <Col
                  xs={6}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {inspection?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {inspection?.nfeld1
                      ? double_to_string(inspection?.nfeld1)
                      : ""}{" "}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {inspection?.nfeld3
                      ? double_to_string(inspection?.nfeld3)
                      : ""}{" "}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {inspection?.cfeld5}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {inspection?.cfeld2}
                  </Text>
                </Col>

                <Col
                  xs={2}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {inspection?.nfeld2 == 0 ? "Fehlerhaft" : "Ok"}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col
              xs={1}
              style={{
                padding: 0,
              }}
            >
              <Popconfirm
                title="Delete the inspection"
                description="Are you sure to delete this inspection?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={async () => {
                  await deleteInspection({ ...inspection }, props?.stclass);
                  props.setUpdate(!props?.update);
                }}
              >
                <DeleteOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            </Col>
          </Row>
        );
      })}
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          onClick={() => {
            setNewInspection({ ...state });
          }}
        >
          <IntlMessages id="cockpit.add_Inspection" />
        </Button>
      </Row>
    </>
  );
};

export default Inspections;
