import { Modal, Input, Form, Row, Col, DatePicker, Button } from "antd";
import { useRef, useState } from "react";
import { addVehicle } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { string_to_double, double_to_string } from "../../../../constants";
import ModelInspections from "../../../extraComponents/MoreActions/ModelInspections";

export default function AddVehicles(props) {
  const { open, setOpen, setPage, stkey } = props;
  const history = useHistory();
  const ref = useRef();
  const [nfeld1, setnfeld1] = useState(moment(moment(), "DD.MM.YYYY"));
  const [state, setState] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      {state ? (
        <ModelInspections
          state={state}
          stClass={"15"}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}
      <Modal
        title={
          <>
            <IntlMessages id="vehicle.title" /> <IntlMessages id="add" />
          </>
        }
        width="660"
        visible={open}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onCancel={() => {
          setOpen(!open);
        }}
        footer={[
          <Button
            key="1"
            disabled={!Boolean(state)}
            onClick={() => {
              setIsModalVisible(!isModalVisible);
            }}
          >
            Add Inspection
          </Button>,
          <Button
            key="2"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="3"
            type="primary"
            onClick={() => {
              //setOpen(!open);
              ref?.current?.submit();
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          name="add vehicles"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            {
              // remember: true,
            }
          }
          onFinish={async (values) => {
            props.setLoad(true);
            const response = await addVehicle(
              {
                ...values,
                nfeld1: string_to_double(nfeld1),
              },
              stkey
            );
            if (response?.status === 401) {
              localStorage.removeItem("user_id");
              history.go(0);
            }
            if (response?.success) {
              setState({
                ...values,
                nfeld1: string_to_double(nfeld1),
                stkey: response?.success,
                objStkey: response?.success,
              });
            }
            props.setUpdate(!props.update);
            setnfeld1(moment(moment(), "DD.MM.YYYY"));
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
          <Row>
            <Col span={12} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="Name / Type" />}
                name="cfeld1"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: "Pflichtfeld!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.registrationNo." />}
                name="cfeld2"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.condition" />}
                name="cfeld3"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.costcenter" />}
                name="cfeld4"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.inventoryNo." />}
                name="cfeld5"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.firstRegistration" />}
                name="nfeld1"
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={nfeld1}
                  format="DD.MM.YYYY"
                  onChange={(e, d) => {
                    setnfeld1(d);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.QR-Code" />}
                name="cfeld6"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.AM-Check" />}
                name="cfeld7"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={18} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.Producer" />}
                name="cfeld8"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.ProducerTelno" />}
                name="cfeld9"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={18} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.Supplier" />}
                name="cfeld10"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} style={{ margin: 0 }}>
              <Form.Item
                label={<IntlMessages id="vehicle.SupplierTelno" />}
                name="cfeld11"
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={<IntlMessages id="vehicle.comment" />}
            name="cfeld12"
            style={{ marginBottom: 0 }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
