import { Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Allocations from "./allocations";
import Appliances from "./appliances";
import Vehicles from "./vehicles";

export default function VehicleOverview() {
  const { page } = useParams();
  const [mainPage, setMainPage] = useState(page);
  const [mainStates, setMainStates] = useState({});

  useEffect(() => {
    setMainPage(page);
  }, [page]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "20px",
        minHeight: "70vh",
        padding: "20px 10px",
        marginBottom: "20px",
      }}
    >
      {" "}
      {mainPage === "1" ? (
        <Allocations mainStates={mainStates} setMainStates={setMainStates} />
      ) : (
        <>
          <Vehicles mainStates={mainStates} setMainStates={setMainStates} />
          <Appliances mainStates={mainStates} setMainStates={setMainStates} />
        </>
      )}{" "}
    </div>
  );
}
