import { Button } from "antd";
import IntlMessages from "util/IntlMessages";

const tabStyle = {
  margin: 0,
  width: "100px",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function TreeTabs(props) {
  const { tabValue, setTabValue } = props;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <Button
          type={tabValue === 1 ? "primary" : "text"}
          onClick={() => {
            setTabValue(1);
          }}
          style={tabStyle}
        >
          <IntlMessages id="outdoor.tree" />
        </Button>
        <Button
          type={tabValue === 2 ? "primary" : "text"}
          onClick={() => {
            setTabValue(2);
          }}
          style={tabStyle}
        >
          <IntlMessages id="outdoor.checks" />
        </Button>
        <Button
          type={tabValue === 3 ? "primary" : "text"}
          onClick={() => {
            setTabValue(3);
          }}
          style={tabStyle}
        >
          <IntlMessages id="outdoor.actions" />
        </Button>
      </div>
    </div>
  );
}
